<template>
    <div>
        <div v-for="p in dadosFiltrados" :key="p.id" class="row mt-5">
            <a style="cursor: pointer;" class="w-100" data-toggle="modal" data-target="#modalPost" @click="setStore(p)">
                <div class="card mx-3 card-publication">
                    <div class="card-body">
                        <div class="row">
                            <h5 class="col-9 card-title title-card-publication text-primary-color pl-4 pl-lg-5 font-weight-bold">
                                {{p.publicacao}}
                            </h5>
                            <div class="col-3 text-right">
                                <img v-if="p.importante == 's' && p.treinamento == 'n'" src="/storage/icons/importante_icon.png" class="card-publication-position-icon" alt="Icone Atividade">
                                <img v-if="p.importante == 'n' && p.treinamento == 's'" src="/storage/icons/treinamento.png" class="card-publication-position-icon" alt="Icone Atividade">
                                <img v-if="(p.importante == 'n' && p.treinamento == 'n') || (p.importante == 's' && p.treinamento == 's')" src="/storage/icons/bate_papo_icon.png" class="card-publication-position-icon" alt="Icone Atividade">
                            </div>
                        </div>
                        <p class="card-text text-secondary-color description-card-publication">
                            {{p.descricao}}
                        </p>
                        <p class="card-text date-card-publication text-primary-color float-right pr-4">
                            Data de Publicação: {{p.created_at | formataDataSimples}}
                        </p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['titulo', 'icone', 'dados', 'user'],
        computed: {
            dadosFiltrados () {
                // Recupera as publicações que correspondem ao cargo, setor e filial do usuário logado
                let dadosFiltrados = []
                this.dados.forEach(dado => {
                    dado.cargos.forEach(cargo => {
                        if (cargo.id == this.user.cargo_id) {
                            dado.setores.forEach(setor => {
                                if (setor.id == this.user.setor_id) {
                                    dado.filiais.forEach(filial => {
                                        if (filial.id == this.user.filial_id) {
                                            let itemFiltrado = {}
                                            itemFiltrado['publicacao'] = dado.publicacao
                                            itemFiltrado['descricao'] = dado.descricao
                                            itemFiltrado['texto'] = dado.texto
                                            itemFiltrado['treinamento'] = dado.treinamento
                                            if (dado.treinamento) {
                                                itemFiltrado['data_evento'] = dado.data_evento
                                            }
                                            itemFiltrado['importante'] = dado.importante
                                            if (dado.publicacoes_imagens.length != 0) {
                                                itemFiltrado['publicacoes_imagens'] = dado.publicacoes_imagens
                                            }                                            
                                            itemFiltrado['user'] = dado.user
                                            itemFiltrado['categoria'] = dado.categoria
                                            itemFiltrado['created_at'] = dado.created_at
                                            dadosFiltrados.push(itemFiltrado) 
                                        }                                                             
                                    })
                                }                                
                            }) 
                        }                                               
                    })                    
                });
                return dadosFiltrados
            }
        },
        methods: {
            setStore(obj) {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.$store.state.item = {}
                // Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
                this.$store.state.item = obj
            }
        }
    }
</script>