<template>
    <div class="modal fade" :id="id" tabindex="-1" :aria-labelledby="id+'Label'" aria-hidden="true">
        <div :class="estilo">
            <div class="modal-content modal-generic">
                <div class="modal-header modal-header-generic">
                    <h5 class="modal-title" :id="id+'Label'">{{titulo}}</h5>
                    <button type="button" class="btn-close-modal-generic close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><i class="fas fa-times"></i></span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="conteudo"></slot>
                    <slot name="alertas"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="rodape"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['id', 'titulo', 'tamanho'],
        // Retorna atributos que precisam ser computados/processados
        computed: {
            // Retorna o estilo computado para a class do componente modal
            estilo() {
                return 'modal-dialog modal-'+this.tamanho
            }
        }
    }
</script>