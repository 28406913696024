/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

import Vue from 'vue';
/* Importando e configurando o vuex */
import Vuex from 'Vuex';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueCropper from 'vue-cropperjs';
import Loader from 'vue-ui-preloader';
import 'cropperjs/dist/cropper.css';
import VueSimpleAlert from 'vue-simple-alert';
import VCalendar from 'v-calendar';

Vue.use(VCalendar);
Vue.use(VueSimpleAlert);
Vue.use(Loader);
Vue.component(VueCropper);
Vue.use(CKEditor);
Vue.use(Vuex);

Vue.filter('formataDataHora', function (d) {
    if (!d) {
        return ''
    }
    // Separa o valor de data e hora
    d = d.split('T')
    // Formata o valor de data para o padrão dd/mm/aaaa
    let data = d[0].split('-')
    data = data[2] + '/' + data[1] + '/' + data[0]
    // Recupera somente o valor da hora no formato hh:mm
    let hora = d[1].split('.')
    hora = hora[0]

    return data + ' às ' + hora
})

Vue.filter('formataDH', function (d) {
    if (!d) {
        return ''
    }
    // Separa o valor de data e hora
    d = d.split(' ')
    // Formata o valor de data para o padrão dd/mm/aaaa
    let data = d[0].split('-')
    data = data[2] + '/' + data[1] + '/' + data[0]
    // Recupera somente o valor da hora no formato hh:mm
    let hora = d[1]

    return data + ' às ' + hora
})

Vue.filter('formataDataSimples', function (d) {
    if (!d) {
        return ''
    }
    // Separa o valor de data e hora
    d = d.split('T')
    // Formata o valor de data para o padrão dd/mm/aaaa
    let data = d[0].split('-')
    data = data[2] + '/' + data[1] + '/' + data[0]

    return data
})

Vue.filter('formataData', function (d) {
    if (!d) {
        return ''
    }
    // Formata o valor de data para o padrão dd/mm/aaaa
    let data = d.split('-')
    data = data[2] + '/' + data[1] + '/' + data[0]

    return data
})

Vue.filter('textoCurto', function (t) {
    if (!t) {
        return ''
    }
    // Recupera apenas o começo de uma string
    let texto = t.substring(0, 20)

    if (t.length > 20) {
        texto = texto + '...'
    }

    return texto
})

Vue.filter('textoCurtoContatos', function (t) {
    if (!t) {
        return ''
    }
    // Recupera apenas o começo de uma string
    let texto = t.substring(0, 12)

    if (t.length > 12) {
        texto = texto + '...'
    }

    return texto
})

Vue.filter('valorNull', function (v) {
    if (v === 'null' || v === null || v === undefined || v === 'undefined') {
        v = ''
    }

    return v
})

const store = new Vuex.Store({
    state: {
        apiHost: 'https://intranet.comercialivaipora.com.br/', // TODO: https://intranet.comercialivaipora.com.br/  // http://localhost/
        item: {},
        modal: '',
        cargos: {},
        cargosSelecionados: [],
        setores: {},
        setoresSelecionados: [],
        filiais: {},
        filiaisSelecionadas: [],
        categorias: {},
        user: {},
        users: {},
        atividades: {},
        atividadesUsuarios: [],
        perguntas: {},
        transacao: { status: '', mensagem: '', dados: '' },
        statusArray: [],
        /* Variavel Global Cropper */
        imgSrcUser: '/storage/icons/func_m.png',
        cropImgUser: '',
        feedback: '',
        /* Variaveis Globais Configuração Loader */
        loader: false,
        colorObject: '#ffdf00',
        colorBg: '#000000',
        opacity: '50',
        disableScrolling: 'false',
        name: 'dots',
        size: '15',
        speed: '2',
    },
    mutations: {
        carregarCargos(state) {
            // Requisição de consulta de registros na tabela cargos no banco
            return axios.get(state.apiHost + 'api/cargo?order=cargo')
                .then(response => {
                    state.cargos = response.data
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        carregarSetores(state) {
            // Requisição de consulta de registros na tabela setores no banco
            return axios.get(state.apiHost + 'api/setor?order=setor')
                .then(response => {
                    state.setores = response.data
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        carregarFiliais(state) {
            // Requisição de consulta de registros na tabela filiais no banco
            return axios.get(state.apiHost + 'api/filial?order=filial')
                .then(response => {
                    state.filiais = response.data
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        carregarCategorias(state) {
            // Requisição de consulta de registros na tabela filiais no banco
            return axios.get(state.apiHost + 'api/categoria?order=categoria')
                .then(response => {
                    state.categorias = response.data
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        carregarUser(state) {
            // Requisição de consulta de registro do usuário logado na tabela users no banco
            return axios.post(state.apiHost + 'api/me')
                .then(response => {
                    state.user = response.data
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        carregarUsers(state) {
            // Requisição de consulta de registro do usuário logado na tabela users no banco
            return axios.get(state.apiHost + 'api/user?order=name&filtro=status:=:a;id:<>:1')
                .then(response => {
                    state.users = response.data
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        carregarAtividades(state) {
            // Requisição de consulta de registro do usuário logado na tabela users no banco
            return axios.get(state.apiHost + 'api/atividade?atributos=id,atividade&order=atividade')
                .then(response => {
                    state.atividades = response.data
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        carregarPerguntas(state) {
            // Requisição de consulta de registro do usuário logado na tabela users no banco
            return axios.get(state.apiHost + 'api/pergunta?order=pergunta')
                .then(response => {
                    state.perguntas = response.data
                    console.log(state.perguntas)
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        }
    }
})

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.component('agenda-component', require('./components/Agenda.vue').default);
Vue.component('cropper-component', require('./components/Cropper.vue').default);
Vue.component('back-to-top-component', require('./components/BackToTop.vue').default);
Vue.component('sair-home-component', require('./components/SairHome.vue').default);
Vue.component('sair-adm-component', require('./components/SairAdm.vue').default);

Vue.component('login-component', require('./components/Login.vue').default);

Vue.component('home-component', require('./components/Home.vue').default);
Vue.component('card-left-component', require('./components/CardLeft.vue').default);
Vue.component('card-left-activity-component', require('./components/CardLeftActivity.vue').default);
Vue.component('card-right-component', require('./components/CardRight.vue').default);
Vue.component('card-right-sm-component', require('./components/CardRightSm.vue').default);
Vue.component('post-component', require('./components/Post.vue').default);
Vue.component('modal-post-component', require('./components/ModalPost.vue').default);

Vue.component('contact-component', require('./components/Contact.vue').default);

Vue.component('wiki-component', require('./components/Wiki.vue').default);
Vue.component('card-wiki-component', require('./components/CardWiki.vue').default);

Vue.component('arquivo-component', require('./components/Arquivo.vue').default);
Vue.component('card-file-component', require('./components/CardFile.vue').default);

Vue.component('perfil-component', require('./components/Perfil.vue').default);
Vue.component('card-user-component', require('./components/CardUser.vue').default);

Vue.component('atividade-component', require('./components/Atividade.vue').default);
Vue.component('card-activity-component', require('./components/CardActivity.vue').default);

Vue.component('cargos-component', require('./components/Cargos.vue').default);
Vue.component('setores-component', require('./components/Setores.vue').default);
Vue.component('filiais-component', require('./components/Filiais.vue').default);
Vue.component('categorias-component', require('./components/Categorias.vue').default);

Vue.component('publicacoes-component', require('./components/Publicacoes.vue').default);

Vue.component('atividades-component', require('./components/Atividades.vue').default);
Vue.component('perguntas-component', require('./components/Perguntas.vue').default);
Vue.component('alternativas-component', require('./components/Alternativas.vue').default);

Vue.component('atividades-concluidas-component', require('./components/AtividadesConcluidas.vue').default);
Vue.component('atividades-pendentes-component', require('./components/AtividadesPendentes.vue').default);
Vue.component('table-activity-user-component', require('./components/TableActivityUser.vue').default);
Vue.component('table-pending-activity-user-component', require('./components/TablePendingActivityUser.vue').default);

Vue.component('wikis-component', require('./components/Wikis.vue').default);

Vue.component('arquivos-component', require('./components/Arquivos.vue').default);

Vue.component('users-component', require('./components/Users.vue').default);
Vue.component('feedbacks-component', require('./components/Feedbacks.vue').default);

Vue.component('input-container-component', require('./components/InputContainer.vue').default);
Vue.component('table-component', require('./components/Table.vue').default);
Vue.component('card-component', require('./components/Card.vue').default);
Vue.component('modal-component', require('./components/Modal.vue').default);
Vue.component('alert-component', require('./components/Alert.vue').default);
Vue.component('paginate-component', require('./components/Paginate.vue').default);

Vue.component('utilitario-component', require('./components/Utilitario.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    store
});
