const { default: axios } = require('axios');

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

/* Interceptar os requests da aplicação */
axios.interceptors.request.use(
    config => {
        // Recupera os cookies e transforma em um array, depois retorna o cookie que contem o texto 'token='
        let token = document.cookie.split(';').find(indice => {
            return indice.includes('token=')
        })
        // Transforma o token recuperado em um array, recupera apenas o valor contido no token na posicao [1]
        token = token.split('=')[1]

        token = 'Bearer ' + token

        let cpf = document.cookie.split(';').find(indice => {
            return indice.includes('cpf=')
        })

        // Transforma o cpf recuperado em um array, recupera apenas o valor contido no cpf na posicao [1]
        cpf = cpf.split('=')[1]

        // Definir para todas as requisições os parâmetros de accept e authorization
        config.headers.Authorization = token
        config.headers.Cpf = cpf
        config.headers.Accept = 'application/json'

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

/* Interceptar os responses da aplicação */
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        // Verifica se o token está expirado
        if (error.response.status == 401 && error.response.data.message == 'Token has expired') {
            // Requisição para o link de refresh para solicitar um novo token
            axios.post('https://intranet.comercialivaipora.com.br/api/refresh') // TODO: http://localhost //https://intranet.comercialivaipora.com.br
                .then(response => {
                    // Armazenando o novo token nos cookies
                    document.cookie = 'token=' + response.data.token
                    // Recarrega a pagina com o token válido
                    window.location.reload()
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        }
        if (error.response.status == 401) {
            axios.post('https://intranet.comercialivaipora.com.br/api/logout') // http://localhost
                .then(response => {
                    console.log(response)

                    var c = document.cookie.split(";")
                    for (i in c)
                        document.cookie = /^[^=]+/.exec(c[i])[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"

                    window.location.reload()
                })
                .catch(errors => {
                    console.log(errors.response)

                    var cookies = document.cookie.split(";")
                    cookies.forEach(c => {
                        document.cookie = c.split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
                    });

                    window.location.reload()
                })
        }
        return Promise.reject(error)
    }
)
