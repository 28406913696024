<template>
	<div class="card mt-5 border-0">
		<div class="card-header bg-third-color card-border-bottom border-radius-card-header">
			<div class="row">
				<div class="col-9">
					<h2 class="title-card text-white font-weight-bold">{{titulo}}</h2>
				</div>
				<div class="col-3">
					<img :src="icone" class="card-position-icon" :alt="'Icone '+titulo" />
				</div>
			</div>
		</div>
		<div class="card-body bg-primary-color border-radius-card-body shadow-card">
			<div v-if="dadosFiltrados.length > 0">
				<div v-for="d in dadosFiltrados" :key="d.id">
					<a
						style="cursor: pointer;"
						data-toggle="modal"
						data-target="#atividadeModal"
						@click="setStore(d)"
					>
						<div class="item-card card-border-bottom mb-3 text-white">
							<h3 class="text-truncate text-card font-weight-bold">{{d.atividade}}</h3>
							<p class="text-card text-right">Prazo: {{d.data_prazo | formataDataSimples}}</p>
						</div>
					</a>
				</div>
			</div>
			<div v-else>
				<h3 class="text-card text-white text-center">
					Parabéns, você não tem atividades pendentes
					<i class="far fa-laugh-beam"></i>
				</h3>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["titulo", "icone", "dados", "atividadesUsuarios", "user"],
	computed: {
		dadosFiltrados() {
			let atividadeUsuario = [];
			// Recupera atividades respondidas do usuário logado
			this.atividadesUsuarios.forEach((atividade) => {
				if (atividade.user_id == this.user.id) {
					atividadeUsuario.push(atividade.atividade_id);
				}
			});

			let dadosFiltrados = [];
			// Recupera atividades que correspondem ao cargo, setor e filial do usuário logado
			this.dados.forEach((dado) => {
				dado.cargos.forEach((cargo) => {
					if (cargo.id == this.user.cargo_id) {
						dado.setores.forEach((setor) => {
							if (setor.id == this.user.setor_id) {
								dado.filiais.forEach((filial) => {
									if (filial.id == this.user.filial_id) {
										if (
											!atividadeUsuario.includes(dado.id)
										) {
											let itemFiltrado = {};
											itemFiltrado["id"] = dado.id;
											itemFiltrado["atividade"] =
												dado.atividade;
											itemFiltrado["descricao"] =
												dado.descricao;
											itemFiltrado["data_prazo"] =
												dado.data_prazo;
											itemFiltrado["perguntas"] =
												dado.perguntas;
											itemFiltrado["alternativas"] =
												dado.alternativas;

											dadosFiltrados.push(itemFiltrado);
										}
									}
								});
							}
						});
					}
				});
			});
			return dadosFiltrados;
		},
	},
	methods: {
		setStore(obj) {
			// Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
			this.$store.state.transacao.mensagem = "";
			this.$store.state.transacao.status = "";
			this.$store.state.transacao.dados = "";
			this.$store.state.item = {};
			this.$store.state.atividadesUsuarios = [];
			// Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
			this.$store.state.item = obj;
			this.atividadesUsuarios.forEach((atividade) => {
				if (atividade.user_id == this.user.id) {
					this.$store.state.atividadesUsuarios.push(atividade);
				}
			});
		},
	},
};
</script>