<template>
	<div class="row mt-3">
		<div class="col-12 col-lg-5 mt-3 border-filter-right pb-4 order-12 order-lg-1">
			<!-- Barra lateral com lista de atividades -->
			<div>
				<button
					class="btn btn-collapse-activity"
					type="button"
					data-toggle="collapse"
					data-target="#collapseExample"
					aria-expanded="false"
					aria-controls="collapseExample"
				>
					<h3 class="title-activities font-weight-bold text-primary-color">
						Lista de Atividades
						<i class="fas fa-plus icon-collapse-title"></i>
					</h3>
				</button>
			</div>
			<div class="collapse show" id="collapseExample">
				<div class="mt-4">
					<!-- Cards de Atividades -->
					<card-activity-component
						:dados="atividades.data"
						:atividades-usuarios="atividadesUsuarios"
						:user="$store.state.user"
					></card-activity-component>
				</div>
			</div>
			<!-- Links de paginação -->
			<div class="row justify-content-center mr-3 mt-5">
				<nav aria-label="Page navigation">
					<ul class="pagination" style="cursor: pointer;">
						<li @click="paginacao(atividades.links[0])" class="page-item">
							<a class="page-link" v-if="atividades.links" v-html="atividades.links[0].label"></a>
						</li>
						<li
							v-if="atividades.current_page != 1"
							@click="paginacao(atividades.links[atividades.current_page-1])"
							class="page-item"
						>
							<a
								class="page-link"
								v-if="atividades.links"
								v-html="atividades.links[atividades.current_page-1].label"
							></a>
						</li>
						<li @click="paginacao(atividades.links[atividades.current_page])" class="page-item active">
							<a
								class="page-link"
								v-if="atividades.links"
								v-html="atividades.links[atividades.current_page].label"
							></a>
						</li>
						<li
							v-if="atividades.last_page > 1 && atividades.last_page != atividades.current_page"
							@click="paginacao(atividades.links[atividades.current_page+1])"
							class="page-item"
						>
							<a
								class="page-link"
								v-if="atividades.links"
								v-html="atividades.links[atividades.current_page+1].label"
							></a>
						</li>
						<li
							v-if="atividades.last_page > 1 && atividades.last_page != atividades.current_page"
							@click="paginacao(atividades.links[atividades.current_page+1])"
							class="page-item"
						>
							<a class="page-link" v-html="'Próximo &raquo;'"></a>
						</li>
						<li v-else class="page-item">
							<a class="page-link" v-html="'Próximo &raquo;'"></a>
						</li>
					</ul>
				</nav>
			</div>
		</div>

		<div class="col-12 col-lg-7 px-3 px-lg-5 mt-3 order-1 order-lg-12">
			<!-- Barra de Pesquisa de Wikis por Título de Wiki -->
			<div class="row mx-3 justify-content-center">
				<div class="col-12">
					<h3
						class="title-activities text-center font-weight-bold text-primary-color mb-3"
					>BUSCA DE ATIVIDADES</h3>
					<div class="form-group">
						<input
							type="text"
							class="form-control input-search-post placeholder px-4"
							id="atividade"
							placeholder="Posso Ajudar?"
							autocomplete="off"
							v-model="busca.atividade"
							@keydown.enter="pesquisar()"
						/>
					</div>
					<div class="col-12 text-center">
						<button type="submit" class="btn btn-search px-4" @click="pesquisar()">PESQUISAR</button>
					</div>
				</div>
			</div>
			<div class="row mt-5 d-none d-lg-inline-flex">
				<div class="col-1 col-md-3 col-lg-4"></div>
				<div class="col-10 col-md-6 col-lg-4 text-center">
					<img src="/storage/icons/computador.png" alt="Imagem de um Computador" class="img-fluid" />
				</div>
				<div class="col-1 col-md-3 col-lg-4"></div>
			</div>
		</div>

		<!-- Modal Atividade -->
		<div
			class="modal fade"
			id="atividadeModal"
			tabindex="-1"
			aria-labelledby="atividadeModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header bg-primary-color border-0">
						<h5 class="modal-title text-white" id="atividadeModalLabel">{{$store.state.item.atividade}}</h5>
						<button type="button" class="close btn-close-modal" data-dismiss="modal" aria-label="Close">
							<!-- <span aria-hidden="true">&times;</span> -->
							<i class="far fa-times-circle text-white"></i>
						</button>
					</div>
					<div class="modal-body">
						<!-- Chama o component de loader no envio da requisição -->
						<loader
							v-if="$store.state.loader"
							:object="$store.state.colorObject"
							:size="$store.state.size"
							:speed="$store.state.speed"
							:bg="$store.state.colorBg"
							:opacity="$store.state.opacity"
							:disableScrolling="$store.state.disableScrolling"
							:name="$store.state.name"
						></loader>

						<p class="description-activity mt-3 px-3">{{$store.state.item.descricao}}</p>

						<!-- <hr> -->

						<!-- <div v-if="$store.state.transacao.status != 'sucesso'">
                            <div v-for="p in $store.state.item.perguntas" :key="p.id">
                                Visualiza atividades se a mesma estiver pendente
                                <div v-if="$store.state.item.situacao == 'Pendente'">
                                    <div class="mt-5 px-3">
                                        <p class="text-activity font-weight-bold">{{p.pergunta}}</p>
                                        <div class="px-3">
                                            <div v-for="a in $store.state.item.alternativas" :key="a.id">
                                                <div class="form-check" v-if="a.pergunta_id == p.id">
                                                    <input class="form-check-input" type="radio" :name="'alt_'+p.id" :id="'alt_'+p.id" :value="a.id" required>
                                                    <label class="form-check-label alternative-activity" :for="'alt_'+p.id">
                                                        {{a.alternativa}}
                                                    </label>
                                                </div>
                                            </div>                                
                                        </div>
                                    </div>
                                </div>
                                Visualiza atividades se a mesma estiver concluída
                                <div v-if="$store.state.item.situacao == 'Concluído'">
                                    <div class="mt-5 px-3" v-for="atv in $store.state.atividadesUsuarios" :key="atv.id">
                                        <div v-if="atv.pergunta_id == p.id">
                                            <p class="text-activity font-weight-bold">{{atv.perguntas.pergunta}}</p>
                                            <p class="text-activity">{{atv.alternativas.alternativa}}</p>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
						</div>-->

						<!-- Lista alertas de retorno de envio de requisição -->
						<div
							class="mt-4"
							v-if="$store.state.transacao.status == 'sucesso' || $store.state.transacao.status == 'erro'"
						>
							<alert-component
								tipo="success"
								:detalhes="$store.state.transacao"
								titulo="Sucesso"
								v-if="$store.state.transacao.status == 'sucesso'"
							></alert-component>
							<alert-component
								tipo="danger"
								:detalhes="$store.state.transacao"
								titulo="Erro"
								v-if="$store.state.transacao.status == 'erro'"
							></alert-component>
						</div>
						<!-- Alerta se a atividade já estiver concluída -->
						<div
							v-if="$store.state.item.situacao == 'Concluído' && $store.state.transacao.status == ''"
							class="alert alert-success mt-4"
							role="alert"
						>Atividade já foi respondida!</div>
					</div>
					<!-- Botões de Enviar e Excluir atividades respondidas -->
					<div class="modal-footer">
						<button type="button" class="btn btn-cancel" data-dismiss="modal">Fechar</button>
						<!-- <button
							type="button"
							class="btn btn-confirm"
							v-if="$store.state.item.situacao == 'Pendente' && $store.state.transacao.status != 'sucesso'"
							@click="salvarAtividadeRespondida()"
						>Enviar</button>
						<button
							type="button"
							class="btn btn-cancel"
							v-if="$store.state.item.situacao == 'Concluído' && $store.state.transacao.status == ''"
							@click="excluirAtividadeRespondida()"
						>Excluir</button>-->
					</div>
				</div>
			</div>
		</div>
		<!-- Fim Modal Atividade -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			urlBase: "api/atividade",
			atividades: { data: [] },
			atividadesUsuarios: [],
			alternativa: "",
			urlPaginacao: "",
			urlFiltro: "&filtro=status:=:a",
			numPaginas: "&paginas=5",
			busca: { atividade: "" },
		};
	},
	methods: {
		// Metodo para buscar os registros da tabela user
		carregarUser() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarUser");
		},
		carregarCardAtividades() {
			// Montando url de requisição com possiveis parâmetros de filtro
			let url =
				this.$store.state.apiHost +
				this.urlBase +
				"?" +
				this.urlPaginacao +
				"&atributos_cargos_user=cargo&atributos_setores_user=setor&atributos_filiais_user=filial&order_desc=created_at" +
				this.urlFiltro +
				this.numPaginas;
			// Requisição de consulta de registros na tabela atividades no banco
			return axios
				.get(url)
				.then((response) => {
					this.atividades = response.data;
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		carregarAtividadesUsuarios() {
			// Requisição de consulta de registros na tabela atividades_usuarios no banco
			return axios
				.get(
					this.$store.state.apiHost +
						"api/atividade-usuario?order_desc=created_at"
				)
				.then((response) => {
					this.atividadesUsuarios = response.data;
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		// Metodo para criar links de requisição de buscas paginadas
		paginacao(l) {
			if (l.url) {
				// Capturando apenas parâmetro de pagina da request de paginação
				this.urlPaginacao = l.url.split("?")[1];
				this.carregarCardAtividades();
			}
		},
		// Metodo de pesquisa de registros na tabela atividades por nome do registro
		pesquisar() {
			let filtro = "status:=:a";
			// Percorre o objeto 'busca' concatenando seus atributos em uma string
			for (let chave in this.busca) {
				// Verifica se o campo foi preenchido para atribuir o valor
				if (this.busca[chave]) {
					// Separa cada atributo com ';'
					if (filtro != "") {
						filtro += ";";
					}
					// Separa valor e chave com ':like:'
					filtro +=
						chave + ":like:%" + this.busca[chave].substr(1) + "%";
				}
			}
			// Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
			if (filtro != "") {
				this.urlFiltro = "&filtro=" + filtro;
			} else {
				this.urlFiltro = "";
			}
			// Define a primeira pagina para o resultado da busca
			this.urlPaginacao = "page=1";
			this.carregarCardAtividades();
		},
		// Metodo para inserção de registros na tabela atividades_usuarios no banco
		salvarAtividadeRespondida() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;

			let radio = [];
			let preenchido = false;
			let cont = 0;
			// Valida se todas as perguntas da atividade foram respondidas
			this.$store.state.item.perguntas.forEach((pergunta) => {
				radio = document.getElementsByName("alt_" + pergunta.id);
				for (var j = 0, rd = radio.length; j < rd; j++) {
					if (radio[j].checked) {
						cont++;
						if (cont == this.$store.state.item.perguntas.length) {
							preenchido = true;
						}
					}
				}
			});
			// Insere no banco os registro se todas a atividades foram respondidas
			this.$store.state.item.perguntas.forEach((pergunta) => {
				if (preenchido) {
					radio = document.getElementsByName("alt_" + pergunta.id);
					for (var i = 0, r = radio.length; i < r; i++) {
						if (radio[i].checked) {
							// Instanciando formulario e definindo seus atributos
							let formData = new FormData();
							formData.append(
								"atividade_id",
								this.$store.state.item.id
							);
							formData.append("pergunta_id", pergunta.id);
							formData.append("alternativa_id", radio[i].value);
							formData.append(
								"user_id",
								this.$store.state.user.id
							);

							// Configurando headers da requisição
							let config = {
								headers: {
									"Content-Type": "multipart/form-data",
								},
							};

							// Requisição de inserção de registros atraves da biblioteca axios
							axios
								.post(
									this.$store.state.apiHost +
										"api/atividade-usuario",
									formData,
									config
								)
								.then((response) => {
									// Definindo status da requisição como sucesso
									this.$store.state.transacao.status =
										"sucesso";
									// Recuperando registro cadastrado no banco
									this.$store.state.transacao.mensagem =
										"Todas as perguntas foram respondidas com sucesso";
									this.$store.state.transacao.dados = "";
									this.carregarCardAtividades();
									this.carregarAtividadesUsuarios();

									// Define a variaval global Spinner como false
									this.$store.state.loader = false;

									this.$alert(
										"Registro inserido com sucesso",
										"Sucesso",
										"success"
									);
								})
								.catch((errors) => {
									// Definindo status da requisição como erro
									this.$store.state.transacao.status = "erro";
									// Recuperando erros da requisição
									this.$store.state.transacao.mensagem =
										errors.response.data.message;
									this.$store.state.transacao.dados =
										errors.response.data.errors;

									// Define a variaval global Spinner como false
									this.$store.state.loader = false;

									this.$alert(
										"Falha ao tentar inserir o registro",
										"Erro",
										"error"
									);
								});
						}
					}
				} else {
					// Definindo status da requisição como erro
					this.$store.state.transacao.status = "erro";
					// Retorna a mensagem de erro se alguma atividade não foi respondida
					this.$store.state.transacao.mensagem =
						"Uma ou mais perguntas não foram respondidas";
					this.$store.state.transacao.dados = "";

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Falha ao tentar inserir o registro",
						"Erro",
						"error"
					);
				}
			});
		},
		excluirAtividadeRespondida() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;

			// Abre uma caixa de dialogo para confirmar a exclusão do registro
			this.$confirm(
				"Tem certeza que deseja remover o registro?",
				"Exclusão de Registro",
				"question"
			)
				.then(() => {
					this.$store.state.atividadesUsuarios.forEach(
						(atividade) => {
							// Verifica quais atividades respondidas correspondem a atividade selecionada na instancia do vue
							if (
								atividade.atividade_id ==
									this.$store.state.item.id &&
								atividade.user_id == this.$store.state.user.id
							) {
								// Concatena a url com o id do registro a se excluido
								let url =
									this.$store.state.apiHost +
									"api/atividade-usuario/" +
									atividade.id;
								// Instanciando formulario e definindo o _method delete
								let formData = new FormData();
								formData.append("_method", "delete");
								// Requisição de remoção de registro por id
								axios
									.post(url, formData)
									.then((response) => {
										// Definindo status da requisição como sucesso
										this.$store.state.transacao.status =
											"sucesso";
										// Recuperando a mensagem da requisição
										this.$store.state.transacao.mensagem =
											response.data.msg;
										this.$store.state.transacao.dados = "";
										this.carregarCardAtividades();
										this.carregarAtividadesUsuarios();

										// Define a variaval global Spinner como false
										this.$store.state.loader = false;
									})
									.catch((errors) => {
										// Definindo status da requisição como erro
										this.$store.state.transacao.status =
											"erro";
										// Recuperando erros da requisição
										this.$store.state.transacao.mensagem =
											errors.response.data.erro;

										// Define a variaval global Spinner como false
										this.$store.state.loader = false;
									});
							}
						}
					);
				})
				.catch(() => {
					// Define a variaval global Spinner como false
					this.$store.state.loader = false;
				});
		},
	},
	async mounted() {
		await this.carregarCardAtividades();
		await this.carregarAtividadesUsuarios();
		await this.carregarUser();
	},
};
</script>
