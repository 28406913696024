<template>
    <div class="bg-image bg-opacity">
        <div class="container mt-3 mt-md-5">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="border-login">
                        <div class="card card-generic">
                            <div class="card-header card-header-generic">Login</div>

                            <div class="card-body py-3 py-md-5">
                                <form method="POST" action="" @submit.prevent="login($event)">
                                    <input type="hidden" name="_token" :value="csrf_token">
                                    <div class="form-group row">
                                        <label for="cpf" class="col-md-3 col-form-label text-md-right">CPF</label>
                                        <div class="col-md-6">
                                            <input id="cpf" type="text" class="form-control" name="cpf" placeholder="ex.: 012.345.678-90" required autocomplete="off" autofocus oninput="mcpf(this)" v-model="cpf">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="password" class="col-md-3 col-form-label text-md-right">Senha</label>
                                        <div class="col-md-6">
                                            <input id="password" type="password" class="form-control" name="password" required autocomplete="current-password" v-model="password">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-md-6 offset-md-3 text-center">
                                            <button type="submit" class="btn btn-confirm px-5">ENTRAR</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                    <div class="px-2 px-md-5 pt-2 pt-md-3" v-if="$store.state.transacao.status == 'erro'">
                        <div style="background-color: #FFF; border-radius: 5px">
                            <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Falha ao realizar login"></alert-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
    export default {
        // Recebe as propriedades definidas no componente
        props: ['csrf_token'],
        // Define os atributos que poderão ser usados nesta instancia do Vue
        data() {
            return {
                cpf: '',
                password: ''
            }
        },
        // Define os metodos usados nesta instancia do Vue
        methods: {
            // Metodo de login com autenticação por API e sequência autenticação Web
            login(e) {
                // Url que será encaminhado a requisição
                let url = this.$store.state.apiHost + 'api/login'
                // Configuração da requisição de login
                let config = {
                    // Define o metodo de requisição
                    method: 'post',
                    // Define os parâmetros das requisição
                    body: new URLSearchParams({
                        'cpf': this.cpf,
                        'password': this.password
                    })
                }
                // Requisição de login que recupera o token e armazena nos cookies
                fetch(url, config)
                    // Recupera o token e converte para o formato Json
                    .then(response => response.json()) 
                    .then(data => { 
                        if (data.token) {
                            // Atribui o token aos cookies
                            document.cookie = 'token='+data.token+';SameSite=Lax'
                            document.cookie = 'cpf='+this.cpf+';SameSite=Lax'
                            // Dar sequencia no envio do form para autenticação por sessão
                            e.target.submit()
                        }
                        if (data.erro) {
                            // Definindo status da requisição como erro
                            this.$store.state.transacao.status = 'erro'
                            // Recuperando erros da requisição
                            this.$store.state.transacao.mensagem = data.erro
                        }
                    })
                    .catch(error => {
                        console.log(error.message);
                    })
            }
        }
    }
</script>
