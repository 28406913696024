<template>
    <div>
        <div v-if="dadosFiltrados.length > 0">
            <a v-for="d in dadosFiltrados" :key="d.id" :href="$store.state.apiHost + 'api/download/'+d.id" @click="download(d.id)" class="link-file">
                <div class="row border-file-bottom mt-4">
                    <div class="col-12 col-sm-2 col-lg-2 d-none d-sm-block align-self-center">
                        <img v-if="d.url.includes('.pdf')" :src="'/storage/icons/pdf.png'" class="img-fluid p-4">
                        <img v-if="d.url.includes('.txt')" :src="'/storage/icons/txt.png'" class="img-fluid p-4">
                        <img v-if="d.url.includes('.xls') || d.url.includes('.xlsx')" :src="'/storage/icons/excel.png'" class="img-fluid p-4">
                        <img v-if="d.url.includes('.doc') || d.url.includes('.docx')" :src="'/storage/icons/word.png'" class="img-fluid p-4">
                        <img v-if="d.url.includes('.ppt') || d.url.includes('.pptx')" :src="'/storage/icons/ppt.png'" class="img-fluid p-4">
                    </div>
                    <div class="col-12 col-sm-6 col-lg-5 align-self-end">
                        <h4 class="title-file font-weight-bold">
                            {{d.arquivo}}
                            <i class="far fa-file d-inline d-sm-none pl-2"></i>
                        </h4>
                        <p class="text-file" v-if="d.user">Autor: {{d.user.name}}</p>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-5 align-self-end">
                        <p class="text-file text-right">Última alteração: {{d.updated_at | formataDataSimples}}</p>
                    </div>
                </div>
            </a>
        </div>
        <div v-else>
            <div class="row border-file-bottom mt-5">
                <div class="col-12 text-center ">
                    <p class="text-file" >Nenhum arquivo encontrado!</p>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['dados', 'user'],
        computed: {
            dadosFiltrados () {
                // Recupera arquivos que correspondem ao cargo, setor e filial do usuário logado
                let dadosFiltrados = []
                this.dados.forEach(dado => {
                    dado.cargos.forEach(cargo => {
                        if (cargo.id == this.user.cargo_id) {
                            dado.setores.forEach(setor => {
                                if (setor.id == this.user.setor_id) {
                                    dado.filiais.forEach(filial => {
                                        if (filial.id == this.user.filial_id) { 
                                            let itemFiltrado = {}
                                            itemFiltrado['id'] = dado.id  
                                            itemFiltrado['arquivo'] = dado.arquivo      
                                            itemFiltrado['url'] = dado.url                              
                                            itemFiltrado['user'] = dado.user
                                            itemFiltrado['updated_at'] = dado.updated_at
                                            dadosFiltrados.push(itemFiltrado) 
                                        }                                                             
                                    })
                                }                                
                            }) 
                        }                                               
                    })                    
                });
                return dadosFiltrados
            }
        },
        methods: {
            download(id) {
                axios.get(this.$store.state.apiHost + 'api/download/'+id)
                    .then(response => {
                        //console.log(response)
                    })
                    .catch(errors => {
                        alert(errors.response.data.erro)
                        document.location.reload()                        
                    })
            }
        }
    }
</script>