<template>
    <div class="text-center mt-5">  
        <v-calendar
            :masks="masks"
            :attributes="attributes"
            is-dark
        >
            <template v-slot:day-content="{ day, attributes }">
                <div>
                    <span>{{ day.day }}</span>
                    <div>
                        <span v-for="attr in attributes" :key="attr.id">
                            <a style="cursor: pointer;" data-toggle="modal" data-target="#modalPostAgenda" @click="setStore(attr)">
                                <i class="fas fa-exclamation-circle icon-agenda" data-toggle="tooltip" :title="attr.customData.title"></i>
                            </a>
                        </span>
                    </div>
                </div>
            </template>
        </v-calendar>        
    </div>
</template>

<script>
export default {
    props: ['attributes'],
    data() {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        return {
            masks: {
                weekdays: 'W',
            },
        };
    },
    methods: {
        setStore(obj) {
            // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
            this.$store.state.item = {}
            // Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
            this.$store.state.item = obj
        }
    }
};
</script>

