<template>
	<div>
		<div class="row mt-5">
			<div class="col-12 col-lg-3 align-self-center text-center d-none d-md-block">
				<img src="/storage/icons/perfil.png" class="img-fluid px-3" alt="Imagem de Perfil" />
			</div>
			<div class="col-12 col-lg-9">
				<!-- Chama o component de loader no envio da requisição -->
				<loader
					v-if="$store.state.loader"
					:object="$store.state.colorObject"
					:size="$store.state.size"
					:speed="$store.state.speed"
					:bg="$store.state.colorBg"
					:opacity="$store.state.opacity"
					:disableScrolling="$store.state.disableScrolling"
					:name="$store.state.name"
				></loader>

				<!-- Formulario de Informações do Colaborador -->
				<h3
					class="text-center text-md-right text-primary-color font-weight-bold mb-4 mt-4 mt-lg-0"
				>Minhas Informações</h3>
				<div class="form-row">
					<div class="form-group col-md-6 mb-2">
						<label class="font-weight-bold label-input-perfil">Nome:</label>
						<input
							type="text"
							class="form-control input-perfil"
							v-model="$store.state.user.name"
							autocomplete="off"
						/>
					</div>
					<div class="form-group col-md-3 mb-2">
						<label class="font-weight-bold label-input-perfil">Celular:</label>
						<input
							type="text"
							class="form-control input-perfil"
							v-model="$store.state.user.celular"
							autocomplete="off"
							onkeypress="mask(this, mphone);"
							onblur="mask(this, mphone);"
						/>
					</div>
					<div class="form-group col-md-3 mb-2">
						<label class="font-weight-bold label-input-perfil">Ramal:</label>
						<input
							type="text"
							class="form-control input-perfil"
							v-model="$store.state.user.ramal"
							autocomplete="off"
						/>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-7 mb-2">
						<label class="font-weight-bold label-input-perfil">Endereço:</label>
						<input
							type="text"
							class="form-control input-perfil"
							v-model="$store.state.user.endereco"
							autocomplete="off"
						/>
					</div>
					<div class="form-group col-md-3 mb-2">
						<label class="font-weight-bold label-input-perfil">Cidade:</label>
						<input
							type="text"
							class="form-control input-perfil"
							v-model="$store.state.user.cidade"
							autocomplete="off"
						/>
					</div>
					<div class="form-group col-md-2 mb-2">
						<label class="font-weight-bold label-input-perfil">UF:</label>
						<input
							type="text"
							class="form-control input-perfil"
							v-model="$store.state.user.uf"
							autocomplete="off"
						/>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-5 mb-2">
						<label class="font-weight-bold label-input-perfil">Contato Emergência:</label>
						<input
							type="text"
							class="form-control input-perfil"
							v-model="$store.state.user.nome_emergencia"
							autocomplete="off"
						/>
					</div>
					<div class="form-group col-md-3 mb-2">
						<label class="font-weight-bold label-input-perfil">Telefone:</label>
						<input
							type="text"
							class="form-control input-perfil"
							v-model="$store.state.user.contato_emergencia"
							autocomplete="off"
							onkeypress="mask(this, mphone);"
							onblur="mask(this, mphone);"
						/>
					</div>
					<div class="form-group col-md-4 mb-2">
						<label class="font-weight-bold label-input-perfil">Parentesco:</label>
						<input
							type="text"
							class="form-control input-perfil"
							v-model="$store.state.user.parentesco_emergencia"
							autocomplete="off"
						/>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-7 mb-2">
						<label class="font-weight-bold label-input-perfil">E-mail:</label>
						<input
							type="email"
							class="form-control input-perfil"
							v-model="$store.state.user.email"
							autocomplete="off"
						/>
					</div>
				</div>
				<div class="text-center text-md-right">
					<!-- Botão de atualização de senha -->
					<button
						type="submit"
						class="btn btn-update-password px-md-4 mr-md-2 mt-3"
						data-toggle="modal"
						data-target="#atualizarSenhaModal"
						@click="limparTransacao()"
					>Atualizar Senha</button>
					<!-- Botão de atualização de imagem de perfil -->
					<button
						type="submit"
						class="btn btn-update-image px-md-4 mr-md-2 mt-3"
						data-toggle="modal"
						data-target="#atualizarImagemModal"
						@click="limparTransacao()"
					>Atualizar Imagem</button>
					<!-- Botão de atualização de informações de cadastro -->
					<button
						type="submit"
						class="btn btn-update-perfil px-md-4 mr-md-5 mt-3"
						@click="atualizar()"
					>Atualizar Cadastro</button>
				</div>
				<div class="col-12 mt-3">
					<!-- Alertas de mensagem das requisições encaminhadas -->
					<alert-component
						tipo="success"
						:detalhes="transacao"
						titulo="Cadastro atualizado com sucesso"
						v-if="transacao.status == 'sucesso'"
					></alert-component>
					<alert-component
						tipo="danger"
						:detalhes="transacao"
						titulo="Erro ao atualizar cadastro do usuário"
						v-if="transacao.status == 'erro'"
					></alert-component>
				</div>
			</div>
		</div>
		<div class="row mt-5">
			<div class="col-11 col-md-12 mx-auto divisor-perfil mt-3 mb-4"></div>
			<div class="col-12 col-lg-9 order-2 order-lg-1">
				<h3
					class="text-center text-md-left text-primary-color font-weight-bold mb-4 mt-4 mt-lg-0"
				>Minha Equipe</h3>
				<!-- Cards de colaboradores do mesmo setor do usuário logado -->
				<card-user-component :dados="$store.state.users" :user="$store.state.user"></card-user-component>
			</div>
			<div class="col-12 col-lg-3 align-self-center text-center order-1 order-lg-2 d-none d-md-block">
				<img src="/storage/icons/equipe.png" class="img-fluid px-3" alt="Imagem de Equipe" />
			</div>
		</div>

		<!-- Modal Feedback -->
		<div
			class="modal fade"
			id="feedbackModal"
			data-backdrop="static"
			data-keyboard="false"
			tabindex="-1"
			aria-labelledby="feedbackModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-primary-color border-0">
						<h5 class="modal-title text-white" id="feedbackModalLabel">Feedback Colaborador</h5>
						<button type="button" class="close btn-close-modal" data-dismiss="modal" aria-label="Close">
							<!-- <span aria-hidden="true">&times;</span> -->
							<i class="far fa-times-circle text-white"></i>
						</button>
					</div>
					<div class="modal-body">
						<!-- Chama o component de loader no envio da requisição -->
						<loader
							v-if="$store.state.loader"
							:object="$store.state.colorObject"
							:size="$store.state.size"
							:speed="$store.state.speed"
							:bg="$store.state.colorBg"
							:opacity="$store.state.opacity"
							:disableScrolling="$store.state.disableScrolling"
							:name="$store.state.name"
						></loader>

						<div v-if="$store.state.item.imagem" class="form-group text-center">
							<img
								:src="'/storage/'+$store.state.item.imagem"
								v-if="$store.state.item.imagem != 'sem-imagem'"
								class="img-fluid border-primary-circle zoom"
								width="70px"
								alt="Imagem de Perfil"
							/>
							<img
								:src="'/storage/icons/func_m.png'"
								v-if="$store.state.item.imagem == 'sem-imagem'"
								class="img-fluid border-primary-circle zoom"
								width="70px"
								alt="Imagem de Perfil"
							/>
						</div>
						<div class="form-group">
							<label for="nome" class="col-form-label">Colaborador:</label>
							<input type="text" class="form-control" id="nome" :value="$store.state.item.name" disabled />
						</div>
						<div class="form-group">
							<label for="feedback" class="col-form-label">Mensagem:</label>
							<textarea class="form-control" id="feedback" v-model="$store.state.feedback"></textarea>
						</div>

						<alert-component
							tipo="success"
							:detalhes="$store.state.transacao"
							titulo="Feedback enviado com sucesso"
							v-if="$store.state.transacao.status == 'sucesso'"
						></alert-component>
						<alert-component
							tipo="danger"
							:detalhes="$store.state.transacao"
							titulo="Falha ao tentar enviar feedback"
							v-if="$store.state.transacao.status == 'erro'"
						></alert-component>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-cancel" data-dismiss="modal">Fechar</button>
						<button type="button" class="btn btn-confirm" @click="enviarFeedback()">Enviar</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Fim Modal Feedback -->

		<!-- Modal Atualizar Senha -->
		<div
			class="modal fade"
			id="atualizarSenhaModal"
			data-backdrop="static"
			data-keyboard="false"
			tabindex="-1"
			aria-labelledby="atualizarSenhaModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-primary-color border-0">
						<h5 class="modal-title text-white" id="atualizarSenhaModalLabel">Atualizar Senha</h5>
						<button type="button" class="close btn-close-modal" data-dismiss="modal" aria-label="Close">
							<!-- <span aria-hidden="true">&times;</span> -->
							<i class="far fa-times-circle text-white"></i>
						</button>
					</div>
					<div class="modal-body">
						<!-- Chama o component de loader no envio da requisição -->
						<loader
							v-if="$store.state.loader"
							:object="$store.state.colorObject"
							:size="$store.state.size"
							:speed="$store.state.speed"
							:bg="$store.state.colorBg"
							:opacity="$store.state.opacity"
							:disableScrolling="$store.state.disableScrolling"
							:name="$store.state.name"
						></loader>

						<div class="form-group">
							<label class="col-form-label">Colaborador:</label>
							<input type="text" class="form-control" :value="$store.state.user.name" disabled />
						</div>
						<div class="form-group">
							<label class="col-form-label">Nova Senha:</label>
							<input type="password" class="form-control" v-model="senha" />
						</div>

						<alert-component
							tipo="success"
							:detalhes="$store.state.transacao"
							titulo="Senha atualizada com sucesso"
							v-if="$store.state.transacao.status == 'sucesso'"
						></alert-component>
						<alert-component
							tipo="danger"
							:detalhes="$store.state.transacao"
							titulo="Falha ao tentar atualizar senha"
							v-if="$store.state.transacao.status == 'erro'"
						></alert-component>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-cancel" data-dismiss="modal">Fechar</button>
						<button type="button" class="btn btn-confirm" @click="atualizarSenha()">Alterar</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Fim Modal Atualizar Senha -->

		<!-- Modal Atualizar Imagem de Perfil -->
		<div
			class="modal fade"
			id="atualizarImagemModal"
			data-backdrop="static"
			data-keyboard="false"
			tabindex="-1"
			aria-labelledby="atualizarImagemModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header bg-primary-color border-0">
						<h5 class="modal-title text-white" id="atualizarImagemModalLabel">Atualizar Imagem de Perfil</h5>
						<button type="button" class="close btn-close-modal" data-dismiss="modal" aria-label="Close">
							<!-- <span aria-hidden="true">&times;</span> -->
							<i class="far fa-times-circle text-white"></i>
						</button>
					</div>
					<div class="modal-body">
						<!-- Chama o component de loader no envio da requisição -->
						<loader
							v-if="$store.state.loader"
							:object="$store.state.colorObject"
							:size="$store.state.size"
							:speed="$store.state.speed"
							:bg="$store.state.colorBg"
							:opacity="$store.state.opacity"
							:disableScrolling="$store.state.disableScrolling"
							:name="$store.state.name"
						></loader>

						<div class="form-group">
							<label class="col-form-label">Colaborador:</label>
							<input type="text" class="form-control" :value="$store.state.user.name" disabled />
						</div>
						<div v-if="$store.state.user.imagem" class="form-group">
							<label class="col-form-label">Imagem Atual:</label>
							<img
								:src="'/storage/'+$store.state.user.imagem"
								v-if="$store.state.user.imagem != 'sem-imagem'"
								class="img-fluid ml-3 border-primary-circle zoom"
								width="50px"
								alt="Imagem de Perfil"
							/>
							<img
								:src="'/storage/icons/func_m.png'"
								v-if="$store.state.user.imagem == 'sem-imagem'"
								class="img-fluid ml-3 border-primary-circle zoom"
								width="50px"
								alt="Imagem de Perfil"
							/>
						</div>

						<cropper-component></cropper-component>

						<div
							class="mt-3"
							v-if="$store.state.transacao.status == 'sucesso' || $store.state.transacao.status == 'erro'"
						>
							<alert-component
								tipo="success"
								:detalhes="$store.state.transacao"
								titulo="Imagem de perfil atualizada com sucesso"
								v-if="$store.state.transacao.status == 'sucesso'"
							></alert-component>
							<alert-component
								tipo="danger"
								:detalhes="$store.state.transacao"
								titulo="Falha ao tentar atualizar imagem de perfil"
								v-if="$store.state.transacao.status == 'erro'"
							></alert-component>
						</div>
					</div>
					<div class="px-3 d-block">
						<small style="font-size: 14px">
							* Clique na
							<i class="fas fa-cut px-1"></i> antes de alterar a imagem!
						</small>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-cancel" data-dismiss="modal">Fechar</button>
						<button type="button" class="btn btn-confirm" @click="atualizarImagem()">Alterar</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Fim Modal Atualizar Imagem de Perfil -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			urlBase: "api/user",
			users: [],
			senha: "",
			status: "",
			transacao: { status: "", mensagem: "", dados: "" },
		};
	},
	methods: {
		carregarUsers() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarUsers");
		},
		// Metodo para buscar os registros da tabela user
		carregarUser() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarUser");
		},
		limparTransacao() {
			// Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
			this.$store.state.transacao.mensagem = "";
			this.$store.state.transacao.status = "";
			this.$store.state.transacao.dados = "";
		},
		atualizar() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;

			console.log(this.$store.state.user.ramal);

			// Recupera a urlBase e concatena com o id do registro a se atualizado
			let url =
				this.$store.state.apiHost +
				this.urlBase +
				"/" +
				this.$store.state.user.id;
			// Instanciando formulario e definindo seus atributos
			let formData = new FormData();
			formData.append("_method", "patch");
			formData.append("name", this.$store.state.user.name);
			if (
				this.$store.state.user.email === "null" ||
				this.$store.state.user.email === null ||
				this.$store.state.user.email === undefined ||
				this.$store.state.user.email === "undefined"
			) {
				formData.append("email", " ");
			} else {
				formData.append("email", this.$store.state.user.email);
			}
			if (
				this.$store.state.user.ramal === "null" ||
				this.$store.state.user.ramal === null ||
				this.$store.state.user.ramal === undefined ||
				this.$store.state.user.ramal === "undefined"
			) {
				formData.append("ramal", " ");
			} else {
				formData.append("ramal", this.$store.state.user.ramal);
			}
			if (
				this.$store.state.user.celular === "null" ||
				this.$store.state.user.celular === null ||
				this.$store.state.user.celular === undefined ||
				this.$store.state.user.celular === "undefined"
			) {
				formData.append("celular", " ");
			} else {
				formData.append("celular", this.$store.state.user.celular);
			}
			if (
				this.$store.state.user.contato_emergencia === "null" ||
				this.$store.state.user.contato_emergencia === null ||
				this.$store.state.user.contato_emergencia === undefined ||
				this.$store.state.user.contato_emergencia === "undefined"
			) {
				formData.append("contato_emergencia", " ");
			} else {
				formData.append(
					"contato_emergencia",
					this.$store.state.user.contato_emergencia
				);
			}
			if (
				this.$store.state.user.nome_emergencia === "null" ||
				this.$store.state.user.nome_emergencia === null ||
				this.$store.state.user.nome_emergencia === undefined ||
				this.$store.state.user.nome_emergencia === "undefined"
			) {
				formData.append("nome_emergencia", " ");
			} else {
				formData.append(
					"nome_emergencia",
					this.$store.state.user.nome_emergencia
				);
			}
			if (
				this.$store.state.user.parentesco_emergencia === "null" ||
				this.$store.state.user.parentesco_emergencia === null ||
				this.$store.state.user.parentesco_emergencia === undefined ||
				this.$store.state.user.parentesco_emergencia === "undefined"
			) {
				formData.append("parentesco_emergencia", " ");
			} else {
				formData.append(
					"parentesco_emergencia",
					this.$store.state.user.parentesco_emergencia
				);
			}
			formData.append("endereco", this.$store.state.user.endereco);
			formData.append("cidade", this.$store.state.user.cidade);
			formData.append("uf", this.$store.state.user.uf);

			// Configurando headers da requisição
			let config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			// Requisição de atualização de registros atraves da biblioteca axios
			axios
				.post(url, formData, config)
				.then((response) => {
					// Definindo status da requisição como sucesso
					this.transacao.status = "sucesso";
					// Recuperando a mensagem da requisição
					// this.transacao.mensagem = 'Código do registro: ' + response.data.id
					this.transacao.dados = "";
					this.carregarUser();

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Registro alterado com sucesso",
						"Sucesso",
						"success"
					);
				})
				.catch((errors) => {
					// Definindo status da requisição como erro
					this.transacao.status = "erro";
					// Recuperando erros da requisição
					this.transacao.mensagem = errors.response.data.message;
					this.transacao.dados = errors.response.data.errors;

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Falha ao tentar alterar o registro",
						"Erro",
						"error"
					);
				});
		},
		atualizarSenha() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;
			this.transacao.status = "";

			// Recupera a urlBase e concatena com o id do registro a se atualizado
			let url =
				this.$store.state.apiHost +
				this.urlBase +
				"/" +
				this.$store.state.user.id;
			// Instanciando formulario e definindo seus atributos
			let formData = new FormData();
			formData.append("_method", "patch");
			formData.append("password", this.senha);

			// Configurando headers da requisição
			let config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			// Requisição de atualização de registros atraves da biblioteca axios
			axios
				.post(url, formData, config)
				.then((response) => {
					// Definindo status da requisição como sucesso
					this.$store.state.transacao.status = "sucesso";
					// Recuperando a mensagem da requisição
					// this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id
					this.$store.state.transacao.dados = "";
					this.carregarUser();

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Registro alterado com sucesso",
						"Sucesso",
						"success"
					);
				})
				.catch((errors) => {
					// Definindo status da requisição como erro
					this.$store.state.transacao.status = "erro";
					// Recuperando erros da requisição
					this.$store.state.transacao.mensagem =
						errors.response.data.message;
					this.$store.state.transacao.dados =
						errors.response.data.errors;

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Falha ao tentar alterar o registro",
						"Erro",
						"error"
					);
				});
		},
		// Metodo para inserção de registros na tabela usuários no banco
		atualizarImagem() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;
			this.transacao.status = "";

			// Recupera a urlBase e concatena com o id do registro a se atualizado
			let url =
				this.$store.state.apiHost +
				this.urlBase +
				"/" +
				this.$store.state.user.id;

			// Instanciando formulario e definindo seus atributos
			let formData = new FormData();
			formData.append("_method", "patch");
			if (this.$store.state.cropImgUser != "") {
				formData.append("imagem", this.$store.state.cropImgUser);
			}

			// Configurando headers da requisição
			let config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};

			// Requisição de inserção de registros atraves da biblioteca axios
			axios
				.post(url, formData, config)
				.then((response) => {
					// Definindo status da requisição como sucesso
					this.$store.state.transacao.status = "sucesso";
					// Recuperando a mensagem da requisição
					// this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id
					this.$store.state.transacao.dados = "";
					this.carregarUser();

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Registro alterado com sucesso",
						"Sucesso",
						"success"
					);
				})
				.catch((errors) => {
					// Definindo status da requisição como erro
					this.$store.state.transacao.status = "erro";
					// Recuperando erros da requisição
					this.$store.state.transacao.mensagem =
						errors.response.data.message;
					this.$store.state.transacao.dados =
						errors.response.data.errors;

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Falha ao tentar alterar o registro",
						"Erro",
						"error"
					);
				});
		},
		// Metodo para inserção de registros na tabela feedbacks no banco
		enviarFeedback() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;
			this.transacao.status = "";

			// Instanciando formulario e definindo seus atributos
			let formData = new FormData();
			formData.append("feedback", this.$store.state.feedback);
			formData.append("user_id", this.$store.state.item.id);
			formData.append("user_feedback_id", this.$store.state.user.id);

			// Configurando headers da requisição
			let config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};

			// Requisição de inserção de registros atraves da biblioteca axios
			axios
				.post(
					this.$store.state.apiHost + "api/feedback",
					formData,
					config
				)
				.then((response) => {
					// Definindo status da requisição como sucesso
					this.$store.state.transacao.status = "sucesso";
					// Recuperando a mensagem da requisição
					// this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id
					this.$store.state.transacao.dados = "";

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Registro inserido com sucesso",
						"Sucesso",
						"success"
					);
				})
				.catch((errors) => {
					// Definindo status da requisição como erro
					this.$store.state.transacao.status = "erro";
					// Recuperando erros da requisição
					this.$store.state.transacao.mensagem =
						errors.response.data.message;
					this.$store.state.transacao.dados =
						errors.response.data.errors;

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Falha ao tentar inserir o registro",
						"Erro",
						"error"
					);
				});
		},
	},
	async mounted() {
		await this.carregarUser();
		await this.carregarUsers();
	},
};
</script>
