<template>
    <div>
        <div :class="estilo" role="alert" style="padding-bottom: 2px !important;">
            <h5 class="alert-heading">{{titulo}}</h5>
            <hr v-if="detalhes.mensagem">
            <p v-if="detalhes.mensagem">
                <template v-if="detalhes.mensagem == 'The given data was invalid.'">Os dados fornecidos são inválidos</template>
                <template v-else>{{detalhes.mensagem}}</template>
            </p>
            <br  v-if="detalhes.dados">
            <!-- Verifica se existe as informações de erro para entrar no laço de repetição -->
            <ul v-if="detalhes.dados">
                <!-- Exibe todos os erros dentro do array recebido -->
                <li v-for="e, key in detalhes.dados" :key="key">{{e[0]}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['tipo','detalhes','titulo'],
        // Retorna atributos que precisam ser computados/processados
        computed: {
            // Retorna o estilo computado para a class do componente alert
            estilo() {
                return 'alert alert-'+this.tipo
            }
        }
    }
</script>