<template>
	<a class="nav-link text-white" @click="sair()" style="cursor: pointer !important;">SAIR</a>
</template>

<script>
export default {
	// Define os atributos que poderão ser usados nesta instancia do Vue
	data() {
		return {
			urlBase: "api/logout",
		};
	},
	// Define os metodos usados nesta instancia do Vue
	methods: {
		// Metodo para sair da aplicação
		sair() {
			let url = this.$store.state.apiHost + this.urlBase;
			axios
				.post(url)
				.then((response) => {
					this.deleteAllCookies();
					console.log(response);
					window.location.reload();
				})
				.catch((errors) => {
					this.deleteAllCookies();
					console.log(errors.response);
					window.location.reload();
				});
		},
		deleteAllCookies() {
			var cookies = document.cookie.split(";");
			cookies.forEach((c) => {
				document.cookie =
					c.split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
			});
		},
	},
};
</script>