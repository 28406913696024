<template>
    <div>
        <input class="input-cropper" ref="input" type="file" name="image" accept="image/*" @change="setImage">

        <div class="row">
            <div class="col-6">
                <section class="cropper-area">
                    <div class="img-cropper">
                        <vue-cropper ref="cropper" :aspect-ratio="1/1" :src="$store.state.imgSrcUser" preview=".preview"></vue-cropper>
                    </div>                
                </section>
            </div>
            <div class="col-6">
                <section class="preview-area">
                    <!-- <div class="preview"></div>  -->
                    <div class="cropped-image text-center">
                        <img v-if="$store.state.cropImgUser" :src="$store.state.cropImgUser" alt="Cropped Image"/>
                        <div v-else class="crop-placeholder"></div>
                    </div>
                </section>
            </div>            
        </div>

        <hr>

        <!-- Botões de ação -->
        <div class="actions row text-center align-items-center">
            <div class="col-lg-3 text-lg-right mb-2">
                <a data-toggle="tooltip" title="Upload" class="btn btn-cropper-secondary" style="cursor: pointer;" role="button" @click.prevent="showFileChooser"><i class="fas fa-upload"></i></a>
                <a data-toggle="tooltip" title="Cortar" class="btn btn-cropper-secondary" style="cursor: pointer;" role="button" @click.prevent="cropImage"><i class="fas fa-cut"></i></a>
                <a data-toggle="tooltip" title="Voltar" class="btn btn-cropper-secondary" style="cursor: pointer;" role="button" @click.prevent="reset"><i class="fas fa-fast-backward"></i></a>
            </div>
            <div class="col-lg-5 mb-2">
                <a data-toggle="tooltip" title="Aumentar" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="zoom(0.2)"><i class="fas fa-plus-circle"></i></a>
                <a data-toggle="tooltip" title="Diminuir" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="zoom(-0.2)"><i class="fas fa-minus-circle"></i></a>
                <a data-toggle="tooltip" title="Esquerda" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="move(-10, 0)"><i class="fas fa-chevron-circle-left"></i></a>
                <a data-toggle="tooltip" title="Direita" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="move(10, 0)"><i class="fas fa-chevron-circle-right"></i></a>
                <a data-toggle="tooltip" title="Cima" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="move(0, -10)"><i class="fas fa-chevron-circle-up"></i></a>
                <a data-toggle="tooltip" title="Baixo" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="move(0, 10)"><i class="fas fa-chevron-circle-down"></i></a>
            </div>
            <div class="col-lg-4 mb-2 text-lg-left">
                <a data-toggle="tooltip" title="Girar +90°" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="rotate(90)"><i class="fas fa-redo"></i></a>
                <a data-toggle="tooltip" title="Girar -90°" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="rotate(-90)"><i class="fas fa-undo"></i></a>
                <a data-toggle="tooltip" title="Inverter H" ref="flipX" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="flipX"><i class="fas fa-arrows-alt-h"></i></a>
                <a data-toggle="tooltip" title="Inverter V" ref="flipY" class="btn btn-cropper-primary" style="cursor: pointer;" role="button" @click.prevent="flipY"><i class="fas fa-arrows-alt-v"></i></a>
            </div>          
           
            <!-- 
            <a class="btn btn-danger btn-sm" style="cursor: pointer;" role="button" @click.prevent="getData"> Get Data </a>
            <a class="btn btn-danger btn-sm" style="cursor: pointer;" role="button" @click.prevent="setData"> Set Data </a>
            <a class="btn btn-danger btn-sm" style="cursor: pointer;" role="button" @click.prevent="getCropBoxData"> Get CropBox Data </a>
            <a class="btn btn-danger btn-sm" style="cursor: pointer;" role="button" @click.prevent="setCropBoxData"> Set CropBox Data </a>
            -->
        </div>
    </div>
</template>

<script>
    import VueCropper from "vue-cropperjs";
    import "cropperjs/dist/cropper.css";
    export default {
        components: {
            VueCropper,
        },
        methods: {
            // Metodo para inserção de registros na tabela usuários no banco
            /* salvar() {                
                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('imagem', this.$store.state.cropImgUser)

                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }                
                // Requisição de inserção de registros atraves da biblioteca axios
                axios.post('http://127.0.0.1:8000/api/user', formData, config)
                    .then(response => {
                        console.log(response.data)
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            }, */
            cropImage() {
                // obter dados de imagem para pós-processamento, por exemplo, fazer upload ou definir src de imagem
                this.$store.state.cropImgUser = this.$refs.cropper.getCroppedCanvas().toDataURL();
            },
            flipX() {
                const dom = this.$refs.flipX;
                let scale = dom.getAttribute("data-scale");
                scale = scale ? -scale : -1;
                this.$refs.cropper.scaleX(scale);
                dom.setAttribute("data-scale", scale);
            },
            flipY() {
                const dom = this.$refs.flipY;
                let scale = dom.getAttribute("data-scale");
                scale = scale ? -scale : -1;
                this.$refs.cropper.scaleY(scale);
                dom.setAttribute("data-scale", scale);
            },
            /* getCropBoxData() {
                this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
            },
            getData() {
                this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
            }, */
            move(offsetX, offsetY) {
                this.$refs.cropper.move(offsetX, offsetY);
            },
            reset() {
                this.$refs.cropper.reset();
            },
            rotate(deg) {
                this.$refs.cropper.rotate(deg);
            },
            /* setCropBoxData() {
                if (!this.data) return;
                this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
            },
            setData() {
                if (!this.data) return;
                this.$refs.cropper.setData(JSON.parse(this.data));
            }, */
            setImage(e) {
                const file = e.target.files[0];
                if (file.type.indexOf("image/") === -1) {
                    alert("Por favor, selecione uma imagem.");
                    return;
                }
                if (typeof FileReader === "function") {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.$store.state.imgSrcUser = event.target.result;
                        // reconstruir cropperjs com a imagem atualizada
                        this.$refs.cropper.replace(event.target.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert("Desculpe, não há suporte para API FileReader.");
                }
            },
            showFileChooser() {
                this.$refs.input.click();
            },
            zoom(percent) {
                this.$refs.cropper.relativeZoom(percent);
            },
        },
    };
</script>
