<template>
    <div class="row mt-5">
        <div class="col-12 col-lg-9 px-4">
            <h3 class="title-utilities font-weight-bold text-primary-color mb-4">Ferramentas e Utilitários</h3>
            <div class="accordion" id="accordionAtividades">
                <div class="py-2">
                    <button class="btn btn-primary-generic" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Calculadora de Pisos
                    </button>
                </div>
                <div class="card border border-dark">
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionAtividades">
                        <div class="card-body">
                            <div class="border border-secondary p-3 mb-3">
                                <legend class="font-weight-bold text-primary-color">Área da Superfície</legend>
                                <div class="form-row mb-3">
                                    <label class="col-md-3 font-weight-bold">Comprimento (m)</label>
                                    <input type="number" class="col-md-9 form-control-sm" v-model="cArea">
                                </div>
                                <div class="form-row mb-3">
                                    <label class="col-md-3 font-weight-bold">Largura (m)</label>
                                    <input type="number" class="col-md-9 form-control-sm" v-model="lArea">
                                </div>
                            </div>
                            <div class="border border-secondary p-3 mb-3">
                                <legend class="font-weight-bold text-primary-color">Tamanho do Revestimento</legend>
                                <div class="form-row mb-3">
                                    <label class="col-md-3 font-weight-bold">Comprimento (cm)</label>
                                    <input type="number" class="col-md-9 form-control-sm" v-model="cRevestimento">
                                </div>
                                <div class="form-row mb-3">
                                    <label class="col-md-3 font-weight-bold">Largura (cm)</label>
                                    <input type="number" class="col-md-9 form-control-sm" v-model="lRevestimento">
                                </div>                                
                                <div class="form-row mb-3">
                                    <label class="col-md-3 font-weight-bold">Peças por Caixa</label>
                                    <input type="number" class="col-md-9 form-control-sm" v-model="pcRevestimento">
                                </div>
                            </div>
                            <div class="border border-secondary p-3">
                                <legend class="font-weight-bold text-primary-color">Resultado</legend>
                                <div class="form-row mb-3">
                                    <label class="col-md-3 font-weight-bold">Área (m²)</label>
                                    <input :value="(cArea * lArea).toFixed(2)"
                                        type="number" class="col-md-9 form-control-sm" disabled>
                                </div>
                                <div class="form-row mb-3">
                                    <label class="col-md-3 font-weight-bold">Total de Peças</label>
                                    <input :value="((cArea * lArea) / ((cRevestimento / 100) * (lRevestimento / 100))).toFixed(2)"
                                        type="number" class="col-md-9 form-control-sm" disabled>
                                </div>
                                <div class="form-row mb-3">
                                    <label class="col-md-3 font-weight-bold">Total de Caixas</label>
                                    <input :value="(((cArea * lArea) / ((cRevestimento / 100) * (lRevestimento / 100))) / pcRevestimento).toFixed(2)"
                                        type="number" class="col-md-9 form-control-sm" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>   
        <div class="col-12 col-lg-3 align-self-center text-center my-5 p-4 d-none d-lg-block">
            <img src="/storage/icons/ferramentas.png" class="img-fluid" alt="Icone de Ferramentas">
        </div>      
    </div>

</template>

<script>
    export default {
        data() {
            return {
                cArea: 1,
                lArea: 1,
                cRevestimento: 45,
                lRevestimento: 45,
                pcRevestimento: 10,                
            }
        },
        methods: {
            
        },
        mounted() {
            
        }
    }
</script>
