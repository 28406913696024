<template>
    <div class="modal fade" :id="id" tabindex="-1" :aria-labelledby="id+'Label'" aria-hidden="true">
        <div :class="estilo">
            <div class="modal-content border-modal-post">
                <div class="modal-body">
                    <slot name="conteudo"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['id', 'tamanho'],
        // Retorna atributos que precisam ser computados/processados
        computed: {
            // Retorna o estilo computado para a class do componente modal
            estilo() {
                return 'modal-dialog modal-'+this.tamanho
            }
        }
    }
</script>