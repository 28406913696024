<template>
    <div class="container">
        <div class="row mt-5"></div>
        <div class="row justify-content-center">
            <div class="col-md-6 mb-5">
                <!-- Inicio do card de pesquisa -->
                <card-component titulo="Pesquisa de Wikis">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <input-container-component id="inputWiki" label="Wiki" id-help="wikiHelp" texto-help="Informe o nome do wiki que deseja pesquisar!">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input type="text" class="form-control" id="inputWiki" aria-describedby="wikiHelp" placeholder="Digite aqui!" autocomplete="off" v-model="busca.wiki" @keydown.enter="pesquisar()">
                        </input-container-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <button type="submit" class="btn btn-primary-generic btn-sm float-right" @click="pesquisar()">Pesquisar</button>
                    </template>
                </card-component>
                <!-- Fim do card de pesquisa -->
            </div>

            <div class="col-md-12">
                <!-- Inicio do card de listagem de wikis -->
                <card-component titulo="Lista de Wikis">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <!-- filiais: {campo: 'filial', titulo: 'Filiais', tipo: 'modal', dataTarget: '#modalFiliais', classe: 'secondary'}, -->
                        <table-component 
                            :dados="wikis.data" 
                            :titulos="{  // Relação de titulos a serem listados e seus respectivos titulos
                                id: {titulo: 'ID', tipo: 'inteiro'},
                                wiki: {titulo: 'Wiki', tipo: 'texto'},
                                descricao: {titulo: '', tipo: 'nao_exibir'},
                                texto: {titulo: '', tipo: 'nao_exibir'},
                                created_at: {titulo: 'Data de criação', tipo: 'data_hora'},
                                user: {campo: 'name', titulo: 'Último usuário', tipo: 'chave'},
                                cargos: {titulo: '', tipo: 'nao_exibir'},
                                setores: {titulo: '', tipo: 'nao_exibir'},
                                filiais: {titulo: '', tipo: 'nao_exibir'},
                            }"
                            
                            :botoes="{   // Relação de botoes a serem listados e suas respectivas propriedades
                                visualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalVisualizarWiki', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                atualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalAtualizarWiki', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                excluir: {visivel: true, dataToggle: 'modal', dataTarget: '#modalRemoverWiki', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso}
                            }"
                        >
                        </table-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <div class="row align-items-center">
                            <div class="col">
                                <paginate-component>
                                    <li @click="paginacao(wikis.links[0])" class="page-item">
                                        <a class="page-link" v-if="wikis.links" v-html="wikis.links[0].label"></a>
                                    </li>
                                    <li v-if="wikis.current_page != 1" @click="paginacao(wikis.links[wikis.current_page-1])" class="page-item">
                                        <a class="page-link" v-if="wikis.links" v-html="wikis.links[wikis.current_page-1].label"></a>
                                    </li>
                                    <li @click="paginacao(wikis.links[wikis.current_page])" class="page-item active">
                                        <a class="page-link" v-if="wikis.links" v-html="wikis.links[wikis.current_page].label"></a>
                                    </li>
                                    <li v-if="wikis.last_page > 1 && wikis.last_page != wikis.current_page" @click="paginacao(wikis.links[wikis.current_page+1])" class="page-item">
                                        <a class="page-link" v-if="wikis.links" v-html="wikis.links[wikis.current_page+1].label"></a>
                                    </li>
                                    <li v-if="wikis.last_page > 1 && wikis.last_page != wikis.current_page" @click="paginacao(wikis.links[wikis.current_page+1])" class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                    <li v-else class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                </paginate-component>
                            </div>
                            <div class="col mt-2 mt-md-0">
                                <button type="button" class="btn btn-primary-generic btn-sm float-right" data-toggle="modal" data-target="#modalWiki" @click="limparTransacao()">Adicionar</button>
                            </div>
                        </div>
                    </template>
                </card-component>
                <!-- Fim do card de listagem de wikis -->
            </div>
        </div>

        <!-- Inicio Modal de adição de novos wikis-->
        <modal-component id="modalWiki" titulo="Adicionar Wiki" tamanho="lg">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo>    
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                          
                <input-container-component id="novoWiki" label="Nome da Wiki" id-help="novoWikiHelp" texto-help="Informe o nome da wiki.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="text" class="form-control" id="novoWiki" aria-describedby="novoWikiHelp" placeholder="Nome da Wiki" autocomplete="off" v-model="wiki">
                </input-container-component>
                <input-container-component id="novoDescricao" label="Descrição da Wiki" id-help="novoDescricaoHelp" texto-help="Informe a descrição da wiki.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <textarea class="form-control" id="novoDescricao" aria-describedby="novoDescricaoHelp" rows="2" v-model="descricao"></textarea>
                </input-container-component>
                
                <input-container-component id="novoTexto" label="Texto da Wiki" id-help="novoTextoHelp" texto-help="Informe o texto da wiki.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <ckeditor :config="editorConfig" :editor="editor" id="novoTexto" aria-describedby="novoTextoHelp" v-model="texto"></ckeditor>
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="novoCargos" label="Cargos da Wiki" id-help="novoCargosHelp" texto-help="Informe os cargos da wiki.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoCargos" size="3" multiple v-model="cargosSelecionados">
                                <option v-for="c in $store.state.cargos" :key="c.id" :value="c.id">{{c.cargo}}</option>
                            </select>               
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="novoSetores" label="Setores da Wiki" id-help="novoSetoresHelp" texto-help="Informe os setores da wiki.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoSetores" size="3" multiple v-model="setoresSelecionados">
                                <option v-for="s in $store.state.setores" :key="s.id" :value="s.id">{{s.setor}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="novoFiliais" label="Filiais da Wiki" id-help="novoFiliaisHelp" texto-help="Informe os filiais da wiki.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoFiliais" size="3" multiple v-model="filiaisSelecionadas">
                                <option v-for="f in $store.state.filiais" :key="f.id" :value="f.id">{{f.filial}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                </div>   
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro realizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao realizar cadastro do wiki" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="salvar()">Salvar</button>
            </template>            
        </modal-component>
        <!-- Fim Modal de adição de novos wikis -->

        <!-- Inicio Modal de visualização de wikis -->
        <modal-component id="modalVisualizarWiki" titulo="Visualizar Wiki" tamanho="lg">
            <template v-slot:conteudo  v-if="$store.state.modal == '#modalVisualizarWiki'">
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Nome da Wiki">
                    <input type="text" class="form-control" :value="$store.state.item.wiki" disabled>
                </input-container-component>
                <input-container-component label="Descrição da Wiki">
                    <textarea class="form-control" rows="3" :value="$store.state.item.descricao" disabled></textarea>
                </input-container-component>
                <input-container-component label="Texto da Wiki">
                    <textarea class="form-control" rows="5" :value="$store.state.item.texto" disabled></textarea>
                </input-container-component>
                <input-container-component label="Último usuário">
                    <input type="text" class="form-control" :value="$store.state.item.user.name" disabled>
                </input-container-component>
                <input-container-component label="Data de criação">
                    <input type="text" class="form-control" :value="$store.state.item.created_at | formataDataHora" disabled>
                </input-container-component>
                <div class="accordion" id="accordionWikis">
                    <div class="py-2">
                        <button class="btn btn-primary-generic btn-sm" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Cargos
                        </button>
                        <button class="btn btn-primary-generic  btn-sm collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Setores
                        </button>
                        <button class="btn btn-primary-generic  btn-sm collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Filiais
                        </button>
                    </div>
                    <div class="card">
                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionWikis">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Cargo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="c in $store.state.item.cargos" :key="c.id">
                                            <td>{{c.id}}</td>
                                            <td>{{c.cargo}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionWikis">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Setor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="s in $store.state.item.setores" :key="s.id">
                                            <td>{{s.id}}</td>
                                            <td>{{s.setor}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionWikis">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Filial</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="f in $store.state.item.filiais" :key="f.id">
                                            <td>{{f.id}}</td>
                                            <td>{{f.filial}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
            </template>
        </modal-component>
        <!-- Fim Modal de visualização de wikis-->

        <!-- Inicio Modal de atualização de wikis-->
        <modal-component id="modalAtualizarWiki" titulo="Atualizar Wiki" tamanho="lg">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo v-if="$store.state.modal == '#modalAtualizarWiki'"> 
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                              
                <input-container-component id="atualizarWiki" label="Nome da Wiki" id-help="atualizarWikiHelp" texto-help="Informe o nome da wiki.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="text" class="form-control" id="atualizarWiki" aria-describedby="atualizarWikiHelp" placeholder="Nome da Wiki" v-model="$store.state.item.wiki">
                </input-container-component>
                <input-container-component id="atualizarDescricao" label="Descrição da Wiki" id-help="atualizarDescricaoHelp" texto-help="Informe a descrição da wiki.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <textarea class="form-control" id="atualizarWiki" aria-describedby="atualizarDescricaoHelp" rows="3" v-model="$store.state.item.descricao"></textarea>
                </input-container-component>
                <input-container-component id="atualizarTexto" label="Texto da Wiki" id-help="atualizarTextoHelp" texto-help="Informe o texto da wiki.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <ckeditor :config="editorConfig" :editor="editor" id="atualizarTexto" aria-describedby="atualizarTextoHelp" v-model="$store.state.item.texto"></ckeditor>
                </input-container-component>
                <div class="row">
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="atualizarCargos" label="Cargos da Wiki" id-help="atualizarCargosHelp" texto-help="Informe os cargos da wiki.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarCargos" size="3" multiple v-model="$store.state.cargosSelecionados">
                                <option v-for="c in $store.state.cargos" :key="c.id" :value="c.id">{{c.cargo}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="atualizarSetores" label="Setores da Wiki" id-help="atualizarSetoresHelp" texto-help="Informe os setores da wiki.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarSetores" size="3" multiple v-model="$store.state.setoresSelecionados">
                                <option v-for="s in $store.state.setores" :key="s.id" :value="s.id">{{s.setor}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="atualizarFiliais" label="Filiais da Wiki" id-help="atualizarFiliaisHelp" texto-help="Informe os filiais da wiki.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarFiliais" size="3" multiple v-model="$store.state.filiaisSelecionadas">
                                <option v-for="f in $store.state.filiais" :key="f.id" :value="f.id">{{f.filial}}</option>
                            </select>                
                        </input-container-component> 
                    </div>
                </div> 
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro atualizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao atualizar cadastro do wiki" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="atualizar()">Atualizar</button>
            </template>                      
        </modal-component>
        <!-- Fim Modal de atualização de wikis-->

        <!-- Inicio Modal de remoção de wikis-->
        <modal-component id="modalRemoverWiki" titulo="Remover Wiki" tamanho="md">
            <template v-slot:conteudo v-if="$store.state.transacao.status != 'sucesso'">
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Nome da Wiki">
                    <input type="text" class="form-control" :value="$store.state.item.wiki" disabled>
                </input-container-component>
            </template>
            <template v-slot:alertas>
                <alert-component tipo="success" titulo="Registro removido com sucesso" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" titulo="Falha ao remover registro" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-danger-generic" @click="excluir()" v-if="$store.state.transacao.status != 'sucesso'">Excluir</button>
            </template>
        </modal-component>
        <!-- Fim Modal de remoção de wikis-->
    </div>
</template>

<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    
    export default {
        // Define os atributos que poderão ser usados nesta instancia do Vue
        data() {
            return{
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: [ "undo", "redo", "|", "bold", "italic", "|", "blockQuote", "heading", "numberedList", "bulletedList", "|", "insertTable", "|", "tableColumn", "tableRow", "mergeTableCells"],
                        heading: {
                            options: [
                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            ]
                        }
                },
                urlBase: 'api/wiki',
                urlPaginacao: '',
                urlFiltro: '',
                numPaginas: '&paginas=30',
                wiki: '',
                descricao: '',
                texto: '',
                cargosSelecionados: [],
                setoresSelecionados: [],
                filiaisSelecionadas: [],
                wikis: { data: [] },
                busca: { wiki: '' },
            }            
        },
        // Define os metodos usados nesta instancia do Vue
        methods: {
            // Metodo para buscar os registros da tabela wikis
            carregarLista() {
                // Montando url de requisição com possiveis parâmetros de filtro e paginação
                let url = this.$store.state.apiHost + this.urlBase + '?' + this.urlPaginacao + '&order=wiki' + this.urlFiltro + this.numPaginas
                // Requisição de consulta de registros na tabela wikis no banco
                return axios.get(url)
                    .then(response => {
                        this.wikis = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            // Metodo para buscar os registros da tabela cargos
            carregarCargos() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarCargos')
            },
            // Metodo para buscar os registros da tabela setores
            carregarSetores() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarSetores')
            },
            // Metodo para buscar os registros da tabela filiais
            carregarFiliais() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarFiliais')
            },
            // Metodo para buscar o registro do usuário logado
            carregarUser() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarUser')
            },
            // Metodo para criar links de requisição de buscas paginadas
            paginacao(l) {
                if (l.url) {
                    // Capturando apenas parâmetro de pagina da request de paginação
                    this.urlPaginacao = l.url.split('?')[1] 
                    this.carregarLista()
                }                
            },
            // Metodo de pesquisa de registros na tabela wikis por nome do registro
            pesquisar() {
                let filtro = ''
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.busca) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.busca[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':like:%' + this.busca[chave].substr(1) + '%'
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                // Define a primeira pagina para o resultado da busca
                this.urlPaginacao = 'page=1'
                this.carregarLista()
            },
            limparTransacao() {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.wiki = ''
                this.descricao = ''
                this.texto = ''
                this.cargosSelecionados = []
                this.setoresSelecionados = []
                this.filiaisSelecionadas = []
                this.carregarCargos()
                this.carregarSetores()
                this.carregarFiliais()
                this.carregarUser()
            },
            // Metodo para inserção de registros na tabela wikis no banco
            salvar() { 
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('wiki', this.wiki)
                formData.append('descricao', this.descricao)
                // Substitui tags para entrada de codigos html
                this.texto = this.texto.replaceAll("&lt;", "<")
                this.texto = this.texto.replaceAll("&gt;", ">")                
                formData.append('texto', this.texto)
                formData.append('user_id', this.$store.state.user.id)
                
                if (this.cargosSelecionados.length > 0 || this.$store.state.user.nivel_acesso == 1) {
                    this.cargosSelecionados.forEach(cargo => {
                        formData.append('cargos[]', cargo)
                    });
                } else {
                    formData.append('cargos[]', this.$store.state.user.cargo_id)
                }

                if (this.setoresSelecionados.length > 0 || this.$store.state.user.nivel_acesso == 1) {
                    this.setoresSelecionados.forEach(setor => {
                        formData.append('setores[]', setor)
                    });
                } else {
                    formData.append('setores[]', this.$store.state.user.setor_id)
                }
                
                if (this.filiaisSelecionadas.length > 0 || this.$store.state.user.nivel_acesso == 1) {
                    this.filiaisSelecionadas.forEach(filial => {
                        formData.append('filiais[]', filial)
                    });
                } else {
                    formData.append('filiais[]', this.$store.state.user.filial_id)
                }
                
                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }                
                // Requisição de inserção de registros atraves da biblioteca axios
                axios.post(this.$store.state.apiHost + this.urlBase, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando registro cadastrado no banco
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id 
                        this.$store.state.transacao.dados = ''
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Registro inserido com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Falha ao tentar inserir o registro', 'Erro', 'error');
                    })
            },
            atualizar() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                let textoFormatado = ''
                // Recupera a urlBase e concatena com o id do registro a se atualizado
                let url = this.$store.state.apiHost + this.urlBase+'/'+this.$store.state.item.id
                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('_method', 'patch')
                formData.append('wiki', this.$store.state.item.wiki)
                formData.append('descricao', this.$store.state.item.descricao)
                // Substitui tags para entrada de codigos html
                textoFormatado = this.$store.state.item.texto.replaceAll("&lt;", "<")
                textoFormatado = textoFormatado.replaceAll("&gt;", ">")  
                formData.append('texto', textoFormatado)
                formData.append('user_id', this.$store.state.user.id)

                this.$store.state.cargosSelecionados.forEach(cargo => {
                    formData.append('cargos[]', cargo)
                });
                this.$store.state.setoresSelecionados.forEach(setor => {
                    formData.append('setores[]', setor)
                });
                this.$store.state.filiaisSelecionadas.forEach(filial => {
                    formData.append('filiais[]', filial)
                });

                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                } 
                // Requisição de atualização de registros atraves da biblioteca axios
                axios.post(url, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando a mensagem da requisição
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id
                        this.$store.state.transacao.dados = ''
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Registro alterado com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Falha ao tentar alterar o registro', 'Erro', 'error');
                    })
            },
            excluir() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Abre uma caixa de dialogo para confirmar a exclusão do registro
                this.$confirm(
                    'Tem certeza que deseja remover o registro?',
                    'Exclusão de Registro',
                    'question'
                ).then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url = this.$store.state.apiHost + this.urlBase+'/'+ this.$store.state.item.id
                    // Instanciando formulario e definindo o _method delete
                    let formData = new FormData()
                    formData.append('_method', 'delete')
                    // Requisição de remoção de registro por id
                    axios.post(url, formData)
                        .then(response => {
                            // Definindo status da requisição como sucesso
                            this.$store.state.transacao.status = 'sucesso'
                            // Recuperando a mensagem da requisição
                            this.$store.state.transacao.mensagem = response.data.msg
                            this.$store.state.transacao.dados = ''
                            this.carregarLista()

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false
                        })
                        .catch(errors => {
                            // Definindo status da requisição como erro
                            this.$store.state.transacao.status = 'erro'
                            // Recuperando erros da requisição
                            this.$store.state.transacao.mensagem = errors.response.data.erro

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false
                        })
                }).catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false
                })
            }
        },
        // Executa uma ação quando o componente estiver montado
        async mounted() {
            await this.carregarLista()
            await this.carregarUser()
        }
    }
</script>