<template>
	<div>
		<div v-if="dadosFiltrados.length > 0">
			<div class="card border-0 mt-3" v-for="d in dadosFiltrados" :key="d.id">
				<a
					style="cursor: pointer;"
					data-toggle="modal"
					data-target="#atividadeModal"
					@click="setStore(d)"
				>
					<div class="card-body bg-primary-color card-activities-body">
						<p class="font-weight-bold text-white text-activities">
							{{d.atividade | textoCurto}}
							<!-- <img v-if="d.situacao == 'Pendente'" class="float-right img-fluid img-activities" src="/storage/icons/pendente.png" alt="Icone de Atividade Pendente">
							<img v-if="d.situacao == 'Concluído'" class="float-right img-fluid img-activities" src="/storage/icons/concluido.png" alt="Icone de Atividade Concluida">-->
						</p>
						<p class="text-white date-activities">
							Prazo: {{d.data_prazo | formataData}}
							<!-- <span class="float-right">{{d.situacao}}</span> -->
						</p>
					</div>
				</a>
			</div>
		</div>
		<div v-else>
			<div class="card border-0 mt-3">
				<div class="card-body bg-primary-color card-activities-body">
					<p
						class="font-weight-bold text-center pt-2 text-white text-activities"
					>Nenhuma atividade encontrada!</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["dados", "atividadesUsuarios", "user"],
	computed: {
		dadosFiltrados() {
			let atividadeUsuario = [];
			// Recupera atividades respondidas do usuário logado
			this.atividadesUsuarios.forEach((atividade) => {
				if (atividade.user_id == this.user.id) {
					atividadeUsuario.push(atividade.atividade_id);
				}
			});

			let dadosFiltrados = [];
			// Recupera atividades que correspondem ao cargo, setor e filial do usuário logado
			this.dados.forEach((dado) => {
				dado.cargos.forEach((cargo) => {
					if (cargo.id == this.user.cargo_id) {
						dado.setores.forEach((setor) => {
							if (setor.id == this.user.setor_id) {
								dado.filiais.forEach((filial) => {
									if (filial.id == this.user.filial_id) {
										let itemFiltrado = {};
										itemFiltrado["id"] = dado.id;
										itemFiltrado["atividade"] =
											dado.atividade;
										itemFiltrado["descricao"] =
											dado.descricao;
										itemFiltrado["data_prazo"] =
											dado.data_prazo;
										itemFiltrado["perguntas"] =
											dado.perguntas;
										itemFiltrado["alternativas"] =
											dado.alternativas;
										// Verifica se a atividade recuperada está pendente ou concluída
										if (
											atividadeUsuario.includes(dado.id)
										) {
											itemFiltrado["situacao"] =
												"Concluído";
										} else {
											itemFiltrado["situacao"] =
												"Pendente";
										}
										dadosFiltrados.push(itemFiltrado);
									}
								});
							}
						});
					}
				});
			});
			return dadosFiltrados;
		},
	},
	methods: {
		setStore(obj) {
			// Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
			this.$store.state.transacao.mensagem = "";
			this.$store.state.transacao.status = "";
			this.$store.state.transacao.dados = "";
			this.$store.state.item = {};
			this.$store.state.atividadesUsuarios = [];
			// Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
			this.$store.state.item = obj;
			this.atividadesUsuarios.forEach((atividade) => {
				if (atividade.user_id == this.user.id) {
					this.$store.state.atividadesUsuarios.push(atividade);
				}
			});
		},
	},
};
</script>