<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 mb-5">
                <!-- Inicio do card de pesquisa -->
                <card-component titulo="Pesquisa de Atividades">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <input-container-component id="inputAtividade" label="Atividade" id-help="atividadeHelp" texto-help="Informe o nome do atividade que deseja pesquisar!">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input type="text" class="form-control" id="inputAtividade" aria-describedby="atividadeHelp" placeholder="Digite aqui!" autocomplete="off" v-model="busca.atividade" @keydown.enter="pesquisar()">
                        </input-container-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <button type="submit" class="btn btn-primary-generic btn-sm float-right" @click="pesquisar()">Pesquisar</button>
                    </template>
                </card-component>
                <!-- Fim do card de pesquisa -->
            </div>

            <div class="col-md-12">
                <!-- Inicio do card de listagem de atividades -->
                <card-component titulo="Lista de Atividades">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <!-- filiais: {campo: 'filial', titulo: 'Filiais', tipo: 'modal', dataTarget: '#modalFiliais', classe: 'secondary'}, -->
                        <table-component 
                            :dados="atividades.data" 
                            :titulos="{  // Relação de titulos a serem listados e seus respectivos titulos
                                id: {titulo: 'ID', tipo: 'inteiro'},
                                atividade: {titulo: 'Atividade', tipo: 'texto'},
                                descricao: {titulo: '', tipo: 'nao_exibir'},
                                status: {titulo: '', tipo: 'nao_exibir'},
                                perguntas: {titulo: '', tipo: 'nao_exibir'},
                                alternativas: {titulo: '', tipo: 'nao_exibir'},
                                data_prazo: {titulo: 'Data prazo', tipo: 'data'},
                                created_at: {titulo: 'Data de criação', tipo: 'data_hora'},
                                user: {campo: 'name', titulo: 'Último usuário', tipo: 'chave'},
                                cargos: {titulo: '', tipo: 'nao_exibir'},
                                setores: {titulo: '', tipo: 'nao_exibir'},
                                filiais: {titulo: '', tipo: 'nao_exibir'},
                            }"
                            
                            :botoes="{   // Relação de botoes a serem listados e suas respectivas propriedades
                                visualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalVisualizarAtividade', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                atualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalAtualizarAtividade', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                excluir: {visivel: true, dataToggle: 'modal', dataTarget: '#modalRemoverAtividade', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso}
                            }"
                            
                        >
                        </table-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <div class="row align-items-center">
                            <div class="col">
                                <paginate-component>
                                    <li @click="paginacao(atividades.links[0])" class="page-item">
                                        <a class="page-link" v-if="atividades.links" v-html="atividades.links[0].label"></a>
                                    </li>
                                    <li v-if="atividades.current_page != 1" @click="paginacao(atividades.links[atividades.current_page-1])" class="page-item">
                                        <a class="page-link" v-if="atividades.links" v-html="atividades.links[atividades.current_page-1].label"></a>
                                    </li>
                                    <li @click="paginacao(atividades.links[atividades.current_page])" class="page-item active">
                                        <a class="page-link" v-if="atividades.links" v-html="atividades.links[atividades.current_page].label"></a>
                                    </li>
                                    <li v-if="atividades.last_page > 1 && atividades.last_page != atividades.current_page" @click="paginacao(atividades.links[atividades.current_page+1])" class="page-item">
                                        <a class="page-link" v-if="atividades.links" v-html="atividades.links[atividades.current_page+1].label"></a>
                                    </li>
                                    <li v-if="atividades.last_page > 1 && atividades.last_page != atividades.current_page" @click="paginacao(atividades.links[atividades.current_page+1])" class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                    <li v-else class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                </paginate-component>
                            </div>
                            <div class="col mt-2 mt-md-0">
                                <button type="button" class="btn btn-primary-generic btn-sm float-right" data-toggle="modal" data-target="#modalAtividade" @click="limparTransacao()">Adicionar</button>
                            </div>
                        </div>
                    </template>
                </card-component>
                <!-- Fim do card de listagem de atividades -->
            </div>
        </div>

        <!-- Inicio Modal de adição de novos atividades-->
        <modal-component id="modalAtividade" titulo="Adicionar Atividade" tamanho="lg">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo>         
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                       
                <input-container-component id="novoAtividade" label="Nome da Atividade" id-help="novoAtividadeHelp" texto-help="Informe o nome da atividade.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="text" class="form-control" id="novoAtividade" aria-describedby="novoAtividadeHelp" placeholder="Nome da Atividade" autocomplete="off" v-model="atividade">
                </input-container-component>
                <input-container-component id="novoDescricao" label="Descrição da Atividade" id-help="novoDescricaoHelp" texto-help="Informe a descrição da atividade.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <textarea class="form-control" id="novoDescricao" aria-describedby="novoDescricaoHelp" rows="3" v-model="descricao"></textarea>
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-6">
                        <input-container-component id="novoStatus" label="Status da Atividade" id-help="novoStatusHelp" texto-help="Informe o status da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoStatus" v-model="statusAtividade">
                                <option value="" selected> -- Selecione -- </option>
                                <option value="a">Ativa</option>
                                <option value="i">Inativa</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-6">
                        <input-container-component id="novoDataPrazo" label="Data Prazo da Atividade" id-help="novoDataPrazoHelp" texto-help="Informe a data prazo da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input type="date" class="form-control" id="novoDataPrazo" aria-describedby="novoDataPrazoHelp" placeholder="Data Prazo da Atividade" v-model="dataPrazo">
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="novoCargos" label="Cargos da Atividade" id-help="novoCargosHelp" texto-help="Informe os cargos da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoCargos" size="3" multiple v-model="cargosSelecionados">
                                <option v-for="c in $store.state.cargos" :key="c.id" :value="c.id">{{c.cargo}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="novoSetores" label="Setores da Atividade" id-help="novoSetoresHelp" texto-help="Informe os setores da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoSetores" size="3" multiple v-model="setoresSelecionados">
                                <option v-for="s in $store.state.setores" :key="s.id" :value="s.id">{{s.setor}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="novoFiliais" label="Filiais da Atividade" id-help="novoFiliaisHelp" texto-help="Informe os filiais da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoFiliais" size="3" multiple v-model="filiaisSelecionadas">
                                <option v-for="f in $store.state.filiais" :key="f.id" :value="f.id">{{f.filial}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                </div>   
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro realizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao realizar cadastro do atividade" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="salvar()">Salvar</button>
            </template>            
        </modal-component>
        <!-- Fim Modal de adição de novos atividades -->

        <!-- Inicio Modal de visualização de atividades -->
        <modal-component id="modalVisualizarAtividade" titulo="Visualizar Atividade" tamanho="lg">
            <template v-slot:conteudo  v-if="$store.state.modal == '#modalVisualizarAtividade'">
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Nome da Atividade">
                    <input type="text" class="form-control" :value="$store.state.item.atividade" disabled>
                </input-container-component>
                <input-container-component label="Descrição da Atividade">
                    <textarea class="form-control" rows="3" :value="$store.state.item.descricao" disabled></textarea>
                </input-container-component>
                <input-container-component label="Status">
                    <input type="text" class="form-control" value="Ativa" v-if="$store.state.item.status == 'a'" disabled>
                    <input type="text" class="form-control" value="Inativa" v-if="$store.state.item.status == 'i'" disabled>
                </input-container-component>
                <input-container-component label="Último usuário">
                    <input type="text" class="form-control" :value="$store.state.item.user.name" disabled>
                </input-container-component>
                <input-container-component label="Data prazo">
                    <input type="text" class="form-control" :value="$store.state.item.data_prazo | formataData" disabled>
                </input-container-component>
                <input-container-component label="Data de criação">
                    <input type="text" class="form-control" :value="$store.state.item.created_at | formataDataHora" disabled>
                </input-container-component>

                <div class="accordion" id="accordionAtividades">
                    <div class="py-2">
                        <button class="btn btn-primary-generic btn-sm" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Cargos
                        </button>
                        <button class="btn btn-primary-generic  btn-sm collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Setores
                        </button>
                        <button class="btn btn-primary-generic  btn-sm collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Filiais
                        </button>
                        <button class="btn btn-primary-generic  btn-sm collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Perguntas
                        </button>
                    </div>
                    <div class="card">
                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionAtividades">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Cargo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="c in $store.state.item.cargos" :key="c.id">
                                            <td>{{c.id}}</td>
                                            <td>{{c.cargo}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionAtividades">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Setor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="s in $store.state.item.setores" :key="s.id">
                                            <td>{{s.id}}</td>
                                            <td>{{s.setor}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionAtividades">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Filial</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="f in $store.state.item.filiais" :key="f.id">
                                            <td>{{f.id}}</td>
                                            <td>{{f.filial}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionAtividades">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Pergunta</th>
                                            <th class="text-center">Atividade ID</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="p in $store.state.item.perguntas" :key="p.id">
                                        <tr v-if="!($store.state.statusArray.includes('sucesso_'+p.id))">
                                            <td>{{p.id}}</td>
                                            <td>{{p.pergunta | textoCurto}}</td>
                                            <td class="text-center">{{p.atividade_id}}</td>
                                            <td class="text-center">
                                                <button class="btn btn-primary-generic btn-sm" type="button" data-toggle="collapse" :data-target="'#collapse'+p.id" aria-expanded="false" :aria-controls="'collapse'+p.id">
                                                    Alternativas
                                                </button>
                                                <button class="btn btn-danger-generic btn-sm" type="button" @click="excluirPerguntas(p.id)">
                                                    Excluir
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" class="pt-0 pb-0">
                                                <div  class="collapse pt-3 pb-3" :id="'collapse'+p.id">
                                                    <div class="card card-body">
                                                        <table class="table table-hover table-sm-responsive">
                                                            <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Alternativa</th>
                                                                    <th class="text-center">Pergunta ID</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody v-for="a in $store.state.item.alternativas" :key="a.id">
                                                                <tr v-if="!($store.state.statusArray.includes('sucesso_alt_'+a.id))">
                                                                    <td v-if="a.pergunta_id == p.id">{{a.id}}</td>
                                                                    <td v-if="a.pergunta_id == p.id">{{a.alternativa | textoCurto}}</td>
                                                                    <td v-if="a.pergunta_id == p.id" class="text-center">{{a.pergunta_id}}</td>
                                                                    <td v-if="a.pergunta_id == p.id" class="text-center">
                                                                        <button class="btn btn-danger-generic btn-sm" type="button" @click="excluirAlternativas(a.id)">
                                                                            Excluir
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
            </template>
        </modal-component>
        <!-- Fim Modal de visualização de atividades-->

        <!-- Inicio Modal de atualização de atividades-->
        <modal-component id="modalAtualizarAtividade" titulo="Atualizar Atividade" tamanho="lg">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo v-if="$store.state.modal == '#modalAtualizarAtividade'">   
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                              
                <input-container-component id="atualizarAtividade" label="Nome da Atividade" id-help="atualizarAtividadeHelp" texto-help="Informe o nome da atividade.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="text" class="form-control" id="atualizarAtividade" aria-describedby="atualizarAtividadeHelp" placeholder="Nome da Atividade" v-model="$store.state.item.atividade">
                </input-container-component>
                <input-container-component id="atualizarDescricao" label="Descrição da Atividade" id-help="atualizarDescricaoHelp" texto-help="Informe a descrição da atividade.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <textarea class="form-control" id="atualizarAtividade" aria-describedby="atualizarDescricaoHelp" rows="3" v-model="$store.state.item.descricao"></textarea>
                </input-container-component>
                <div class="row">
                    <div class="col-md-6">
                        <input-container-component id="atualizarStatus" label="Status da Atividade" id-help="atualizarStatusHelp" texto-help="Informe o status da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarStatus" v-model="$store.state.item.status">
                                <option value="a">Ativa</option>
                                <option value="i">Inativa</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-6">
                        <input-container-component id="atualizarDataPrazo" label="Data Prazo da Atividade" id-help="atualizarDataPrazoHelp" texto-help="Informe a data prazo da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input type="date" class="form-control" id="atualizarDataPrazo" aria-describedby="atualizarDataPrazoHelp" placeholder="Data Prazo da Atividade" v-model="$store.state.item.data_prazo">
                        </input-container-component>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="atualizarCargos" label="Cargos da Atividade" id-help="atualizarCargosHelp" texto-help="Informe os cargos da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarCargos" size="3" multiple v-model="$store.state.cargosSelecionados">
                                <option v-for="c in $store.state.cargos" :key="c.id" :value="c.id">{{c.cargo}}</option>
                            </select>
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="atualizarSetores" label="Setores da Atividade" id-help="atualizarSetoresHelp" texto-help="Informe os setores da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarSetores" size="3" multiple v-model="$store.state.setoresSelecionados">
                                <option v-for="s in $store.state.setores" :key="s.id" :value="s.id">{{s.setor}}</option>
                            </select>
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="atualizarFiliais" label="Filiais da Atividade" id-help="atualizarFiliaisHelp" texto-help="Informe os filiais da atividade.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarFiliais" size="3" multiple v-model="$store.state.filiaisSelecionadas">
                                <option v-for="f in $store.state.filiais" :key="f.id" :value="f.id">{{f.filial}}</option>
                            </select>
                        </input-container-component> 
                    </div>
                </div> 
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro atualizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao atualizar cadastro do atividade" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="atualizar()">Atualizar</button>
            </template>                      
        </modal-component>
        <!-- Fim Modal de atualização de atividades-->

        <!-- Inicio Modal de remoção de atividades-->
        <modal-component id="modalRemoverAtividade" titulo="Remover Atividade" tamanho="md">
            <template v-slot:conteudo v-if="$store.state.transacao.status != 'sucesso'">
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                 
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Nome da Atividade">
                    <input type="text" class="form-control" :value="$store.state.item.atividade" disabled>
                </input-container-component>
            </template>
            <template v-slot:alertas>
                <alert-component tipo="success" titulo="Registro removido com sucesso" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" titulo="Falha ao remover registro" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-danger-generic" @click="excluir()" v-if="$store.state.transacao.status != 'sucesso'">Excluir</button>
            </template>
        </modal-component>
        <!-- Fim Modal de remoção de atividades-->
    </div>
</template>

<script>
    export default {
        // Define os atributos que poderão ser usados nesta instancia do Vue
        data() {
            return{
                urlBase: 'api/atividade',
                urlPaginacao: '',
                urlFiltro: '',
                numPaginas: '&paginas=30',
                atividade: '',
                descricao: '',
                statusAtividade: '',
                dataPrazo: '',
                cargosSelecionados: [],
                setoresSelecionados: [],
                filiaisSelecionadas: [],
                atividades: { data: [] },
                busca: { atividade: '' }
            }            
        },
        // Define os metodos usados nesta instancia do Vue
        methods: {
            // Metodo para buscar os registros da tabela atividades
            carregarLista() {
                // Montando url de requisição com possiveis parâmetros de filtro e paginação
                let url = this.$store.state.apiHost + this.urlBase + '?' + this.urlPaginacao + '&order=atividade' + this.urlFiltro + this.numPaginas
                // Requisição de consulta de registros na tabela atividades no banco
                return axios.get(url)
                    .then(response => {
                        this.atividades = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            // Metodo para buscar os registros da tabela cargos
            carregarCargos() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarCargos')
            },
            // Metodo para buscar os registros da tabela setores
            carregarSetores() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarSetores')
            },
            // Metodo para buscar os registros da tabela filiais
            carregarFiliais() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarFiliais')
            },
            // Metodo para buscar os registros da tabela users
            carregarUser() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarUser')
            },
            // Metodo para criar links de requisição de buscas paginadas
            paginacao(l) {
                if (l.url) {
                    // Capturando apenas parâmetro de pagina da request de paginação
                    this.urlPaginacao = l.url.split('?')[1] 
                    this.carregarLista()
                }                
            },
            // Metodo de pesquisa de registros na tabela atividades por nome do registro
            pesquisar() {
                let filtro = ''
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.busca) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.busca[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':like:%' + this.busca[chave].substr(1) + '%'
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                // Define a primeira pagina para o resultado da busca
                this.urlPaginacao = 'page=1'
                this.carregarLista()
            },
            limparTransacao() {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.atividade = ''
                this.descricao = ''
                this.statusAtividade = ''
                this.dataPrazo = ''
                this.cargosSelecionados = []
                this.setoresSelecionados = []
                this.filiaisSelecionadas = []
                this.carregarCargos()
                this.carregarSetores()
                this.carregarFiliais()
                this.carregarUser()
            },
            // Metodo para inserção de registros na tabela atividades no banco
            salvar() {   
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('atividade', this.atividade)
                formData.append('descricao', this.descricao)
                formData.append('status', this.statusAtividade)
                formData.append('data_prazo', this.dataPrazo)
                formData.append('user_id', this.$store.state.user.id)

                if (this.cargosSelecionados.length > 0 || this.$store.state.user.nivel_acesso == 1) {
                    this.cargosSelecionados.forEach(cargo => {
                        formData.append('cargos[]', cargo)
                    });
                } else {
                    formData.append('cargos[]', this.$store.state.user.cargo_id)
                }

                if (this.setoresSelecionados.length > 0 || this.$store.state.user.nivel_acesso == 1) {
                    this.setoresSelecionados.forEach(setor => {
                        formData.append('setores[]', setor)
                    });
                } else {
                    formData.append('setores[]', this.$store.state.user.setor_id)
                }
                
                if (this.filiaisSelecionadas.length > 0 || this.$store.state.user.nivel_acesso == 1) {
                    this.filiaisSelecionadas.forEach(filial => {
                        formData.append('filiais[]', filial)
                    });
                } else {
                    formData.append('filiais[]', this.$store.state.user.filial_id)
                }

                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }                
                // Requisição de inserção de registros atraves da biblioteca axios
                axios.post(this.$store.state.apiHost + this.urlBase, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando registro cadastrado no banco
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id 
                        this.$store.state.transacao.dados = ''
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Registro inserido com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Falha ao tentar inserir o registro', 'Erro', 'error');
                    })
            },
            atualizar() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Recupera a urlBase e concatena com o id do registro a se atualizado
                let url = this.$store.state.apiHost + this.urlBase+'/'+this.$store.state.item.id
                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('_method', 'patch')
                formData.append('atividade', this.$store.state.item.atividade)
                formData.append('descricao', this.$store.state.item.descricao)
                formData.append('status', this.$store.state.item.status)
                formData.append('data_prazo', this.$store.state.item.data_prazo)
                formData.append('user_id', this.$store.state.user.id)
                this.$store.state.cargosSelecionados.forEach(cargo => {
                    formData.append('cargos[]', cargo)
                });
                this.$store.state.setoresSelecionados.forEach(setor => {
                    formData.append('setores[]', setor)
                });
                this.$store.state.filiaisSelecionadas.forEach(filial => {
                    formData.append('filiais[]', filial)
                });

                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                } 
                // Requisição de atualização de registros atraves da biblioteca axios
                axios.post(url, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando a mensagem da requisição
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id
                        this.$store.state.transacao.dados = ''
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Registro alterado com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Falha ao tentar alterar o registro', 'Erro', 'error');
                    })
            },
            excluir() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Abre uma caixa de dialogo para confirmar a exclusão do registro
                this.$confirm(
                    'Tem certeza que deseja remover o registro?',
                    'Exclusão de Registro',
                    'question'
                ).then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url = this.$store.state.apiHost + this.urlBase+'/'+ this.$store.state.item.id
                    // Instanciando formulario e definindo o _method delete
                    let formData = new FormData()
                    formData.append('_method', 'delete')
                    // Requisição de remoção de registro por id
                    axios.post(url, formData)
                        .then(response => {
                            // Definindo status da requisição como sucesso
                            this.$store.state.transacao.status = 'sucesso'
                            // Recuperando a mensagem da requisição
                            this.$store.state.transacao.mensagem = response.data.msg
                            this.$store.state.transacao.dados = ''
                            this.carregarLista()

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false
                        })
                        .catch(errors => {
                            // Definindo status da requisição como erro
                            this.$store.state.transacao.status = 'erro'
                            // Recuperando erros da requisição
                            this.$store.state.transacao.mensagem = errors.response.data.erro

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false
                        })
                }).catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false
                })
            },
            excluirPerguntas(id) {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Definindo o valor inicial como vazio
                this.$store.state.transacao.status = ''

                // Abre uma caixa de dialogo para confirmar a exclusão do registro
                this.$confirm(
                    'Tem certeza que deseja remover o registro?',
                    'Exclusão de Registro',
                    'question'
                ).then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url = this.$store.state.apiHost + 'api/pergunta/'+ id
                    // Instanciando formulario e definindo o _method delete
                    let formData = new FormData()
                    formData.append('_method', 'delete')
                    // Requisição de remoção de registro por id
                    axios.post(url, formData)
                        .then(response => {
                            // Definindo status da requisição como sucesso
                            this.$store.state.statusArray.push('sucesso_'+id) 

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false

                            this.$alert(response.data.msg, 'Sucesso', 'success');
                        })
                        .catch(errors => {
                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false

                            this.$alert(errors.response.data.erro, 'Erro', 'error');
                        })
                }).catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false
                })
            },
            excluirAlternativas(id) {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Definindo o valor inicial como vazio
                this.$store.state.transacao.status = ''

                // Abre uma caixa de dialogo para confirmar a exclusão do registro
                this.$confirm(
                    'Tem certeza que deseja remover o registro?',
                    'Exclusão de Registro',
                    'question'
                ).then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url = this.$store.state.apiHost + 'api/alternativa/'+ id
                    // Instanciando formulario e definindo o _method delete
                    let formData = new FormData()
                    formData.append('_method', 'delete')
                    // Requisição de remoção de registro por id
                    axios.post(url, formData)
                        .then(response => {
                            // Definindo status da requisição como sucesso
                            this.$store.state.statusArray.push('sucesso_alt_'+id) 

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false

                            this.$alert(response.data.msg, 'Sucesso', 'success');
                        })
                        .catch(errors => {
                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false

                            this.$alert(errors.response.data.erro, 'Erro', 'error');
                        })
                }).catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false
                })
            }
        },
        // Executa uma ação quando o componente estiver montado
        async mounted() {
            await this.carregarLista()
            await this.carregarUser()
        }
    }
</script>