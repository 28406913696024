<template>
    <div>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">ID</th>
                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">Atividade</th>
                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">Data Prazo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="dadosFiltrados.length != 0">
                    <!-- Percorre o array de objetos e para cada objeto percorre seus devidos campos -->
                    <tr v-for="obj, key in dadosFiltrados" :key="key">
                        <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{obj.id}}</td>
                        <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{obj.atividade}}</td>
                        <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{obj.data_prazo | formataDataSimples}}</td>
                        <td class="text-center">
                            <button class="btn btn-primary-generic btn-sm mb-1 mb-md-0" @click="setStore(obj)" data-toggle="modal" data-target="#modalVisualizarAtividadesConcluidas">Visualizar</button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="4" class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">Nenhuma atividade encontrada!</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>    
</template>

<script>
    export default {
        props: ['dados', 'atividadesUsuarios', 'user'],
        computed: {
            dadosFiltrados () {

                let atividadeUsuario = []
                // Recupera atividades respondidas do usuário logado
                this.atividadesUsuarios.forEach(atividade => {
                    if (this.user.length > 0) {
                        this.user.forEach(u => {
                            if (atividade.user_id == u.id) {
                                atividadeUsuario.push(atividade.atividade_id)
                            }
                        })
                    } else {
                        atividadeUsuario.push(atividade.atividade_id)
                    }
                })
                
                let dadosFiltrados = [] 

                // Recupera atividades concluidas
                this.dados.forEach(dado => {
                    let itemFiltrado = {}
                    
                    // Verifica se a atividade recuperada está pendente ou concluída 
                    if (atividadeUsuario.includes(dado.id)) {
                        itemFiltrado['id'] = dado.id
                        itemFiltrado['atividade'] = dado.atividade
                        itemFiltrado['descricao'] = dado.descricao
                        itemFiltrado['data_prazo'] = dado.data_prazo                                            
                        itemFiltrado['perguntas'] = dado.perguntas
                        itemFiltrado['alternativas'] = dado.alternativas
                        itemFiltrado['situacao'] = 'Concluído'
                        dadosFiltrados.push(itemFiltrado)  
                    }                                        
                });
                return dadosFiltrados
            }
        },
        methods: {
            setStore(obj) {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.$store.state.item = {}
                this.$store.state.atividadesUsuarios = []
                // Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
                this.$store.state.item = obj

                this.atividadesUsuarios.forEach(atividade => {
                    if (atividade.atividade_id == obj.id) {
                        this.$store.state.atividadesUsuarios.push(atividade)
                    }                    
                }) 
            }
        }
    }
</script>