<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-md-6 mb-5">
				<!-- Inicio do card de pesquisa -->
				<card-component titulo="Pesquisa de Publicações">
					<!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
					<template v-slot:conteudo>
						<input-container-component
							id="inputPublicacao"
							label="Publicação"
							id-help="publicacaoHelp"
							texto-help="Informe o nome da publicação que deseja pesquisar!"
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<input
								type="text"
								class="form-control"
								id="inputPublicacao"
								aria-describedby="publicacaoHelp"
								placeholder="Digite aqui!"
								autocomplete="off"
								v-model="busca.publicacao"
								@keydown.enter="pesquisar()"
							/>
						</input-container-component>
					</template>
					<!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
					<template v-slot:rodape>
						<button
							type="submit"
							class="btn btn-primary-generic btn-sm float-right"
							@click="pesquisar()"
						>Pesquisar</button>
					</template>
				</card-component>
				<!-- Fim do card de pesquisa -->
			</div>

			<div class="col-md-12">
				<!-- Inicio do card de listagem de publicações -->
				<card-component titulo="Lista de Publicações">
					<!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
					<template v-slot:conteudo>
						<!-- filiais: {campo: 'filial', titulo: 'Filiais', tipo: 'modal', dataTarget: '#modalFiliais', classe: 'secondary'}, -->
						<table-component
							:dados="publicacoes.data"
							:titulos="{  // Relação de titulos a serem listados e seus respectivos titulos
                                id: {titulo: 'ID', tipo: 'inteiro'},
                                publicacao: {titulo: 'Publicação', tipo: 'texto'},
                                status: {titulo: 'Situação', tipo: 'status'},
                                created_at: {titulo: 'Data de criação', tipo: 'data_hora'},
                                user: {campo: 'name', titulo: 'Último usuário', tipo: 'chave'},
                                descricao: {titulo: '', tipo: 'nao_exibir'},
                                texto: {titulo: '', tipo: 'nao_exibir'},                                
                                treinamento: {titulo: '', tipo: 'nao_exibir'},
                                importante: {titulo: '', tipo: 'nao_exibir'},
                                categoria: {titulo: '', tipo: 'nao_exibir'},
                                publicacoes_imagens: {titulo: '', tipo: 'nao_exibir'},
                                agenda: {titulo: '', tipo: 'nao_exibir'},
                                cargos: {titulo: '', tipo: 'nao_exibir'},
                                setores: {titulo: '', tipo: 'nao_exibir'},
                                filiais: {titulo: '', tipo: 'nao_exibir'},
                            }"
							:botoes="{   // Relação de botoes a serem listados e suas respectivas propriedades
                                visualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalVisualizarPublicacao', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                atualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalAtualizarPublicacao', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                excluir: {visivel: true, dataToggle: 'modal', dataTarget: '#modalRemoverPublicacao', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso}
                            }"
						></table-component>
					</template>
					<!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
					<template v-slot:rodape>
						<div class="row align-items-center">
							<div class="col">
								<paginate-component>
									<li @click="paginacao(publicacoes.links[0])" class="page-item">
										<a class="page-link" v-if="publicacoes.links" v-html="publicacoes.links[0].label"></a>
									</li>
									<li
										v-if="publicacoes.current_page != 1"
										@click="paginacao(publicacoes.links[publicacoes.current_page-1])"
										class="page-item"
									>
										<a
											class="page-link"
											v-if="publicacoes.links"
											v-html="publicacoes.links[publicacoes.current_page-1].label"
										></a>
									</li>
									<li
										@click="paginacao(publicacoes.links[publicacoes.current_page])"
										class="page-item active"
									>
										<a
											class="page-link"
											v-if="publicacoes.links"
											v-html="publicacoes.links[publicacoes.current_page].label"
										></a>
									</li>
									<li
										v-if="publicacoes.last_page > 1 && publicacoes.last_page != publicacoes.current_page"
										@click="paginacao(publicacoes.links[publicacoes.current_page+1])"
										class="page-item"
									>
										<a
											class="page-link"
											v-if="publicacoes.links"
											v-html="publicacoes.links[publicacoes.current_page+1].label"
										></a>
									</li>
									<li
										v-if="publicacoes.last_page > 1 && publicacoes.last_page != publicacoes.current_page"
										@click="paginacao(publicacoes.links[publicacoes.current_page+1])"
										class="page-item"
									>
										<a class="page-link" v-html="'Próximo &raquo;'"></a>
									</li>
									<li v-else class="page-item">
										<a class="page-link" v-html="'Próximo &raquo;'"></a>
									</li>
								</paginate-component>
							</div>
							<div class="col mt-2 mt-md-0">
								<button
									type="button"
									class="btn btn-primary-generic btn-sm float-right"
									data-toggle="modal"
									data-target="#modalPublicacao"
									@click="limparTransacao()"
								>Adicionar</button>
							</div>
						</div>
					</template>
				</card-component>
				<!-- Fim do card de listagem de publicações -->
			</div>
		</div>

		<!-- Inicio Modal de adição de novos publicações-->
		<modal-component id="modalPublicacao" titulo="Adicionar Publicação" tamanho="lg">
			<!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
			<template v-slot:conteudo>
				<!-- Chama o component de loader no envio da requisição -->
				<loader
					v-if="$store.state.loader"
					:object="$store.state.colorObject"
					:size="$store.state.size"
					:speed="$store.state.speed"
					:bg="$store.state.colorBg"
					:opacity="$store.state.opacity"
					:disableScrolling="$store.state.disableScrolling"
					:name="$store.state.name"
				></loader>

				<input-container-component
					id="novoPublicacao"
					label="Nome da Publicação"
					id-help="novoPublicacaoHelp"
					texto-help="Informe o nome da publicação."
				>
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<input
						type="text"
						class="form-control"
						id="novoPublicacao"
						aria-describedby="novoPublicacaoHelp"
						placeholder="Nome da Publicação"
						autocomplete="off"
						v-model="publicacao"
					/>
				</input-container-component>
				<input-container-component
					id="novoDescricao"
					label="Descrição da Publicação"
					id-help="novoDescricaoHelp"
					texto-help="Informe a descrição da publicação."
				>
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<textarea
						class="form-control"
						id="novoDescricao"
						aria-describedby="novoDescricaoHelp"
						rows="3"
						v-model="descricao"
					></textarea>
				</input-container-component>
				<input-container-component
					id="novoTexto"
					label="Texto da Publicação"
					id-help="novoTextoHelp"
					texto-help="Informe o texto da publicação."
				>
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<ckeditor
						:config="editorConfig"
						:editor="editor"
						id="novoTexto"
						aria-describedby="novoTextoHelp"
						v-model="texto"
					></ckeditor>
				</input-container-component>
				<div class="form-row">
					<div class="col-md-4">
						<input-container-component
							id="novoImportante"
							label="Aviso Importante"
							id-help="novoImportanteHelp"
							texto-help="Informe se a publicação é um aviso importante."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select class="form-control" id="novoImportante" v-model="importante">
								<option value>-- Selecione --</option>
								<option value="s">Sim</option>
								<option value="n">Não</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component
							id="novoTreinamento"
							label="Treinamento"
							id-help="novoTreinamentoHelp"
							texto-help="Informe se a publicação é um treinamento."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select class="form-control" id="novoTreinamento" v-model="treinamento">
								<option value>-- Selecione --</option>
								<option value="s">Sim</option>
								<option value="n">Não</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4" v-if="treinamento == 's'">
						<input-container-component
							id="novoAgendaData"
							label="Data do Evento da Publicação"
							id-help="novoAgendaDataHelp"
							texto-help="Informe a data do evento da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<input
								class="form-control"
								type="datetime-local"
								id="novoAgendaData"
								aria-describedby="novoAgendaDataHelp"
								v-model="dataEvento"
							/>
						</input-container-component>
					</div>
				</div>
				<div class="form-row">
					<div class="col-md-5">
						<input-container-component
							id="novoImagens"
							label="Imagens da Publicação"
							id-help="novoImagensHelp"
							texto-help="Selecione as imagens desejadas."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<input
								type="file"
								class="form-control-file"
								id="novoImagens"
								aria-describedby="novoImagensHelp"
								placeholder="Selecione as imagens."
								multiple
								@change="carregarImagens($event)"
							/>
						</input-container-component>
					</div>
					<div class="col-md-3">
						<input-container-component
							v-if="$store.state.user.nivel_acesso == 1"
							id="novoStatus"
							label="Status"
							id-help="novoStatusHelp"
							texto-help="Informe a situação da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select class="form-control" id="novoStatus" v-model="status">
								<option value>-- Selecione --</option>
								<option value="a">Ativa</option>
								<option value="i">Inativa</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component
							id="novoCategoria"
							label="Categoria"
							id-help="novoCategoriaHelp"
							texto-help="Informe os cargos da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select class="form-control" id="novoCategoria" v-model="categoriaId">
								<option value>-- Selecione --</option>
								<option
									v-for="ctg in $store.state.categorias"
									:key="ctg.id"
									:value="ctg.id"
								>{{ctg.categoria}}</option>
							</select>
						</input-container-component>
					</div>
				</div>
				<div class="form-row">
					<div class="col-md-4">
						<input-container-component
							v-if="$store.state.user.nivel_acesso == 1"
							id="novoCargos"
							label="Cargos da Publicação"
							id-help="novoCargosHelp"
							texto-help="Informe os cargos da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select class="form-control" id="novoCargos" size="3" multiple v-model="cargosSelecionados">
								<option v-for="c in $store.state.cargos" :key="c.id" :value="c.id">{{c.cargo}}</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component
							v-if="$store.state.user.nivel_acesso == 1"
							id="novoSetores"
							label="Setores da Publicação"
							id-help="novoSetoresHelp"
							texto-help="Informe os setores da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select
								class="form-control"
								id="novoSetores"
								size="3"
								multiple
								v-model="setoresSelecionados"
							>
								<option v-for="s in $store.state.setores" :key="s.id" :value="s.id">{{s.setor}}</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component
							v-if="$store.state.user.nivel_acesso == 1"
							id="novoFiliais"
							label="Filiais da Publicação"
							id-help="novoFiliaisHelp"
							texto-help="Informe os filiais da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select
								class="form-control"
								id="novoFiliais"
								size="3"
								multiple
								v-model="filiaisSelecionadas"
							>
								<option v-for="f in $store.state.filiais" :key="f.id" :value="f.id">{{f.filial}}</option>
							</select>
						</input-container-component>
					</div>
				</div>
			</template>
			<!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
			<template v-slot:alertas>
				<alert-component
					tipo="success"
					:detalhes="$store.state.transacao"
					titulo="Cadastro realizado com sucesso"
					v-if="$store.state.transacao.status == 'sucesso'"
				></alert-component>
				<alert-component
					tipo="danger"
					:detalhes="$store.state.transacao"
					titulo="Erro ao realizar cadastro da publicação"
					v-if="$store.state.transacao.status == 'erro'"
				></alert-component>
			</template>
			<!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
			<template v-slot:rodape>
				<button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
				<button type="button" class="btn btn-primary-generic" @click="salvar()">Salvar</button>
			</template>
		</modal-component>
		<!-- Fim Modal de adição de novos publicações -->

		<!-- Inicio Modal de visualização de publicações -->
		<modal-component id="modalVisualizarPublicacao" titulo="Visualizar Publicação" tamanho="lg">
			<template v-slot:conteudo v-if="$store.state.modal == '#modalVisualizarPublicacao'">
				<!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
				<input-container-component label="ID">
					<input type="text" class="form-control" :value="$store.state.item.id" disabled />
				</input-container-component>
				<input-container-component label="Nome da Publicação">
					<input type="text" class="form-control" :value="$store.state.item.publicacao" disabled />
				</input-container-component>
				<input-container-component label="Descrição da Publicação">
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<textarea class="form-control" rows="3" v-model="$store.state.item.descricao" disabled></textarea>
				</input-container-component>
				<input-container-component label="Texto da Publicação">
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<textarea class="form-control" rows="5" v-model="$store.state.item.texto" disabled></textarea>
				</input-container-component>
				<div class="form-row">
					<div class="col-md-4">
						<input-container-component label="Treinamento">
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<input
								type="text"
								class="form-control"
								v-if="$store.state.item.importante == 's'"
								value="Sim"
								disabled
							/>
							<input
								type="text"
								class="form-control"
								v-if="$store.state.item.importante == 'n'"
								value="Não"
								disabled
							/>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component label="Aviso Importante">
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<input
								type="text"
								class="form-control"
								v-if="$store.state.item.importante == 's'"
								value="Sim"
								disabled
							/>
							<input
								type="text"
								class="form-control"
								v-if="$store.state.item.importante == 'n'"
								value="Não"
								disabled
							/>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component label="Categoria">
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<input
								type="text"
								class="form-control"
								:value="$store.state.item.categoria.categoria"
								disabled
							/>
						</input-container-component>
					</div>
				</div>
				<input-container-component label="Último usuário">
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<input type="text" class="form-control" :value="$store.state.item.user.name" disabled />
				</input-container-component>
				<input-container-component label="Data de criação">
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<input
						type="text"
						class="form-control"
						:value="$store.state.item.created_at | formataDataHora"
						disabled
					/>
				</input-container-component>
				<input-container-component v-if="$store.state.item.agenda" label="Data do Evento">
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<input
						type="text"
						class="form-control"
						:value="$store.state.item.agenda.data_evento | formataDH"
						disabled
					/>
				</input-container-component>
				<div class="accordion" id="accordionPuclicacoes">
					<div class="py-2">
						<button
							class="btn btn-primary-generic btn-sm"
							type="button"
							data-toggle="collapse"
							data-target="#collapseOne"
							aria-expanded="true"
							aria-controls="collapseOne"
						>Cargos</button>
						<button
							class="btn btn-primary-generic btn-sm collapsed"
							type="button"
							data-toggle="collapse"
							data-target="#collapseTwo"
							aria-expanded="false"
							aria-controls="collapseTwo"
						>Setores</button>
						<button
							class="btn btn-primary-generic btn-sm collapsed"
							type="button"
							data-toggle="collapse"
							data-target="#collapseThree"
							aria-expanded="false"
							aria-controls="collapseThree"
						>Filiais</button>
						<button
							class="btn btn-primary-generic btn-sm collapsed"
							type="button"
							data-toggle="collapse"
							data-target="#collapseFour"
							aria-expanded="false"
							aria-controls="collapseFour"
						>Imagens</button>
					</div>
					<div class="card">
						<div
							id="collapseOne"
							class="collapse show"
							aria-labelledby="headingOne"
							data-parent="#accordionPuclicacoes"
						>
							<div class="card-body">
								<table class="table table-hover table-sm-responsive">
									<thead>
										<tr>
											<th scope="col">ID</th>
											<th scope="col">Cargo</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="c in $store.state.item.cargos" :key="c.id">
											<td>{{c.id}}</td>
											<td>{{c.cargo}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="card">
						<div
							id="collapseTwo"
							class="collapse"
							aria-labelledby="headingTwo"
							data-parent="#accordionPuclicacoes"
						>
							<div class="card-body">
								<table class="table table-hover table-sm-responsive">
									<thead>
										<tr>
											<th scope="col">ID</th>
											<th scope="col">Setor</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="s in $store.state.item.setores" :key="s.id">
											<td>{{s.id}}</td>
											<td>{{s.setor}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="card">
						<div
							id="collapseThree"
							class="collapse"
							aria-labelledby="headingThree"
							data-parent="#accordionPuclicacoes"
						>
							<div class="card-body">
								<table class="table table-hover table-sm-responsive">
									<thead>
										<tr>
											<th scope="col">ID</th>
											<th scope="col">Filial</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="f in $store.state.item.filiais" :key="f.id">
											<td>{{f.id}}</td>
											<td>{{f.filial}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div
							id="collapseFour"
							class="collapse"
							aria-labelledby="headingThree"
							data-parent="#accordionPuclicacoes"
						>
							<div class="card-body">
								<div class="row">
									<div
										class="col-sm-6 col-md-3"
										v-for="p in $store.state.item.publicacoes_imagens"
										:key="p.id"
									>
										<img :src="'/storage/'+p.url" class="img-fluid" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:rodape>
				<button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
			</template>
		</modal-component>
		<!-- Fim Modal de visualização de publicações-->

		<!-- Inicio Modal de atualização de publicações-->
		<modal-component id="modalAtualizarPublicacao" titulo="Atualizar Publicação" tamanho="lg">
			<!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
			<template v-slot:conteudo v-if="$store.state.modal == '#modalAtualizarPublicacao'">
				<!-- Chama o component de loader no envio da requisição -->
				<loader
					v-if="$store.state.loader"
					:object="$store.state.colorObject"
					:size="$store.state.size"
					:speed="$store.state.speed"
					:bg="$store.state.colorBg"
					:opacity="$store.state.opacity"
					:disableScrolling="$store.state.disableScrolling"
					:name="$store.state.name"
				></loader>

				<input-container-component
					id="atualizarPublicacao"
					label="Nome da Publicação"
					id-help="atualizarPublicacaoHelp"
					texto-help="Informe o nome da publicação."
				>
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<input
						type="text"
						class="form-control"
						id="atualizarPublicacao"
						aria-describedby="atualizarPublicacaoHelp"
						placeholder="Nome da Publicação"
						v-model="$store.state.item.publicacao"
					/>
				</input-container-component>

				<input-container-component
					id="atualizarDescricao"
					label="Descrição da Publicação"
					id-help="atualizarDescricaoHelp"
					texto-help="Informe a descrição da publicação."
				>
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<textarea
						class="form-control"
						id="atualizarDescricao"
						aria-describedby="atualizarDescricaoHelp"
						rows="3"
						v-model="$store.state.item.descricao"
					></textarea>
				</input-container-component>
				<input-container-component
					id="atualizarTexto"
					label="Texto da Publicação"
					id-help="atualizarTextoHelp"
					texto-help="Informe o texto da publicação."
				>
					<!-- Conteudo encaminhado para o slot do componente InputContainer -->
					<ckeditor
						:config="editorConfig"
						:editor="editor"
						id="atualizarTexto"
						aria-describedby="atualizarTextoHelp"
						v-model="$store.state.item.texto"
					></ckeditor>
				</input-container-component>
				<div class="form-row">
					<div class="col-md-4">
						<input-container-component
							id="atualizarImportante"
							label="Aviso Importante"
							id-help="atualizarImportanteHelp"
							texto-help="Informe se a publicação é um aviso importante."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select class="form-control" id="atualizarImportante" v-model="$store.state.item.importante">
								<option value>-- Selecione --</option>
								<option value="s">Sim</option>
								<option value="n">Não</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component
							id="atualizarTreinamento"
							label="Treinamento"
							id-help="atualizarTreinamentoHelp"
							texto-help="Informe se a publicação é um treinamento."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select
								class="form-control"
								id="atualizarTreinamento"
								v-model="$store.state.item.treinamento"
							>
								<option value>-- Selecione --</option>
								<option value="s">Sim</option>
								<option value="n">Não</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4" v-if="$store.state.item.treinamento == 's'">
						<input-container-component
							id="atualizarAgendaData"
							label="Data do Evento da Publicação"
							id-help="atualizarAgendaDataHelp"
							texto-help="Informe a nova data do evento da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<input
								class="form-control"
								type="datetime-local"
								id="atualizarAgendaData"
								aria-describedby="atualizarAgendaDataHelp"
								v-model="dataEvento"
							/>
						</input-container-component>
					</div>
				</div>
				<div class="form-row">
					<div class="col-md-5">
						<input-container-component
							id="atualizarImagens"
							label="Imagens da Publicação"
							id-help="atualizarImagensHelp"
							texto-help="Selecione as imagens desejadas."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<input
								type="file"
								class="form-control-file"
								id="atualizarImagens"
								aria-describedby="atualizarImagensHelp"
								placeholder="Selecione as imagens."
								multiple
								@change="carregarImagens($event)"
							/>
						</input-container-component>
					</div>
					<div class="col-md-3">
						<input-container-component
							v-if="$store.state.user.nivel_acesso == 1"
							id="atualizarStatus"
							label="Status"
							id-help="atualizarStatusHelp"
							texto-help="Informe a situação da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select class="form-control" id="atualizarStatus" v-model="$store.state.item.status">
								<option value>-- Selecione --</option>
								<option value="a">Ativa</option>
								<option value="i">Inativa</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component
							id="atualizarCategoria"
							label="Categoria"
							id-help="atualizarCategoriaHelp"
							texto-help="Informe os cargos da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select
								class="form-control"
								id="atualizarCategoria"
								v-model="$store.state.item.categoria.id"
							>
								<option value>-- Selecione --</option>
								<option
									v-for="ctg in $store.state.categorias"
									:key="ctg.id"
									:value="ctg.id"
								>{{ctg.categoria}}</option>
							</select>
						</input-container-component>
					</div>
				</div>
				<div class="form-row">
					<div class="col-md-4">
						<input-container-component
							v-if="$store.state.user.nivel_acesso == 1"
							id="atualizarCargos"
							label="Cargos da Publicação"
							id-help="atualizarCargosHelp"
							texto-help="Informe os cargos da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select
								class="form-control"
								id="atualizarCargos"
								size="3"
								multiple
								v-model="$store.state.cargosSelecionados"
							>
								<option v-for="c in $store.state.cargos" :key="c.id" :value="c.id">{{c.cargo}}</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component
							v-if="$store.state.user.nivel_acesso == 1"
							id="atualizarSetores"
							label="Setores da Publicação"
							id-help="atualizarSetoresHelp"
							texto-help="Informe os setores da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select
								class="form-control"
								id="atualizarSetores"
								size="3"
								multiple
								v-model="$store.state.setoresSelecionados"
							>
								<option v-for="s in $store.state.setores" :key="s.id" :value="s.id">{{s.setor}}</option>
							</select>
						</input-container-component>
					</div>
					<div class="col-md-4">
						<input-container-component
							v-if="$store.state.user.nivel_acesso == 1"
							id="atualizarFiliais"
							label="Filiais da Publicação"
							id-help="atualizarFiliaisHelp"
							texto-help="Informe os filiais da publicação."
						>
							<!-- Conteudo encaminhado para o slot do componente InputContainer -->
							<select
								class="form-control"
								id="atualizarFiliais"
								size="3"
								multiple
								v-model="$store.state.filiaisSelecionadas"
							>
								<option v-for="f in $store.state.filiais" :key="f.id" :value="f.id">{{f.filial}}</option>
							</select>
						</input-container-component>
					</div>
				</div>
			</template>
			<!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
			<template v-slot:alertas>
				<alert-component
					tipo="success"
					:detalhes="$store.state.transacao"
					titulo="Cadastro atualizado com sucesso"
					v-if="$store.state.transacao.status == 'sucesso'"
				></alert-component>
				<alert-component
					tipo="danger"
					:detalhes="$store.state.transacao"
					titulo="Erro ao atualizar cadastro da publicação"
					v-if="$store.state.transacao.status == 'erro'"
				></alert-component>
			</template>
			<!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
			<template v-slot:rodape>
				<button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
				<button type="button" class="btn btn-primary-generic" @click="atualizar()">Atualizar</button>
			</template>
		</modal-component>
		<!-- Fim Modal de atualização de publicações-->

		<!-- Inicio Modal de remoção de publicações-->
		<modal-component id="modalRemoverPublicacao" titulo="Remover Publicação" tamanho="md">
			<template v-slot:conteudo v-if="$store.state.transacao.status != 'sucesso'">
				<!-- Chama o component de loader no envio da requisição -->
				<loader
					v-if="$store.state.loader"
					:object="$store.state.colorObject"
					:size="$store.state.size"
					:speed="$store.state.speed"
					:bg="$store.state.colorBg"
					:opacity="$store.state.opacity"
					:disableScrolling="$store.state.disableScrolling"
					:name="$store.state.name"
				></loader>

				<!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
				<input-container-component label="ID">
					<input type="text" class="form-control" :value="$store.state.item.id" disabled />
				</input-container-component>
				<input-container-component label="Nome da Publicação">
					<input type="text" class="form-control" :value="$store.state.item.publicacao" disabled />
				</input-container-component>
			</template>
			<template v-slot:alertas>
				<alert-component
					tipo="success"
					titulo="Registro removido com sucesso"
					:detalhes="$store.state.transacao"
					v-if="$store.state.transacao.status == 'sucesso'"
				></alert-component>
				<alert-component
					tipo="danger"
					titulo="Falha ao remover registro"
					:detalhes="$store.state.transacao"
					v-if="$store.state.transacao.status == 'erro'"
				></alert-component>
			</template>
			<template v-slot:rodape>
				<button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
				<button
					type="button"
					class="btn btn-danger-generic"
					@click="excluir()"
					v-if="$store.state.transacao.status != 'sucesso'"
				>Excluir</button>
			</template>
		</modal-component>
		<!-- Fim Modal de remoção de publicações-->
	</div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
	// Define os atributos que poderão ser usados nesta instancia do Vue
	data() {
		return {
			editor: ClassicEditor,
			editorConfig: {
				toolbar: [
					"undo",
					"redo",
					"|",
					"bold",
					"italic",
					"|",
					"blockQuote",
					"heading",
					"numberedList",
					"bulletedList",
					"|",
					"insertTable",
					"|",
					"tableColumn",
					"tableRow",
					"mergeTableCells",
				],
				heading: {
					options: [
						{
							model: "paragraph",
							title: "Paragraph",
							class: "ck-heading_paragraph",
						},
						{
							model: "heading1",
							view: "h1",
							title: "Heading 1",
							class: "ck-heading_heading1",
						},
						{
							model: "heading2",
							view: "h2",
							title: "Heading 2",
							class: "ck-heading_heading2",
						},
						{
							model: "heading3",
							view: "h3",
							title: "Heading 3",
							class: "ck-heading_heading3",
						},
						{
							model: "heading4",
							view: "h4",
							title: "Heading 4",
							class: "ck-heading_heading4",
						},
						{
							model: "heading5",
							view: "h5",
							title: "Heading 5",
							class: "ck-heading_heading5",
						},
					],
				},
			},

			urlBase: "api/publicacao",
			urlPaginacao: "",
			urlFiltro: "",
			numPaginas: "&paginas=30",
			publicacao: "",
			descricao: "",
			texto: "",
			status: "",
			treinamento: "",
			importante: "",
			categoriaId: "",
			imagens: [],
			cargosSelecionados: [],
			setoresSelecionados: [],
			filiaisSelecionadas: [],
			agenda: "n",
			dataEvento: "",
			publicacoes: { data: [] },
			busca: { publicacao: "" },
		};
	},
	// Define os metodos usados nesta instancia do Vue
	methods: {
		// Metodo para buscar os registros da tabela publicações
		carregarLista() {
			// Montando url de requisição com possiveis parâmetros de filtro e paginação
			let url =
				this.$store.state.apiHost +
				this.urlBase +
				"?" +
				this.urlPaginacao +
				"&order=publicacao" +
				this.urlFiltro +
				this.numPaginas;
			// Requisição de consulta de registros na tabela publicações no banco
			return axios
				.get(url)
				.then((response) => {
					this.publicacoes = response.data;
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		// Metodo para buscar os registros da tabela cargos
		carregarCargos() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarCargos");
		},
		// Metodo para buscar os registros da tabela setores
		carregarSetores() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarSetores");
		},
		// Metodo para buscar os registros da tabela filiais
		carregarFiliais() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarFiliais");
		},
		// Metodo para buscar os registros da tabela categorias
		carregarCategorias() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarCategorias");
		},
		// Metodo para buscar o registro do usuário logado
		carregarUser() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarUser");
		},
		// Metodo para criar links de requisição de buscas paginadas
		paginacao(l) {
			if (l.url) {
				// Capturando apenas parâmetro de pagina da request de paginação
				this.urlPaginacao = l.url.split("?")[1];
				this.carregarLista();
			}
		},
		// Metodo de pesquisa de registros na tabela publicações por nome do registro
		pesquisar() {
			let filtro = "";
			// Percorre o objeto 'busca' concatenando seus atributos em uma string
			for (let chave in this.busca) {
				// Verifica se o campo foi preenchido para atribuir o valor
				if (this.busca[chave]) {
					// Separa cada atributo com ';'
					if (filtro != "") {
						filtro += ";";
					}
					// Separa valor e chave com ':like:'
					filtro +=
						chave + ":like:%" + this.busca[chave].substr(1) + "%";
				}
			}
			// Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
			if (filtro != "") {
				this.urlFiltro = "&filtro=" + filtro;
			} else {
				this.urlFiltro = "";
			}
			// Define a primeira pagina para o resultado da busca
			this.urlPaginacao = "page=1";
			this.carregarLista();
		},
		limparTransacao() {
			// Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
			this.$store.state.transacao.mensagem = "";
			this.$store.state.transacao.status = "";
			this.$store.state.transacao.dados = "";
			this.publicacao = "";
			this.descricao = "";
			this.texto = "";
			this.status = "";
			this.treinamento = "";
			this.importante = "";
			this.categoriaId = "";
			this.imagens = [];
			this.cargosSelecionados = [];
			this.setoresSelecionados = [];
			this.filiaisSelecionadas = [];
			this.carregarCargos();
			this.carregarSetores();
			this.carregarFiliais();
			this.carregarCategorias();
			this.carregarUser();
		},
		// Metodo para selecionar publicação e atribuir a variavel imagens
		carregarImagens(e) {
			this.imagens = e.target.files;
		},
		// Metodo para inserção de registros na tabela publicações no banco
		salvar() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;

			// Instanciando formulario e definindo seus atributos
			let formData = new FormData();
			formData.append("publicacao", this.publicacao);
			formData.append("descricao", this.descricao);
			// Substitui tags para entrada de codigos html
			this.texto = this.texto.replaceAll("&lt;", "<");
			this.texto = this.texto.replaceAll("&gt;", ">");
			formData.append("texto", this.texto);
			formData.append("treinamento", this.treinamento);
			if (this.treinamento == "s") {
				formData.append("data_evento", this.dataEvento);
			}
			formData.append("importante", this.importante);
			if (this.$store.state.user.nivel_acesso != 1) {
				this.status = "i";
			}
			formData.append("status", this.status);
			formData.append("categoria_id", this.categoriaId);
			formData.append("user_id", this.$store.state.user.id);

			if (
				this.cargosSelecionados.length > 0 ||
				this.$store.state.user.nivel_acesso == 1
			) {
				this.cargosSelecionados.forEach((cargo) => {
					formData.append("cargos[]", cargo);
				});
			} else {
				formData.append("cargos[]", this.$store.state.user.cargo_id);
			}

			if (
				this.setoresSelecionados.length > 0 ||
				this.$store.state.user.nivel_acesso == 1
			) {
				this.setoresSelecionados.forEach((setor) => {
					formData.append("setores[]", setor);
				});
			} else {
				formData.append("setores[]", this.$store.state.user.setor_id);
			}

			if (
				this.filiaisSelecionadas.length > 0 ||
				this.$store.state.user.nivel_acesso == 1
			) {
				this.filiaisSelecionadas.forEach((filial) => {
					formData.append("filiais[]", filial);
				});
			} else {
				formData.append("filiais[]", this.$store.state.user.filial_id);
			}

			for (let i = 0; i < this.imagens.length; i++) {
				formData.append("imagens[]", this.imagens[i]);
			}

			// Configurando headers da requisição
			let config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			// Requisição de inserção de registros atraves da biblioteca axios
			axios
				.post(
					this.$store.state.apiHost + this.urlBase,
					formData,
					config
				)
				.then((response) => {
					// Definindo status da requisição como sucesso
					this.$store.state.transacao.status = "sucesso";
					// Recuperando registro cadastrado no banco
					this.$store.state.transacao.mensagem =
						"Código do registro: " + response.data.id;
					this.$store.state.transacao.dados = "";
					this.carregarLista();

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Registro inserido com sucesso",
						"Sucesso",
						"success"
					);
				})
				.catch((errors) => {
					// Definindo status da requisição como erro
					this.$store.state.transacao.status = "erro";
					// Recuperando erros da requisição
					this.$store.state.transacao.mensagem =
						errors.response.data.message;
					this.$store.state.transacao.dados =
						errors.response.data.errors;

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Falha ao tentar inserir o registro",
						"Erro",
						"error"
					);
				});
		},
		atualizar() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;

			let textoFormatado = "";
			// Recupera a urlBase e concatena com o id do registro a se atualizado
			let url =
				this.$store.state.apiHost +
				this.urlBase +
				"/" +
				this.$store.state.item.id;
			// Instanciando formulario e definindo seus atributos
			let formData = new FormData();
			formData.append("_method", "patch");
			formData.append("publicacao", this.$store.state.item.publicacao);
			formData.append("descricao", this.$store.state.item.descricao);
			// Substitui tags para entrada de codigos html
			textoFormatado = this.$store.state.item.texto.replaceAll(
				"&lt;",
				"<"
			);
			textoFormatado = textoFormatado.replaceAll("&gt;", ">");
			formData.append("texto", textoFormatado);
			formData.append("treinamento", this.$store.state.item.treinamento);
			if (this.$store.state.item.treinamento == "s") {
				formData.append("data_evento", this.dataEvento);
			}
			formData.append("importante", this.$store.state.item.importante);
			formData.append("status", this.$store.state.item.status);
			formData.append(
				"categoria_id",
				this.$store.state.item.categoria.id
			);
			formData.append("user_id", this.$store.state.user.id);

			for (let i = 0; i < this.imagens.length; i++) {
				formData.append("imagens[]", this.imagens[i]);
			}

			this.$store.state.cargosSelecionados.forEach((cargo) => {
				formData.append("cargos[]", cargo);
			});
			this.$store.state.setoresSelecionados.forEach((setor) => {
				formData.append("setores[]", setor);
			});
			this.$store.state.filiaisSelecionadas.forEach((filial) => {
				formData.append("filiais[]", filial);
			});

			// Configurando headers da requisição
			let config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			// Requisição de atualização de registros atraves da biblioteca axios
			axios
				.post(url, formData, config)
				.then((response) => {
					// Definindo status da requisição como sucesso
					this.$store.state.transacao.status = "sucesso";
					// Recuperando a mensagem da requisição
					this.$store.state.transacao.mensagem =
						"Código do registro: " + response.data.id;
					this.$store.state.transacao.dados = "";
					atualizarImagens.value = "";
					this.carregarLista();

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Registro alterado com sucesso",
						"Sucesso",
						"success"
					);
				})
				.catch((errors) => {
					// Definindo status da requisição como erro
					this.$store.state.transacao.status = "erro";
					// Recuperando erros da requisição
					this.$store.state.transacao.mensagem =
						errors.response.data.message;
					this.$store.state.transacao.dados =
						errors.response.data.errors;

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Falha ao tentar alterar o registro",
						"Erro",
						"error"
					);
				});
		},
		excluir() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;

			// Abre uma caixa de dialogo para confirmar a exclusão do registro
			this.$confirm(
				"Tem certeza que deseja remover o registro?",
				"Exclusão de Registro",
				"question"
			)
				.then(() => {
					// Recupera a urlBase e concatena com o id do registro a se excluido
					let url =
						this.$store.state.apiHost +
						this.urlBase +
						"/" +
						this.$store.state.item.id;
					// Instanciando formulario e definindo o _method delete
					let formData = new FormData();
					formData.append("_method", "delete");
					// Requisição de remoção de registro por id
					axios
						.post(url, formData)
						.then((response) => {
							// Definindo status da requisição como sucesso
							this.$store.state.transacao.status = "sucesso";
							// Recuperando a mensagem da requisição
							this.$store.state.transacao.mensagem =
								response.data.msg;
							this.$store.state.transacao.dados = "";
							this.carregarLista();

							// Define a variaval global Spinner como false
							this.$store.state.loader = false;
						})
						.catch((errors) => {
							// Definindo status da requisição como erro
							this.$store.state.transacao.status = "erro";
							// Recuperando erros da requisição
							this.$store.state.transacao.mensagem =
								errors.response.data.erro;

							// Define a variaval global Spinner como false
							this.$store.state.loader = false;
						});
				})
				.catch(() => {
					// Define a variaval global Spinner como false
					this.$store.state.loader = false;
				});
		},
	},
	// Executa uma ação quando o componente estiver montado
	async mounted() {
		await this.carregarLista();
		await this.carregarUser();
	},
};
</script>