<template>
    <div class="card mt-5 border-0">
        <div class="card-header bg-third-color card-border-bottom border-radius-card-header">
            <div class="row">
                <div class="col-9">
                    <h2 class="title-card text-white font-weight-bold">{{titulo}}</h2>
                </div>
                <div class="col-3">
                    <img :src="icone" class="card-position-icon" :alt="'Icone '+titulo">
                </div>
            </div>
        </div>
        <div class="card-body bg-primary-color border-radius-card-body shadow-card">
            <div v-if="dadosFiltrados.length > 0">
                <div v-for="d in dadosFiltrados" :key="d.id">
                    <a style="cursor: pointer;" data-toggle="modal" data-target="#modalPost" @click="setStore(d)">
                        <div class="item-card card-border-bottom mb-3 text-white">
                            <h3 class="text-truncate text-card font-weight-bold">{{d.publicacao}}</h3>
                            <p class="text-card text-right" v-if="d.treinamento == 's'">{{d.data_evento | formataDH}}</p>
                            <p class="text-card text-right" v-else>{{d.created_at | formataDataSimples}}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div v-else>
                <h3 class="text-card text-white text-center">Nenhum registro encontrado</h3>
            </div>            
        </div>
    </div>
</template>

<script>
    export default {
        props: ['titulo', 'icone', 'dados', 'user', 'data'],
        computed: {
            dadosFiltrados () {
                // Recupera as ultimas 4 publicações que correspondem ao cargo, setor e filial do usuário logado
                let dadosFiltrados = []
                let cont = 0
                this.dados.forEach(dado => {
                    dado.cargos.forEach(cargo => {
                        if (cargo.id == this.user.cargo_id) {
                            dado.setores.forEach(setor => {
                                if (setor.id == this.user.setor_id) {
                                    dado.filiais.forEach(filial => {
                                        if (filial.id == this.user.filial_id) {
                                            cont++
                                            if (cont <= 4) {
                                                let itemFiltrado = {}
                                                itemFiltrado['publicacao'] = dado.publicacao
                                                itemFiltrado['descricao'] = dado.descricao
                                                itemFiltrado['texto'] = dado.texto
                                                itemFiltrado['treinamento'] = dado.treinamento
                                                if (dado.treinamento == 's') {
                                                    itemFiltrado['data_evento'] = dado.data_evento
                                                }
                                                itemFiltrado['importante'] = dado.importante
                                                if (dado.publicacoes_imagens.length != 0) {
                                                    itemFiltrado['publicacoes_imagens'] = dado.publicacoes_imagens
                                                }                                            
                                                itemFiltrado['user'] = dado.user
                                                itemFiltrado['categoria'] = dado.categoria
                                                itemFiltrado['created_at'] = dado.created_at
                                                dadosFiltrados.push(itemFiltrado) 
                                            }  
                                        }                                                             
                                    })
                                }                                
                            }) 
                        }                                               
                    })                    
                });
                return dadosFiltrados
            }
        },
        methods: {
            setStore(obj) {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.$store.state.item = {}
                // Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
                this.$store.state.item = obj
            }
        }
    }
</script>