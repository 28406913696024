<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 mb-5">
                <!-- Inicio do card de pesquisa -->
                <card-component titulo="Pesquisa de Usuários">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <input-container-component
                            id="inputNomeUser"
                            label="Usuário"
                            id-help="userNomeHelp"
                            texto-help="Informe o nome do usuário que deseja pesquisar!"
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="inputNomeUser"
                                aria-describedby="userNomeHelp"
                                placeholder="Digite aqui!"
                                autocomplete="off"
                                v-model="busca.name"
                                @keydown.enter="pesquisar()"
                            />
                        </input-container-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <button
                            type="submit"
                            class="btn btn-primary-generic btn-sm float-right"
                            @click="pesquisar()"
                        >Pesquisar</button>
                    </template>
                </card-component>
                <!-- Fim do card de pesquisa -->
            </div>

            <div class="col-md-12">
                <!-- Inicio do card de listagem de usuários -->
                <card-component titulo="Lista de Usuários">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <!-- Chama o component de loader no envio da requisição -->
                        <loader
                            v-if="$store.state.loader"
                            :object="$store.state.colorObject"
                            :size="$store.state.size"
                            :speed="$store.state.speed"
                            :bg="$store.state.colorBg"
                            :opacity="$store.state.opacity"
                            :disableScrolling="$store.state.disableScrolling"
                            :name="$store.state.name"
                        ></loader>

                        <!-- filiais: {campo: 'filial', titulo: 'Filiais', tipo: 'modal', dataTarget: '#modalFiliais', classe: 'secondary'}, -->
                        <table-component
                            :dados="users.data"
                            :titulos="{  // Relação de titulos a serem listados e seus respectivos titulos
                                id: {titulo: 'ID', tipo: 'inteiro'},
                                name: {titulo: 'Nome do Usuário', tipo: 'texto'},
                                imagem: {titulo: 'Imagem de Perfil', tipo: 'imagem'},
                                status: {titulo: 'Situação', tipo: 'status'},
                                created_at: {titulo: 'Data de criação', tipo: 'data_hora'},                                
                                email: {titulo: '', tipo: 'nao_exibir'},
                                cpf: {titulo: '', tipo: 'nao_exibir'},
                                sexo: {titulo: '', tipo: 'nao_exibir'},
                                data_nascimento: {titulo: '', tipo: 'nao_exibir'},
                                data_admissao: {titulo: '', tipo: 'nao_exibir'},
                                data_demissao: {titulo: '', tipo: 'nao_exibir'},
                                nivel_acesso: {titulo: '', tipo: 'nao_exibir'},
                                ramal: {titulo: '', tipo: 'nao_exibir'},
                                celular: {titulo: '', tipo: 'nao_exibir'},
                                endereco: {titulo: '', tipo: 'nao_exibir'},
                                cidade: {titulo: '', tipo: 'nao_exibir'},
                                uf: {titulo: '', tipo: 'nao_exibir'},
                                contato_emergencia: {titulo: '', tipo: 'nao_exibir'},
                                nome_emergencia: {titulo: '', tipo: 'nao_exibir'},
                                parentesco_emergencia: {titulo: '', tipo: 'nao_exibir'},
                                cargo: {titulo: '', tipo: 'nao_exibir'},
                                setor: {titulo: '', tipo: 'nao_exibir'},
                                filial: {titulo: '', tipo: 'nao_exibir'},
                            }"
                            :botoes="{   // Relação de botoes a serem listados e suas respectivas propriedades
                                visualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalVisualizarUser', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                atualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalAtualizarUser', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                excluir: {visivel: true, dataToggle: 'modal', dataTarget: '#modalRemoverUser', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso}
                            }"
                        ></table-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <div class="row align-items-center">
                            <div class="col">
                                <paginate-component :dados="users">
                                    <li @click="paginacao(users.links[0])" class="page-item">
                                        <a
                                            class="page-link"
                                            v-if="users.links"
                                            v-html="users.links[0].label"
                                        ></a>
                                    </li>
                                    <li
                                        v-if="users.current_page != 1"
                                        @click="paginacao(users.links[users.current_page-1])"
                                        class="page-item"
                                    >
                                        <a
                                            class="page-link"
                                            v-if="users.links"
                                            v-html="users.links[users.current_page-1].label"
                                        ></a>
                                    </li>
                                    <li
                                        @click="paginacao(users.links[users.current_page])"
                                        class="page-item active"
                                    >
                                        <a
                                            class="page-link"
                                            v-if="users.links"
                                            v-html="users.links[users.current_page].label"
                                        ></a>
                                    </li>
                                    <li
                                        v-if="users.last_page > 1 && users.last_page != users.current_page"
                                        @click="paginacao(users.links[users.current_page+1])"
                                        class="page-item"
                                    >
                                        <a
                                            class="page-link"
                                            v-if="users.links"
                                            v-html="users.links[users.current_page+1].label"
                                        ></a>
                                    </li>
                                    <li
                                        v-if="users.last_page > 1 && users.last_page != users.current_page"
                                        @click="paginacao(users.links[users.current_page+1])"
                                        class="page-item"
                                    >
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                    <li v-else class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                </paginate-component>
                            </div>
                            <div class="col mt-2 mt-md-0">
                                <button
                                    type="button"
                                    class="btn btn-primary-generic btn-sm float-right"
                                    data-toggle="modal"
                                    data-target="#modalUser"
                                    @click="limparTransacao()"
                                >Adicionar</button>
                                <!-- Liberar apenas para usuário master -->
                                <button
                                    v-if="$store.state.user.id == 1"
                                    type="button"
                                    class="btn btn-danger-generic btn-sm float-right mr-2"
                                    @click="sincronizarColaboradores()"
                                >Sincronizar com a Sênior</button>
                            </div>
                        </div>
                    </template>
                </card-component>
                <!-- Fim do card de listagem de usuários -->
            </div>
        </div>

        <!-- Inicio Modal de adição de novos usuários-->
        <modal-component id="modalUser" titulo="Adicionar Usuário" tamanho="lg">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo>
                <!-- Chama o component de loader no envio da requisição -->
                <loader
                    v-if="$store.state.loader"
                    :object="$store.state.colorObject"
                    :size="$store.state.size"
                    :speed="$store.state.speed"
                    :bg="$store.state.colorBg"
                    :opacity="$store.state.opacity"
                    :disableScrolling="$store.state.disableScrolling"
                    :name="$store.state.name"
                ></loader>
                <!-- Busca dados do colaborador no banco da Senior -->
                <div class="row justify-content-end align-items-center">
                    <div class="col-md-5 px-0">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Digite o CPF do Colaborador"
                            autocomplete="off"
                            oninput="mcpf(this)"
                            v-model="cpf"
                        />
                    </div>
                    <div class="col-md-3">
                        <button
                            type="submit"
                            class="btn btn-primary-generic btn-sm"
                            @click="carregarColaboradorSenior()"
                        >Buscar Sênior</button>
                    </div>
                </div>

                <input-container-component
                    id="novoNomeUser"
                    label="Nome do Usuário"
                    id-help="novoNomeUserHelp"
                    texto-help="Informe o nome do usuário."
                >
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        id="novoNomeUser"
                        aria-describedby="novoNomeUserHelp"
                        placeholder="Nome do Usuário"
                        autocomplete="off"
                        v-model="name"
                    />
                </input-container-component>
                <input-container-component
                    id="novoEmail"
                    label="E-mail"
                    id-help="novoEmailHelp"
                    texto-help="Informe o e-mail do usuário."
                >
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="email"
                        class="form-control"
                        id="novoEmail"
                        aria-describedby="novoEmailHelp"
                        placeholder="E-mail do Usuário"
                        autocomplete="off"
                        v-model="email"
                    />
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component
                            id="novoCPF"
                            label="CPF"
                            id-help="novoCPFHelp"
                            texto-help="Informe o CPF do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="novoCPF"
                                aria-describedby="novoCPFHelp"
                                placeholder="CPF do Usuário"
                                autocomplete="off"
                                oninput="mcpf(this)"
                                v-model="cpf"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="novoSexo"
                            label="Sexo"
                            id-help="novoSexoHelp"
                            texto-help="Informe o sexo do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoSexo" v-model="sexo">
                                <option value>-- Selecione --</option>
                                <option value="F">Feminino</option>
                                <option value="M">Masculino</option>
                            </select>
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="novoDataNasc"
                            label="Data de Nascimento"
                            id-help="novoDataNascHelp"
                            texto-help="Informe o nome do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="date"
                                class="form-control"
                                id="novoDataNasc"
                                aria-describedby="novoDataNascHelp"
                                placeholder="Data de Nascimento do Usuário"
                                autocomplete="off"
                                v-model="dataNascimento"
                            />
                        </input-container-component>
                    </div>
                </div>
                <input-container-component
                    id="novoEndereco"
                    label="Endereço"
                    id-help="novoEnderecoHelp"
                    texto-help="Informe o endereço do usuário."
                >
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        id="novoEndereco"
                        aria-describedby="novoEnderecoHelp"
                        placeholder="Endereço do Usuário"
                        autocomplete="off"
                        v-model="endereco"
                    />
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-8">
                        <input-container-component
                            id="novoCidade"
                            label="Cidade"
                            id-help="novoCidadeHelp"
                            texto-help="Informe a cidade do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="novoCidade"
                                aria-describedby="novoCidadeHelp"
                                placeholder="Cidade do Usuário"
                                autocomplete="off"
                                v-model="cidade"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="novoUF"
                            label="UF"
                            id-help="novoUFHelp"
                            texto-help="Informe o UF do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="novoUF"
                                aria-describedby="novoUFHelp"
                                placeholder="UF do Usuário"
                                autocomplete="off"
                                v-model="uf"
                            />
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component
                            id="novoDataAdm"
                            label="Data de Admissão"
                            id-help="novoDataAdmHelp"
                            texto-help="Informe a data de admissão do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="date"
                                class="form-control"
                                id="novoDataAdm"
                                aria-describedby="novoDataAdmHelp"
                                placeholder="Data de Admissão do Usuário"
                                autocomplete="off"
                                v-model="dataAdmissao"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-6">
                        <input-container-component
                            id="novoNivelAcesso"
                            label="Nível de Acesso"
                            id-help="novoNivelAcessoHelp"
                            texto-help="Informe o nível de acesso do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoNivelAcesso" v-model="nivelAcesso">
                                <option value>-- Selecione --</option>
                                <option value="1">Administrador</option>
                                <option value="2">Gerente de Setor</option>
                                <option value="3">Colaborador</option>
                            </select>
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <input-container-component
                            id="novoRamal"
                            label="Ramal"
                            id-help="novoRamalHelp"
                            texto-help="Informe o ramal do usuário. (4 dígitos)"
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="novoRamal"
                                aria-describedby="novoRamalHelp"
                                placeholder="Ramal do Usuário"
                                autocomplete="off"
                                v-model="ramal"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-6">
                        <input-container-component
                            id="novoCelular"
                            label="Celular"
                            id-help="novoCelularHelp"
                            texto-help="Informe o celular do usuário. (somente dígitos)"
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="novoCelular"
                                aria-describedby="novoCelularHelp"
                                placeholder="Celular do Usuário"
                                autocomplete="off"
                                onkeypress="mask(this, mphone);"
                                onblur="mask(this, mphone);"
                                v-model="celular"
                            />
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component
                            id="novoCargo"
                            label="Cargo do Usuário"
                            id-help="novoCargoHelp"
                            texto-help="Informe o cargo do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoCargo" v-model="cargoId">
                                <option value>-- Selecione --</option>
                                <option
                                    v-for="c in $store.state.cargos"
                                    :key="c.id"
                                    :value="c.id"
                                >{{c.cargo}}</option>
                            </select>
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="novoSetor"
                            label="Setor do Usuário"
                            id-help="novoSetorHelp"
                            texto-help="Informe o setor do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoSetor" v-model="setorId">
                                <option value>-- Selecione --</option>
                                <option
                                    v-for="s in $store.state.setores"
                                    :key="s.id"
                                    :value="s.id"
                                >{{s.setor}}</option>
                            </select>
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="novoFilial"
                            label="Filial do Usuário"
                            id-help="novoFilialHelp"
                            texto-help="Informe a filial do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoFilial" v-model="filialId">
                                <option value>-- Selecione --</option>
                                <option
                                    v-for="f in $store.state.filiais"
                                    :key="f.id"
                                    :value="f.id"
                                >{{f.filial}}</option>
                            </select>
                        </input-container-component>
                    </div>
                </div>
                <input-container-component
                    id="novoNomeEmergencia"
                    label="Nome Contato de Emergência"
                    id-help="novoNomeEmergenciaHelp"
                    texto-help="Informe o nome de um contato de emergência do usuário."
                >
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        id="novoNomeEmergencia"
                        aria-describedby="novoNomeEmergenciaHelp"
                        placeholder="Nome Contato de Emergência do Usuário"
                        autocomplete="off"
                        v-model="nomeEmergencia"
                    />
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-6">
                        <input-container-component
                            id="novoContatoEmergencia"
                            label="Contato de Emergência"
                            id-help="novoContatoEmergenciaHelp"
                            texto-help="Informe o telefone de contato de emergência do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="novoContatoEmergencia"
                                aria-describedby="novoContatoEmergenciaHelp"
                                placeholder="Contato de Emergência do Usuário"
                                autocomplete="off"
                                v-model="contatoEmergencia"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-6">
                        <input-container-component
                            id="novoParentescoEmergencia"
                            label="Grau de Parentesco"
                            id-help="novoParentescoEmergenciaHelp"
                            texto-help="Informe o vínculo de parentesco com o usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="novoParentescoEmergencia"
                                aria-describedby="novoParentescoEmergenciaHelp"
                                placeholder="Vínculo de Parentesco com o Usuário"
                                autocomplete="off"
                                v-model="parentescoEmergencia"
                            />
                        </input-container-component>
                    </div>
                </div>
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component
                    tipo="success"
                    :detalhes="$store.state.transacao"
                    titulo="Cadastro realizado com sucesso"
                    v-if="$store.state.transacao.status == 'sucesso'"
                ></alert-component>
                <alert-component
                    tipo="danger"
                    :detalhes="$store.state.transacao"
                    titulo="Erro ao realizar cadastro do usuário"
                    v-if="$store.state.transacao.status == 'erro'"
                ></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="salvar()">Salvar</button>
            </template>
        </modal-component>
        <!-- Fim Modal de adição de novos usuários -->

        <!-- Inicio Modal de visualização de usuários -->
        <modal-component id="modalVisualizarUser" titulo="Visualizar Usuário" tamanho="lg">
            <template v-slot:conteudo v-if="$store.state.modal == '#modalVisualizarUser'">
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled />
                </input-container-component>
                <input-container-component label="Nome do Usuário">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        :value="$store.state.item.name"
                        disabled
                    />
                </input-container-component>
                <input-container-component label="E-mail">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        :value="$store.state.item.email | valorNull"
                        disabled
                    />
                </input-container-component>
                <input-container-component label="Imagem de Perfil">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <img
                        :src="'/storage/'+$store.state.item.imagem"
                        v-if="$store.state.item.imagem != 'sem-imagem'"
                        class="img-fluid ml-3 py-3"
                        width="50px"
                    />
                    <img
                        :src="'/storage/icons/func_m.png'"
                        v-if="$store.state.item.imagem == 'sem-imagem'"
                        class="img-fluid ml-3 py-3"
                        width="50px"
                    />
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component label="CPF">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.cpf"
                                disabled
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component label="Sexo">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                v-if="$store.state.item.sexo == 'M'"
                                value="Masculino"
                                disabled
                            />
                            <input
                                type="text"
                                class="form-control"
                                v-if="$store.state.item.sexo == 'F'"
                                value="Feminino"
                                disabled
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component label="Data de Nascimento">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.data_nascimento | formataData"
                                disabled
                            />
                        </input-container-component>
                    </div>
                </div>
                <input-container-component label="Endereço">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        :value="$store.state.item.endereco"
                        disabled
                    />
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-8">
                        <input-container-component label="Cidade">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.cidade"
                                disabled
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component label="UF">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.uf"
                                disabled
                            />
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component label="Data de Admissão">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.data_admissao | formataData"
                                disabled
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component label="Nível de Acesso">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                v-if="$store.state.item.nivel_acesso == 1"
                                value="Administrador"
                                disabled
                            />
                            <input
                                type="text"
                                class="form-control"
                                v-if="$store.state.item.nivel_acesso == 2"
                                value="Gerente de Setor"
                                disabled
                            />
                            <input
                                type="text"
                                class="form-control"
                                v-if="$store.state.item.nivel_acesso == 3"
                                value="Colaborador"
                                disabled
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component label="Status">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                v-if="$store.state.item.status == 'a'"
                                value="Ativo"
                                disabled
                            />
                            <input
                                type="text"
                                class="form-control"
                                v-if="$store.state.item.status == 'i'"
                                value="Inativo"
                                disabled
                            />
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <input-container-component label="Ramal">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.ramal | valorNull"
                                disabled
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-6">
                        <input-container-component label="Celular">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.celular"
                                disabled
                            />
                        </input-container-component>
                    </div>
                </div>

                <input-container-component label="Nome Contato de Emergência">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        :value="$store.state.item.nome_emergencia | valorNull"
                        disabled
                    />
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-6">
                        <input-container-component label="Contato de Emergência">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.contato_emergencia | valorNull"
                                disabled
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-6">
                        <input-container-component label="Grau de Parentesco">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.parentesco_emergencia | valorNull"
                                disabled
                            />
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component label="Cargo">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.cargo.cargo"
                                disabled
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component label="Setor">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.setor.setor"
                                disabled
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component label="Filial">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                :value="$store.state.item.filial.filial"
                                disabled
                            />
                        </input-container-component>
                    </div>
                </div>
                <input-container-component label="Data de criação">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        :value="$store.state.item.created_at | formataDataHora"
                        disabled
                    />
                </input-container-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
            </template>
        </modal-component>
        <!-- Fim Modal de visualização de usuários-->

        <!-- Inicio Modal de atualização de usuários-->
        <modal-component id="modalAtualizarUser" titulo="Atualizar Usuário" tamanho="lg">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo v-if="$store.state.modal == '#modalAtualizarUser'">
                <!-- Chama o component de loader no envio da requisição -->
                <loader
                    v-if="$store.state.loader"
                    :object="$store.state.colorObject"
                    :size="$store.state.size"
                    :speed="$store.state.speed"
                    :bg="$store.state.colorBg"
                    :opacity="$store.state.opacity"
                    :disableScrolling="$store.state.disableScrolling"
                    :name="$store.state.name"
                ></loader>

                <input-container-component
                    id="atualizarNomeUser"
                    label="Nome do Usuário"
                    id-help="atualizarNomeUserHelp"
                    texto-help="Informe o nome do usuário."
                >
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        id="atualizarNomeUser"
                        aria-describedby="atualizarNomeUserHelp"
                        autocomplete="off"
                        v-model="$store.state.item.name"
                    />
                </input-container-component>

                <input-container-component
                    id="atualizarEmail"
                    label="E-mail"
                    id-help="atualizarEmailHelp"
                    texto-help="Informe o e-mail do usuário."
                >
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="email"
                        class="form-control"
                        id="atualizarEmail"
                        aria-describedby="atualizarEmailHelp"
                        autocomplete="off"
                        v-model="$store.state.item.email"
                    />
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarCPF"
                            label="CPF"
                            id-help="atualizarCPFHelp"
                            texto-help="Informe o CPF do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="atualizarCPF"
                                aria-describedby="atualizarCPFHelp"
                                oninput="mcpf(this)"
                                autocomplete="off"
                                v-model="$store.state.item.cpf"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarSexo"
                            label="Sexo"
                            id-help="atualizarSexoHelp"
                            texto-help="Informe o sexo do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select
                                class="form-control"
                                id="atualizarSexo"
                                v-model="$store.state.item.sexo"
                            >
                                <option value="M">Masculino</option>
                                <option value="F">Feminino</option>
                            </select>
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarDataNasc"
                            label="Data de Nascimento"
                            id-help="atualizarDataNascHelp"
                            texto-help="Informe a data de nascimento do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="date"
                                class="form-control"
                                id="atualizarDataNasc"
                                aria-describedby="atualizarDataNascHelp"
                                v-model="$store.state.item.data_nascimento"
                            />
                        </input-container-component>
                    </div>
                </div>
                <input-container-component
                    id="atualizarEndereco"
                    label="Endereço"
                    id-help="atualizarEnderecoHelp"
                    texto-help="Informe o endereço do usuário."
                >
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        id="atualizarEndereco"
                        aria-describedby="atualizarEnderecoHelp"
                        autocomplete="off"
                        v-model="$store.state.item.endereco"
                    />
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-6">
                        <input-container-component
                            id="atualizarCidade"
                            label="Cidade"
                            id-help="atualizarCidadeHelp"
                            texto-help="Informe a cidade do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="atualizarCidade"
                                aria-describedby="atualizarCidadeHelp"
                                autocomplete="off"
                                v-model="$store.state.item.cidade"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarUF"
                            label="UF"
                            id-help="atualizarUFHelp"
                            texto-help="Informe o UF do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="atualizarUF"
                                aria-describedby="atualizarUFHelp"
                                autocomplete="off"
                                v-model="$store.state.item.uf"
                            />
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarDataAdm"
                            label="Data de Admissão"
                            id-help="atualizarDataAdmHelp"
                            texto-help="Informe a data de admissão do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="date"
                                class="form-control"
                                id="atualizarDataAdm"
                                aria-describedby="atualizarDataAdmHelp"
                                v-model="$store.state.item.data_admissao"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarNivelAcesso"
                            label="Nível de Acesso"
                            id-help="atualizarNivelAcessoHelp"
                            texto-help="Informe o nível de acesso do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select
                                class="form-control"
                                id="atualizarNivelAcesso"
                                v-model="$store.state.item.nivel_acesso"
                            >
                                <option value="1">Administrador</option>
                                <option value="2">Gerente de Setor</option>
                                <option value="3">Colaborador</option>
                            </select>
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarStatus"
                            label="Status"
                            id-help="atualizarStatusHelp"
                            texto-help="Informe a situação do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select
                                class="form-control"
                                id="atualizarStatus"
                                v-model="$store.state.item.status"
                            >
                                <option value="a">Ativo</option>
                                <option value="i">Inativo</option>
                            </select>
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <input-container-component
                            id="atualizarRamal"
                            label="Ramal"
                            id-help="atualizarRamalHelp"
                            texto-help="Informe o ramal do usuário. (4 dígitos)"
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="atualizarRamal"
                                aria-describedby="atualizarRamalHelp"
                                autocomplete="off"
                                v-model="$store.state.item.ramal"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-6">
                        <input-container-component
                            id="atualizarCelular"
                            label="Celular"
                            id-help="atualizarCelularHelp"
                            texto-help="Informe o celular do usuário. (somente dígitos)"
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="atualizarCelular"
                                aria-describedby="atualizarCelularHelp"
                                autocomplete="off"
                                onkeypress="mask(this, mphone);"
                                onblur="mask(this, mphone);"
                                v-model="$store.state.item.celular"
                            />
                        </input-container-component>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarCargo"
                            label="Cargo do Usuário"
                            id-help="atualizarCargoHelp"
                            texto-help="Informe o cargo do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select
                                class="form-control"
                                id="atualizarCargo"
                                v-model="$store.state.item.cargo.id"
                            >
                                <option
                                    v-for="c in $store.state.cargos"
                                    :key="c.id"
                                    :value="c.id"
                                >{{c.cargo}}</option>
                            </select>
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarSetor"
                            label="Setor do Usuário"
                            id-help="atualizarSetorHelp"
                            texto-help="Informe o setor do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select
                                class="form-control"
                                id="atualizarSetor"
                                v-model="$store.state.item.setor.id"
                            >
                                <option
                                    v-for="s in $store.state.setores"
                                    :key="s.id"
                                    :value="s.id"
                                >{{s.setor}}</option>
                            </select>
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component
                            id="atualizarFilial"
                            label="Filial do Usuário"
                            id-help="atualizarFilialHelp"
                            texto-help="Informe a filial do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select
                                class="form-control"
                                id="atualizarFilial"
                                v-model="$store.state.item.filial.id"
                            >
                                <option
                                    v-for="f in $store.state.filiais"
                                    :key="f.id"
                                    :value="f.id"
                                >{{f.filial}}</option>
                            </select>
                        </input-container-component>
                    </div>
                </div>
                <input-container-component
                    id="atualizarNomeEmergencia"
                    label="Nome Contato de Emergência"
                    id-help="atualizarNomeEmergenciaHelp"
                    texto-help="Informe o nome de um contato de emergência do usuário."
                >
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input
                        type="text"
                        class="form-control"
                        id="atualizarNomeEmergencia"
                        aria-describedby="atualizarNomeEmergenciaHelp"
                        autocomplete="off"
                        v-model="$store.state.item.nome_emergencia"
                    />
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-6">
                        <input-container-component
                            id="atualizarContatoEmergencia"
                            label="Contato de Emergência"
                            id-help="atualizarContatoEmergenciaHelp"
                            texto-help="Informe o telefone de contato de emergência do usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="atualizarContatoEmergencia"
                                aria-describedby="atualizarContatoEmergenciaHelp"
                                autocomplete="off"
                                v-model="$store.state.item.contato_emergencia"
                            />
                        </input-container-component>
                    </div>
                    <div class="col-md-6">
                        <input-container-component
                            id="atualizarParentescoEmergencia"
                            label="Grau de Parentesco"
                            id-help="atualizarParentescoEmergenciaHelp"
                            texto-help="Informe o vínculo de parentesco com o usuário."
                        >
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input
                                type="text"
                                class="form-control"
                                id="atualizarParentescoEmergencia"
                                aria-describedby="atualizarParentescoEmergenciaHelp"
                                autocomplete="off"
                                v-model="$store.state.item.parentesco_emergencia"
                            />
                        </input-container-component>
                    </div>
                </div>
                <input-container-component
                    id="atualizarImagem"
                    label="Imagem de Perfil"
                    id-help="atualizarImagemHelp"
                >
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <cropper-component></cropper-component>
                </input-container-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component
                    tipo="success"
                    :detalhes="$store.state.transacao"
                    titulo="Cadastro atualizado com sucesso"
                    v-if="$store.state.transacao.status == 'sucesso'"
                ></alert-component>
                <alert-component
                    tipo="danger"
                    :detalhes="$store.state.transacao"
                    titulo="Erro ao atualizar cadastro do usuário"
                    v-if="$store.state.transacao.status == 'erro'"
                ></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="atualizar()">Atualizar</button>
            </template>
        </modal-component>
        <!-- Fim Modal de atualização de usuários-->

        <!-- Inicio Modal de remoção de usuários-->
        <modal-component id="modalRemoverUser" titulo="Remover Usuário" tamanho="md">
            <template v-slot:conteudo v-if="$store.state.transacao.status != 'sucesso'">
                <!-- Chama o component de loader no envio da requisição -->
                <loader
                    v-if="$store.state.loader"
                    :object="$store.state.colorObject"
                    :size="$store.state.size"
                    :speed="$store.state.speed"
                    :bg="$store.state.colorBg"
                    :opacity="$store.state.opacity"
                    :disableScrolling="$store.state.disableScrolling"
                    :name="$store.state.name"
                ></loader>

                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled />
                </input-container-component>
                <input-container-component label="Nome do Usuário">
                    <input
                        type="text"
                        class="form-control"
                        :value="$store.state.item.name"
                        disabled
                    />
                </input-container-component>
            </template>
            <template v-slot:alertas>
                <alert-component
                    tipo="success"
                    titulo="Registro removido com sucesso"
                    :detalhes="$store.state.transacao"
                    v-if="$store.state.transacao.status == 'sucesso'"
                ></alert-component>
                <alert-component
                    tipo="danger"
                    titulo="Falha ao remover registro"
                    :detalhes="$store.state.transacao"
                    v-if="$store.state.transacao.status == 'erro'"
                ></alert-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button
                    type="button"
                    class="btn btn-danger-generic"
                    @click="excluir()"
                    v-if="$store.state.transacao.status != 'sucesso'"
                >Excluir</button>
            </template>
        </modal-component>
        <!-- Fim Modal de remoção de usuários-->
    </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
    components: {
        VueCropper,
    },
    // Define os atributos que poderão ser usados nesta instancia do Vue
    data() {
        return {
            urlBase: "api/user",
            urlPaginacao: "",
            urlFiltro: "",
            numPaginas: "&paginas=30",
            name: "",
            email: "",
            status: "",
            password: "",
            cpf: "",
            sexo: "",
            dataNascimento: "",
            dataAdmissao: "",
            dataDemissao: "",
            nivelAcesso: "",
            ramal: "",
            celular: "",
            endereco: "",
            cidade: "",
            uf: "",
            contatoEmergencia: "",
            nomeEmergencia: "",
            parentescoEmergencia: "",
            cargoId: "",
            setorId: "",
            filialId: "",
            users: { data: [] },
            colaboradorSenior: [],
            busca: { name: "" },
        };
    },
    // Define os metodos usados nesta instancia do Vue
    methods: {
        // Metodo para buscar os registros da tabela usuários
        carregarColaboradorSenior() {
            // Montando url de requisição com possiveis parâmetros de filtro e paginação
            let url = this.$store.state.apiHost + "api/colaborador/" + this.cpf;
            // Requisição de consulta de registros na tabela usuários no banco
            axios
                .get(url)
                .then((response) => {
                    this.colaboradorSenior = response.data;
                    this.name = this.colaboradorSenior[0].nome;
                    this.email = this.colaboradorSenior[0].email;
                    this.sexo = this.colaboradorSenior[0].sexo;
                    this.dataNascimento =
                        this.colaboradorSenior[0].data_nascimento.split(" ")[0];
                    this.dataAdmissao =
                        this.colaboradorSenior[0].data_admissao.split(" ")[0];
                    this.ramal = this.colaboradorSenior[0].ramal;
                    this.celular = this.colaboradorSenior[0].celular;
                    this.endereco =
                        this.colaboradorSenior[0].endereco +
                        ", " +
                        this.colaboradorSenior[0].numero;
                    this.cidade = this.colaboradorSenior[0].cidade;
                    this.uf = this.colaboradorSenior[0].uf;
                    this.cargoId = this.colaboradorSenior[0].cargo.replace(
                        /^0+/,
                        ""
                    );
                    this.filialId = this.colaboradorSenior[0].filial;
                })
                .catch((errors) => {
                    console.log(errors.response);
                });
        },
        // Metodo para buscar os registros da tabela usuários
        carregarLista() {
            // Montando url de requisição com possiveis parâmetros de filtro e paginação
            let url =
                this.$store.state.apiHost +
                this.urlBase +
                "?" +
                this.urlPaginacao +
                "&order=name" +
                this.urlFiltro +
                this.numPaginas;
            // Requisição de consulta de registros na tabela usuários no banco
            return axios
                .get(url)
                .then((response) => {
                    this.users = response.data;
                })
                .catch((errors) => {
                    console.log(errors.response);
                });
        },
        // Metodo para buscar os registros da tabela cargos
        carregarCargos() {
            // Executa o metodo da store do vuex (app.js)
            this.$store.commit("carregarCargos");
        },
        // Metodo para buscar os registros da tabela setores
        carregarSetores() {
            // Executa o metodo da store do vuex (app.js)
            this.$store.commit("carregarSetores");
        },
        // Metodo para buscar os registros da tabela filiais
        carregarFiliais() {
            // Executa o metodo da store do vuex (app.js)
            this.$store.commit("carregarFiliais");
        },
        // Metodo para buscar o registro do usuário logado
        carregarUser() {
            // Executa o metodo da store do vuex (app.js)
            this.$store.commit("carregarUser");
        },
        // Metodo para criar links de requisição de buscas paginadas
        paginacao(l) {
            if (l.url) {
                // Capturando apenas parâmetro de pagina da request de paginação
                this.urlPaginacao = l.url.split("?")[1];
                this.carregarLista();
            }
        },
        // Metodo de pesquisa de registros na tabela usuários por nome do registro
        pesquisar() {
            let filtro = "id:<>:1";
            // Percorre o objeto 'busca' concatenando seus atributos em uma string
            for (let chave in this.busca) {
                // Verifica se o campo foi preenchido para atribuir o valor
                if (this.busca[chave]) {
                    // Separa cada atributo com ';'
                    if (filtro != "") {
                        filtro += ";";
                    }
                    // Separa valor e chave com ':like:'
                    filtro +=
                        chave + ":like:%" + this.busca[chave].substr(1) + "%";
                }
            }
            // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
            if (filtro != "") {
                this.urlFiltro = "&filtro=" + filtro;
            } else {
                this.urlFiltro = "";
            }
            // Define a primeira pagina para o resultado da busca
            this.urlPaginacao = "page=1";
            this.carregarLista();
        },
        limparTransacao() {
            // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
            this.$store.state.transacao.mensagem = "";
            this.$store.state.transacao.status = "";
            this.$store.state.transacao.dados = "";
            (this.name = ""),
                (this.email = ""),
                (this.cpf = ""),
                (this.sexo = ""),
                (this.dataNascimento = ""),
                (this.dataAdmissao = ""),
                (this.dataDemissao = ""),
                (this.nivelAcesso = ""),
                (this.ramal = ""),
                (this.celular = ""),
                (this.endereco = ""),
                (this.cidade = ""),
                (this.uf = ""),
                (this.contatoEmergencia = ""),
                (this.nomeEmergencia = ""),
                (this.parentescoEmergencia = ""),
                (this.cargoId = ""),
                (this.setorId = ""),
                (this.filialId = ""),
                this.carregarCargos();
            this.carregarSetores();
            this.carregarFiliais();
        },
        // Metodo para inserção de registros na tabela usuários no banco
        salvar() {
            // Define a variaval global Spinner como true
            this.$store.state.loader = true;

            // Instanciando formulario e definindo seus atributos
            let formData = new FormData();
            formData.append("name", this.name);
            formData.append("email", this.email);
            formData.append("status", "a");
            formData.append("password", "1234");
            formData.append("cpf", this.cpf);
            formData.append("sexo", this.sexo);
            formData.append("data_nascimento", this.dataNascimento);
            formData.append("data_admissao", this.dataAdmissao);
            formData.append("nivel_acesso", this.nivelAcesso);
            formData.append("ramal", this.ramal);
            formData.append("celular", this.celular);
            formData.append("endereco", this.endereco);
            formData.append("cidade", this.cidade);
            formData.append("uf", this.uf);
            formData.append("contato_emergencia", this.contatoEmergencia);
            formData.append("nome_emergencia", this.nomeEmergencia);
            formData.append("parentesco_emergencia", this.parentescoEmergencia);
            formData.append("cargo_id", this.cargoId);
            formData.append("setor_id", this.setorId);
            formData.append("filial_id", this.filialId);

            if (this.$store.state.cropImgUser != "") {
                formData.append("imagem", this.$store.state.cropImgUser);
            }

            // Configurando headers da requisição
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            // Requisição de inserção de registros atraves da biblioteca axios
            axios
                .post(
                    this.$store.state.apiHost + this.urlBase,
                    formData,
                    config
                )
                .then((response) => {
                    // Definindo status da requisição como sucesso
                    this.$store.state.transacao.status = "sucesso";
                    // Recuperando registro cadastrado no banco
                    this.$store.state.transacao.mensagem =
                        "Código do registro: " + response.data.id;
                    this.$store.state.transacao.dados = "";
                    this.carregarLista();

                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false;

                    this.$alert(
                        "Registro inserido com sucesso",
                        "Sucesso",
                        "success"
                    );
                })
                .catch((errors) => {
                    // Definindo status da requisição como erro
                    this.$store.state.transacao.status = "erro";
                    // Recuperando erros da requisição
                    this.$store.state.transacao.mensagem =
                        errors.response.data.message;
                    this.$store.state.transacao.dados =
                        errors.response.data.errors;

                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false;

                    this.$alert(
                        "Falha ao tentar inserir o registro",
                        "Erro",
                        "error"
                    );
                });
        },
        atualizar() {
            // Define a variaval global Spinner como true
            this.$store.state.loader = true;

            // Recupera a urlBase e concatena com o id do registro a se atualizado
            let url =
                this.$store.state.apiHost +
                this.urlBase +
                "/" +
                this.$store.state.item.id;
            // Instanciando formulario e definindo seus atributos
            let formData = new FormData();
            formData.append("_method", "patch");
            formData.append("name", this.$store.state.item.name);
            formData.append("email", this.$store.state.item.email);
            formData.append("status", this.$store.state.item.status);
            formData.append("cpf", this.$store.state.item.cpf);
            formData.append("sexo", this.$store.state.item.sexo);
            formData.append(
                "data_nascimento",
                this.$store.state.item.data_nascimento
            );
            formData.append(
                "data_admissao",
                this.$store.state.item.data_admissao
            );
            formData.append(
                "nivel_acesso",
                this.$store.state.item.nivel_acesso
            );
            formData.append("ramal", this.$store.state.item.ramal);
            formData.append("celular", this.$store.state.item.celular);
            formData.append("endereco", this.$store.state.item.endereco);
            formData.append("cidade", this.$store.state.item.cidade);
            formData.append("uf", this.$store.state.item.uf);
            formData.append(
                "contato_emergencia",
                this.$store.state.item.contato_emergencia
            );
            formData.append(
                "nome_emergencia",
                this.$store.state.item.nome_emergencia
            );
            formData.append(
                "parentesco_emergencia",
                this.$store.state.item.parentesco_emergencia
            );
            formData.append("cargo_id", this.$store.state.item.cargo.id);
            formData.append("setor_id", this.$store.state.item.setor.id);
            formData.append("filial_id", this.$store.state.item.filial.id);

            if (this.$store.state.cropImgUser != "") {
                formData.append("imagem", this.$store.state.cropImgUser);
            }

            // Configurando headers da requisição
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            // Requisição de atualização de registros atraves da biblioteca axios
            axios
                .post(url, formData, config)
                .then((response) => {
                    // Definindo status da requisição como sucesso
                    this.$store.state.transacao.status = "sucesso";
                    // Recuperando a mensagem da requisição
                    this.$store.state.transacao.mensagem =
                        "Código do registro: " + response.data.id;
                    this.$store.state.transacao.dados = "";
                    this.carregarLista();

                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false;

                    this.$alert(
                        "Registro alterado com sucesso",
                        "Sucesso",
                        "success"
                    );
                })
                .catch((errors) => {
                    // Definindo status da requisição como erro
                    this.$store.state.transacao.status = "erro";
                    // Recuperando erros da requisição
                    this.$store.state.transacao.mensagem =
                        errors.response.data.message;
                    this.$store.state.transacao.dados =
                        errors.response.data.errors;

                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false;

                    this.$alert(
                        "Falha ao tentar alterar o registro",
                        "Erro",
                        "error"
                    );
                });
        },
        excluir() {
            // Define a variaval global Spinner como true
            this.$store.state.loader = true;

            // Abre uma caixa de dialogo para confirmar a exclusão do registro
            this.$confirm(
                "Tem certeza que deseja remover o registro?",
                "Exclusão de Registro",
                "question"
            )
                .then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url =
                        this.$store.state.apiHost +
                        this.urlBase +
                        "/" +
                        this.$store.state.item.id;
                    // Instanciando formulario e definindo o _method delete
                    let formData = new FormData();
                    formData.append("_method", "delete");
                    // Requisição de remoção de registro por id
                    axios
                        .post(url, formData)
                        .then((response) => {
                            // Definindo status da requisição como sucesso
                            this.$store.state.transacao.status = "sucesso";
                            // Recuperando a mensagem da requisição
                            this.$store.state.transacao.mensagem =
                                response.data.msg;
                            this.$store.state.transacao.dados = "";
                            this.carregarLista();

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false;
                        })
                        .catch((errors) => {
                            // Definindo status da requisição como erro
                            this.$store.state.transacao.status = "erro";
                            // Recuperando erros da requisição
                            this.$store.state.transacao.mensagem =
                                errors.response.data.erro;

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false;
                        });
                })
                .catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false;
                });
        },
        sincronizarColaboradores() {
            // Define a variaval global Spinner como true
            this.$store.state.loader = true;

            // Abre uma caixa de dialogo para confirmar a exclusão do registro
            this.$confirm(
                "Tem certeza que deseja sincronizar a tabela de colaboradores com a Sênior?",
                "Sincronização de Registros",
                "question"
            )
                .then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url =
                        this.$store.state.apiHost +
                        "api/sincronizar-colaboradores";
                    // Requisição de remoção de registro por id
                    axios
                        .get(url)
                        .then((response) => {
                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false;
                            this.carregarLista();

                            this.$alert(
                                response.data.msg,
                                "Sucesso",
                                "success"
                            );
                        })
                        .catch((errors) => {
                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false;

                            this.$alert(
                                errors.response.data.erro,
                                "Erro",
                                "error"
                            );
                        });
                })
                .catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false;
                });
        },
    },
    // Executa uma ação quando o componente estiver montado
    async mounted() {
        await this.carregarLista();
        await this.carregarUser();
    },
};
</script>