<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 mb-5">
                <!-- Inicio do card de pesquisa -->
                <card-component titulo="Pesquisa de Feedbacks">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <div class="row">
                            <div class="col-md-6">
                                <input-container-component id="inputColaborador" label="ID Colaborador" id-help="colaboradorHelp" texto-help="Informe o id do colaborador que deseja pesquisar!">
                                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                                    <input type="text" class="form-control" id="inputcolaborador" aria-describedby="colaboradorHelp" placeholder="Digite aqui!" autocomplete="off" v-model="busca.user_id" @keydown.enter="pesquisar()">
                                </input-container-component>
                            </div>   
                            <div class="col-md-6">
                                <input-container-component id="inputFeedback" label="Feedback" id-help="feedbackHelp" texto-help="Informe o feedback que deseja pesquisar!">
                                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                                    <input type="text" class="form-control" id="inputFeedback" aria-describedby="feedbackHelp" placeholder="Digite aqui!" autocomplete="off" v-model="busca.feedback" @keydown.enter="pesquisar()">
                                </input-container-component>
                            </div>                                                  
                        </div>                        
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <button type="submit" class="btn btn-primary-generic btn-sm float-right" @click="pesquisar()">Pesquisar</button>
                    </template>
                </card-component>
                <!-- Fim do card de pesquisa -->
            </div>

            <div class="col-md-10">
                <!-- Inicio do card de listagem de feedbacks -->
                <card-component titulo="Lista de Feedbacks">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <table-component 
                            :dados="feedbacks.data" 
                            :titulos="{  // Relação de titulos a serem listados e seus respectivos titulos
                                id: {titulo: 'ID', tipo: 'inteiro'},
                                feedback: {titulo: 'Feedback', tipo: 'texto'},
                                user: {campo: 'name', titulo: 'Colaborador', tipo: 'chave'},
                                user_feedback: {campo: 'name', titulo: 'Colab. Feedback', tipo: 'chave'},
                                created_at: {titulo: 'Data Feedback', tipo: 'data_hora'}
                            }"
                            :botoes="{   // Relação de botoes a serem listados e suas respectivas propriedades
                                visualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalVisualizarFeedback', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                atualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalAtualizarFeedback', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                excluir: {visivel: true, dataToggle: 'modal', dataTarget: '#modalRemoverFeedback', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso}
                            }"
                            
                        >
                        </table-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <div class="row align-items-center">
                            <div class="col">
                                <paginate-component>
                                    <li @click="paginacao(feedbacks.links[0])" class="page-item">
                                        <a class="page-link" v-if="feedbacks.links" v-html="feedbacks.links[0].label"></a>
                                    </li>
                                    <li v-if="feedbacks.current_page != 1" @click="paginacao(feedbacks.links[feedbacks.current_page-1])" class="page-item">
                                        <a class="page-link" v-if="feedbacks.links" v-html="feedbacks.links[feedbacks.current_page-1].label"></a>
                                    </li>
                                    <li @click="paginacao(feedbacks.links[feedbacks.current_page])" class="page-item active">
                                        <a class="page-link" v-if="feedbacks.links" v-html="feedbacks.links[feedbacks.current_page].label"></a>
                                    </li>
                                    <li v-if="feedbacks.last_page > 1 && feedbacks.last_page != feedbacks.current_page" @click="paginacao(feedbacks.links[feedbacks.current_page+1])" class="page-item">
                                        <a class="page-link" v-if="feedbacks.links" v-html="feedbacks.links[feedbacks.current_page+1].label"></a>
                                    </li>
                                    <li v-if="feedbacks.last_page > 1 && feedbacks.last_page != feedbacks.current_page" @click="paginacao(feedbacks.links[feedbacks.current_page+1])" class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                    <li v-else class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                </paginate-component>
                            </div>
                            <div class="col mt-2 mt-md-0">
                                <button type="button" class="btn btn-primary-generic btn-sm float-right" data-toggle="modal" data-target="#modalFeedback" @click="limparTransacao()">Adicionar</button>
                            </div>
                        </div>
                    </template>
                </card-component>
                <!-- Fim do card de listagem de feedbacks -->
            </div>
        </div>

        <!-- Inicio Modal de adição de novos feedbacks-->
        <modal-component id="modalFeedback" titulo="Adicionar Feedback" tamanho="md">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo>    
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                           
                <input-container-component id="novoFeedback" label="Feedback" id-help="novoFeedbackHelp" texto-help="Informe o feedback do colaborador.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <textarea class="form-control" id="novoFeedbackHelp" aria-describedby="novoFeedbackHelp" rows="3" v-model="feedback"></textarea>
                </input-container-component>
                <input-container-component id="novoColaboradorID" label="ID do Colaborador" id-help="novoColaboradorIDHelp" texto-help="Informe o ID do colaborador.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="text" class="form-control" id="novoColaboradorID" aria-describedby="novoColaboradorIDHelp" placeholder="ID do Colaborador" autocomplete="off" v-model="user_id">
                </input-container-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro realizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao realizar cadastro do feedback" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="salvar()">Salvar</button>
            </template>            
        </modal-component>
        <!-- Fim Modal de adição de novos feedbacks-->

        <!-- Inicio Modal de visualização de feedbacks-->
        <modal-component id="modalVisualizarFeedback" titulo="Visualizar Feedback" tamanho="md">
            <template v-slot:conteudo>
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Feedback">
                    <textarea class="form-control" rows="3" :value="$store.state.item.feedback" disabled></textarea>
                </input-container-component>
                <input-container-component label="Colaborador" v-if="$store.state.item.user">
                    <input type="text" class="form-control" :value="$store.state.item.user.name" disabled>
                </input-container-component>
                <input-container-component label="Colaborador Feedback" v-if="$store.state.item.user_feedback">
                    <input type="text" class="form-control" :value="$store.state.item.user_feedback.name" disabled>
                </input-container-component>
                <input-container-component label="Data de criação">
                    <input type="text" class="form-control" :value="$store.state.item.created_at | formataDataHora" disabled>
                </input-container-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
            </template>
        </modal-component>
        <!-- Fim Modal de visualização de feedbacks-->

        <!-- Inicio Modal de atualização de feedbacks-->
        <modal-component id="modalAtualizarFeedback" titulo="Atualizar Feedback" tamanho="md">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo>   
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                             
                <input-container-component id="atualizarFeedback" label="Feedback" id-help="atualizarFeedbackHelp" texto-help="Informe o feedback do colaborador.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <textarea class="form-control" id="atualizarFeedback" aria-describedby="atualizarFeedbackHelp" rows="3" v-model="$store.state.item.feedback"></textarea>
                </input-container-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro atualizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao atualizar cadastro do feedback" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="atualizar()">Atualizar</button>
            </template>                      
        </modal-component>
        <!-- Fim Modal de atualização de feedbacks-->

        <!-- Inicio Modal de remoção de feedbacks-->
        <modal-component id="modalRemoverFeedback" titulo="Remover Feedback" tamanho="md">
            <template v-slot:conteudo v-if="$store.state.transacao.status != 'sucesso'">
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Feedback">
                    <textarea class="form-control" rows="3" :value="$store.state.item.feedback" disabled></textarea>
                </input-container-component>
            </template>
            <template v-slot:alertas>
                <alert-component tipo="success" titulo="Registro removido com sucesso" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" titulo="Falha ao remover registro" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-danger-generic" @click="excluir()" v-if="$store.state.transacao.status != 'sucesso'">Excluir</button>
            </template>
        </modal-component>
        <!-- Fim Modal de remoção de feedbacks-->
    </div>
</template>

<script>
    export default {
        // Define os atributos que poderão ser usados nesta instancia do Vue
        data() {
            return{
                urlBase: 'api/feedback',
                urlPaginacao: '',
                urlFiltro: '',
                numPaginas: '&paginas=30',
                feedback: '',
                user_id: '',
                feedbacks: { data: [] },
                busca: { feedback: '', user_id: '' }
            }            
        },
        // Define os metodos usados nesta instancia do Vue
        methods: {
            // Metodo para buscar os registros da tabela feedbacks
            carregarLista() {
                // Montando url de requisição com possiveis parâmetros de filtro e paginação
                let url = this.$store.state.apiHost + this.urlBase + '?' + this.urlPaginacao + '&order_desc=created_at' + this.urlFiltro + this.numPaginas
                // Requisição de consulta de registros na tabela feedbacks no banco
                return axios.get(url)
                    .then(response => {
                        this.feedbacks = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            // Metodo para buscar os registros da tabela filiais
            carregarUser() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarUser')
            },
            // Metodo para criar links de requisição de buscas paginadas
            paginacao(l) {
                if (l.url) {
                    // Capturando apenas parâmetro de pagina da request de paginação
                    this.urlPaginacao = l.url.split('?')[1] 
                    this.carregarLista()
                }                
            },
            // Metodo de pesquisa de registros na tabela feedbacks por nome do registro
            pesquisar() {
                let filtro = ''
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.busca) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.busca[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':like:%' + this.busca[chave] + '%'
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                // Define a primeira pagina para o resultado da busca
                this.urlPaginacao = 'page=1'
                this.carregarLista()

            },
            limparTransacao() {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.feedback = ''
            },
            // Metodo para inserção de registros na tabela feedbacks no banco
            salvar() {      
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('feedback', this.feedback)
                formData.append('user_id', this.user_id)
                formData.append('user_feedback_id', this.$store.state.user.id)
                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }                
                // Requisição de inserção de registros atraves da biblioteca axios
                axios.post(this.$store.state.apiHost + this.urlBase, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando registro cadastrado no banco
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id 
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Registro inserido com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Falha ao tentar inserir o registro', 'Erro', 'error');
                    })
            },
            atualizar() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Recupera a urlBase e concatena com o id do registro a se atualizado
                let url = this.$store.state.apiHost + this.urlBase+'/'+this.$store.state.item.id
                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('_method', 'patch')
                formData.append('feedback', this.$store.state.item.feedback)
                formData.append('user_feedback_id', this.$store.state.user.id)
                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                } 
                // Requisição de atualização de registros atraves da biblioteca axios
                axios.post(url, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando a mensagem da requisição
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Registro alterado com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Falha ao tentar alterar o registro', 'Erro', 'error');
                    })
            },
            excluir() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Abre uma caixa de dialogo para confirmar a exclusão do registro
                this.$confirm(
                    'Tem certeza que deseja remover o registro?',
                    'Exclusão de Registro',
                    'question'
                ).then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url = this.$store.state.apiHost + this.urlBase+'/'+ this.$store.state.item.id
                    // Instanciando formulario e definindo o _method delete
                    let formData = new FormData()
                    formData.append('_method', 'delete')
                    // Requisição de remoção de registro por id
                    axios.post(url, formData)
                        .then(response => {
                            // Definindo status da requisição como sucesso
                            this.$store.state.transacao.status = 'sucesso'
                            // Recuperando a mensagem da requisição
                            this.$store.state.transacao.mensagem = response.data.msg
                            this.$store.state.transacao.dados = ''
                            this.carregarLista()

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false
                        })
                        .catch(errors => {
                            // Definindo status da requisição como erro
                            this.$store.state.transacao.status = 'erro'
                            // Recuperando erros da requisição
                            this.$store.state.transacao.mensagem = errors.response.data.erro

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false
                        })
                }).catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false
                })
            }
        },
        // Executa uma ação quando o componente estiver montado
        async mounted() {
            await this.carregarLista()
            await this.carregarUser()
        }
    }
</script>