<template>
    <div class="card mt-5 border-0">
        <div class="card-header bg-third-color card-border-bottom border-radius-card-header">
            <div class="row">
                <div class="col-9">
                    <h2 class="title-card text-white font-weight-bold">{{titulo}}</h2>
                </div>
                <div class="col-3">
                    <img :src="icone" class="card-position-icon" :alt="'Icone '+titulo">
                </div>                
            </div>
        </div>
        <div class="card-body bg-primary-color border-radius-card-body shadow-card">
            <div v-if="dados.length > 0">
                <div v-for="a in dados" :key="a.id" class="item-card card-border-bottom mb-3 text-white">
                    <h3 class="text-truncate text-card font-weight-bold">{{a.name}}</h3>
                    <p v-if="a.filial" class="text-truncate text-card text-right">{{a.filial.filial}}</p>
                    <p v-if="a.setor" class="text-truncate text-card text-right">{{a.setor.setor}}</p>
                </div>
            </div>            
            <div v-else>
                <h3 class="text-card text-white text-center">Nenhum registro encontrado</h3>
            </div>  
        </div>
    </div>
</template>

<script>
    export default {
        props: ['titulo', 'icone', 'dados'],
    }
</script>