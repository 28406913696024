<template>
    <div>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">ID</th>
                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">Atividade</th>
                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">Data Prazo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="dados.length != 0">
                    <!-- Percorre o array de objetos e para cada objeto percorre seus devidos campos -->
                    <tr v-for="obj, key in dados" :key="key">
                        <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{obj.id}}</td>
                        <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{obj.atividade}}</td>
                        <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{obj.data_prazo | formataDataSimples}}</td>
                        <td class="text-center">
                            <button class="btn btn-primary-generic btn-sm mb-1 mb-md-0" @click="setStore(obj)" data-toggle="modal" data-target="#modalVisualizarAtividadesPendentes">Visualizar</button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="4" class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">Nenhuma atividade encontrada!</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>    
</template>

<script>
    export default {
        props: ['dados', 'atividadesUsuarios', 'users'],
        methods: {
            setStore(obj) {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.$store.state.item = {}
                this.$store.state.atividadesUsuarios = []
                // Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
                this.$store.state.item = obj

                let activityUserOK = []
                let activityUser = []
                let pendingUser = []
                this.users.forEach(user => {
                    obj.filiais.forEach(filial => {
                        if (filial.id == user.filial_id) {
                            obj.setores.forEach(setor => {
                                if (setor.id == user.setor_id) {
                                    obj.cargos.forEach(cargo => {
                                        if (cargo.id == user.cargo_id) {
                                            activityUser.push(user)
                                        }
                                    });
                                }
                            });
                        }
                    });                         
                }); 
                
                this.atividadesUsuarios.forEach(atividade => {
                    this.users.forEach(user => {
                        if (atividade.atividade_id == obj.id && user.id == atividade.user_id) {
                            activityUserOK.push(user.id)
                        }
                    })
                })

                pendingUser = activityUser.filter(user => {
                    return !activityUserOK.includes(user.id)
                })

                this.$store.state.atividadesUsuarios = pendingUser
            }
        }
    }
</script>