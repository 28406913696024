<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 mb-5">
                <!-- Inicio do card de pesquisa -->
                <card-component titulo="Pesquisa de Atividades Pendentes">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <div class="row">
                            <div class="col-12">
                                <input-container-component id="inputAtividade" label="Atividade" id-help="atividadeHelp" texto-help="Informe a atividade que deseja pesquisar!">
                                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                                    <select class="form-control" id="inputAtividade" v-model="busca.id">
                                        <option v-for="a in $store.state.atividades" :key="a.id" :value="a.id">{{a.atividade}}</option>
                                    </select> 
                                </input-container-component>
                            </div>                                                                              
                        </div>                        
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <button type="submit" class="btn btn-primary-generic btn-sm float-right" @click="pesquisar()">Pesquisar</button>
                    </template>
                </card-component>
                <!-- Fim do card de pesquisa -->
            </div>

            <div class="col-md-10">
                <!-- Inicio do card de listagem de atividades pendentes -->
                <card-component titulo="Lista de Atividades Pendentes">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <table-pending-activity-user-component
                            :dados="atividades"
                            :atividades-usuarios="atividadesUsuarios"
                            :users="$store.state.users"
                        >
                        </table-pending-activity-user-component>
                    </template>
                </card-component>
                <!-- Fim do card de listagem de atividades pendentes -->

                <!-- Inicio Modal de visualização de atividades pendentes-->
                <modal-component id="modalVisualizarAtividadesPendentes" titulo="Visualizar Atividades Pendentes" tamanho="xl">
                    <template v-slot:conteudo>
                        <h5 class="title-card mb-4 px-2">{{$store.state.item.atividade}}</h5>
                        <div class="table-responsive px-2">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">ID</th>
                                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">Colaborador</th>
                                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">Filial</th>
                                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">Setor</th>
                                        <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col">Cargo</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="$store.state.atividadesUsuarios.length > 0">
                                    <!-- Percorre o array de objetos e para cada objeto percorre seus devidos campos -->
                                    <tr v-for="a, key in $store.state.atividadesUsuarios" :key="key">
                                        <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{a.id}}</td>
                                        <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{a.name}}</td>
                                        <template v-if="a.filial">
                                            <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{a.filial.filial}}</td>
                                            <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{a.setor.setor}}</td>
                                            <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">{{a.cargo.cargo}}</td>
                                        </template>                                        
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="5" class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase">Todos os colaboradores concluíram essa atividade</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <template v-slot:rodape>
                        <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                    </template>
                </modal-component>
                <!-- Fim Modal de visualização de atividades pendentes-->
            </div>
        </div>        
    </div>
</template>

<script>
    export default {
        // Define os atributos que poderão ser usados nesta instancia do Vue
        data() {
            return{
                urlFiltro: '',
                busca: { id: '' },
                atividades: [],
                atividadesUsuarios: [],
                user: []
            }            
        },
        // Define os metodos usados nesta instancia do Vue
        methods: {
            carregarAtividades() {  
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarAtividades')
            },
            carregarUsers() {  
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarUsers')
            },
            carregarTableAtividades() {  
                // Montando url de requisição com possiveis parâmetros de filtro
                let url = this.$store.state.apiHost + 'api/atividade?order=atividade' + this.urlFiltro
                // Requisição de consulta de registros na tabela atividades no banco
                return axios.get(url)
                    .then(response => {
                        this.atividades = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            carregarAtividadesUsuarios() {  
                // Requisição de consulta de registros na tabela atividades_usuarios no banco
                return axios.get(this.$store.state.apiHost + 'api/atividade-usuario?order_desc=created_at')
                    .then(response => {
                        this.atividadesUsuarios = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            // Metodo de pesquisa de registros na tabela atividades_usuarios por nome do registro
            pesquisar() {
                let filtro = ''
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.busca) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.busca[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':=:' + this.busca[chave]
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                
                this.carregarTableAtividades()
                this.carregarAtividadesUsuarios()
            },
        },
        // Executa uma ação quando o componente estiver montado
        async mounted() {
            await this.carregarTableAtividades()
            await this.carregarAtividadesUsuarios()
            await this.carregarUsers()
            await this.carregarAtividades()
        }
    }
</script>