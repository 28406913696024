<template>
    <div class="card-body">
        <p class="text-truncate" v-for="d in dadosFiltrados" :key="d.id">
            <i class="fas fa-genderless mr-2"></i>
            <a style="cursor: pointer;" data-toggle="modal" data-target="#modalWiki" @click="setStore(d)">
                {{d.wiki}}
            </a>
        </p>
    </div>
</template>

<script>
    export default {
        props: ['dados', 'setor'],
        computed: {
            dadosFiltrados () {
                // Recupera as wikis que correspondem a cada setor
                let dadosFiltrados = []
                this.dados.forEach(dado => {
                    dado.setores.forEach(setor => {
                        if (setor.id == this.setor) {
                            let itemFiltrado = {}
                            itemFiltrado['wiki'] = dado.wiki
                            itemFiltrado['descricao'] = dado.descricao
                            itemFiltrado['texto'] = dado.texto
                            itemFiltrado['user'] = dado.user
                            itemFiltrado['created_at'] = dado.created_at
                            dadosFiltrados.push(itemFiltrado) 
                        }                                
                    }) 
                });
                return dadosFiltrados
            }
        },
        methods: {
            setStore(obj) {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.$store.state.item = {}
                // Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
                this.$store.state.item = obj
            }
        }
    }
</script>