<template>
    <div class="form-group">                           
        <label class="font-weight-bold" :for="id">{{label}}</label>
        <slot></slot>
        <small :id="idHelp" class="form-text text-muted">{{textoHelp}}</small>
    </div>
</template>

<script>
    export default {
        // Propriedades passadas no input-component
        props: ['id', 'label', 'idHelp', 'textoHelp']
    }
</script>