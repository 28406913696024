<template>
    <nav aria-label="Page navigation">
        <ul class="pagination" style="cursor: pointer;">
            <slot></slot>
        </ul>
    </nav>
</template>

<script>
    export default {
        
    }
</script>