<template>
    <div class="back-to-top" href="#"></div>
</template>

<script>
$(document).ready(function () {
    $(".back-to-top").click(function() {
        $("html, body").animate({scrollTop: 0}, 800);
    });
});
</script>

<style scoped>
    .back-to-top {
        background-color: #013B82;
        border-radius: 60px;
        border: 2px solid #FFFFFF;
        bottom: 15px;
        color: #FFFFFF;
        cursor: pointer;
        height: 30px;
        position: fixed;
        right: 15px;
        width: 30px;
        z-index: 3;    
        -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);    
    }
    .back-to-top::after {
        content: '▲';
        display: flex;
        justify-content: center;
        padding-left: 1px;
        padding-top: 2px;
    }
</style>