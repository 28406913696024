<template>
	<div class="row">
		<template v-if="dadosFiltrados.length > 0">
			<div
				class="card col-12 col-sm-6 col-md-4 border-0 mt-4"
				v-for="d, key in dadosFiltrados"
				:key="key"
			>
				<div class="card-header bg-third-color card-header-team">
					<h3
						v-if="d.cargo"
						class="text-white text-center title-card-team font-weight-bold text-truncate"
					>{{d.cargo.cargo}}</h3>
				</div>
				<div class="card-body bg-primary-color card-body-team">
					<p class="text-white text-card-team text-truncate">
						<i class="fas fa-user-tie pr-2"></i>
						{{d.name}}
					</p>
					<p v-if="d.setor" class="text-white text-card-team text-truncate">
						<i class="fas fa-toolbox pr-2"></i>
						{{d.setor.setor}}
						<!-- Botão Modal Feedback -->
						<!-- <button type="button" class="btn btn-modal-feedback float-right" data-toggle="modal"
                            data-target="#feedbackModal" @click="setStore(d)">
                            <img src="/storage/icons/feedback_colaborador.png" 
							alt="Icone de Feedback Colaborador">
						</button>-->
					</p>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="card col-12 border-0 mt-4">
				<div class="card-body bg-primary-color rounded-pill">
					<p
						class="text-white title-card-team text-center font-weight-bold"
					>Nenhum colaborador encontrado</p>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props: ["dados", "user"],
	computed: {
		dadosFiltrados() {
			// Recupera os usuários que correspondem ao setor e filial do usuário logado
			let dadosFiltrados = [];
			if (this.dados.length > 0) {
				this.dados.forEach((dado) => {
					if (dado.setor_id == this.user.setor_id) {
						if (dado.filial_id == this.user.filial_id) {
							if (dado.id != this.user.id) {
								let itemFiltrado = {};
								itemFiltrado["id"] = dado.id;
								itemFiltrado["name"] = dado.name;
								itemFiltrado["cargo"] = dado.cargo;
								itemFiltrado["setor"] = dado.setor;
								itemFiltrado["filial"] = dado.filial;
								itemFiltrado["imagem"] = dado.imagem;
								dadosFiltrados.push(itemFiltrado);
							}
						}
					}
				});
			}

			return dadosFiltrados;
		},
	},
	methods: {
		setStore(obj) {
			// Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
			this.$store.state.transacao.mensagem = "";
			this.$store.state.transacao.status = "";
			this.$store.state.transacao.dados = "";
			this.$store.state.item = {};
			this.$store.state.feedback = "";
			// Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
			this.$store.state.item = obj;
		},
	},
};
</script>