<template>
	<div class="row">
		<!-- Seção de Cards - Lateral Esquerda -->
		<section class="col-12 col-md-5 col-lg-4 col-xl-3 order-2 order-xl-1">
			<!-- Card de Avisos Importantes -->
			<card-left-component
				titulo="Avisos Importantes"
				icone="/storage/icons/megafone_icon.png"
				:dados="avisos"
				:user="$store.state.user"
			></card-left-component>

			<!-- Card de Atividades Pendentes -->
			<card-left-activity-component
				titulo="Atividades Pendentes"
				icone="/storage/icons/atividades_icon.png"
				:dados="atividades"
				:atividades-usuarios="atividadesUsuarios"
				:user="$store.state.user"
			></card-left-activity-component>

			<!-- Card de Treinamentos Previstos -->
			<card-left-component
				titulo="Lista de Treinamentos"
				data="evento"
				icone="/storage/icons/treinamento_icon.png"
				:dados="treinamentos"
				:user="$store.state.user"
			></card-left-component>

			<!-- Calendário de Eventos -->
			<agenda-component :attributes="agenda"></agenda-component>

			<!-- Cards Para Visualização em Layouts menores -->
			<!-- Seção de Cards - Lateral Direita -->
			<section class="order-3 order-xl-3 d-block d-xl-none">
				<!-- Card de Aniversariantes -->
				<card-right-sm-component
					titulo="Aniversários do Dia"
					icone="/storage/icons/bolo_icon.png"
					:dados="aniversariantes"
				></card-right-sm-component>

				<!-- Card de Novos Colaboradores -->
				<card-right-sm-component
					titulo="Novos Colaboradores"
					icone="/storage/icons/info_icon.png"
					:dados="novosColaboradores"
				></card-right-sm-component>
			</section>
		</section>
		<!-- Seção de Postagens - Conteudo Central -->
		<section class="col-12 col-md-7 col-lg-8 col-xl-6 order-1 order-xl-2">
			<!-- Barra de Pesquisa de Postagens por Título de Postagem -->
			<div class="row my-5">
				<div class="col-12">
					<div class="form-group">
						<input
							type="text"
							class="form-control input-search-post placeholder px-4"
							autocomplete="off"
							id="search_post"
							placeholder="O que você deseja?"
							v-model="busca.publicacao"
							@keydown.enter="pesquisar()"
						/>
					</div>
					<div class="form-group row">
						<label
							for="categoria"
							class="col-sm-1 col-form-label text-primary-color font-weight-bold pl-4"
						>Filtro:</label>
						<div class="col-sm-4 pl-3">
							<select
								class="form-control select-category text-primary-color font-weight-bold"
								id="categoria"
								v-model="categoria"
							>
								<option class="option-category" value selected>Todas</option>
								<option
									class="option-category"
									v-for="ctg in $store.state.categorias"
									:key="ctg.id"
									:value="ctg.id"
								>{{ctg.categoria}}</option>
							</select>
						</div>
					</div>
					<button type="submit" class="btn btn-search float-right mr-3" @click="pesquisar()">PESQUISAR</button>
				</div>
			</div>

			<!-- Seção de Cards de Publicações -->
			<section>
				<div v-if="publicacoes.length > 0">
					<post-component :dados="publicacoes" :user="$store.state.user"></post-component>
				</div>
				<div v-else class="row mt-5">
					<div class="card mx-3 card-publication w-100">
						<div class="card-body">
							<h5
								class="col-12 text-center card-title title-card-publication text-primary-color font-weight-bold"
							>Nenhum registro encontrado!</h5>
						</div>
					</div>
				</div>
			</section>

			<!-- Botão ver mais publicações -->
			<div v-if="publicacoes.length > 0" class="row mt-3">
				<button type="submit" class="btn btn-search px-4 mx-auto" @click="verMais()">VER MAIS</button>
			</div>
		</section>

		<!-- Seção de Cards - Lateral Direita -->
		<section class="col-12 col-md-5 col-lg-4 col-xl-3 order-3 order-xl-3 d-none d-xl-block">
			<!-- Card de Aniversariantes -->
			<card-right-component
				titulo="Aniversários do Dia"
				icone="/storage/icons/bolo_icon.png"
				:dados="aniversariantes"
			></card-right-component>

			<!-- Card de Novos Colaboradores -->
			<card-right-component
				titulo="Novos Colaboradores"
				icone="/storage/icons/info_icon.png"
				:dados="novosColaboradores"
			></card-right-component>
		</section>

		<!-- Inicio Modal Agenda -->
		<modal-post-component id="modalPostAgenda" tamanho="md">
			<template v-slot:conteudo>
				<button type="button" class="btn btn-close-modal float-right pr-2" data-dismiss="modal">
					<i class="far fa-times-circle icon-btn-close-modal"></i>
				</button>
				<!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
				<template v-if="$store.state.item.customData">
					<h3
						class="text-primary-color font-weight-bold text-center title-modal-post px-3 px-md-0 mt-4"
					>{{$store.state.item.customData.title}}</h3>
					<p
						class="font-weight-bold text-justify subtitle-modal-post mt-5 px-4"
					>{{$store.state.item.customData.description}}</p>
					<div class="row align-items-center mt-4">
						<div class="col-12 text-left">
							<p
								class="mb-2 pl-3 info-modal-post"
							>Data do Treinamento: {{$store.state.item.customData.date | formataDH}}</p>
						</div>
					</div>
				</template>
			</template>
		</modal-post-component>

		<!-- Inicio Modal -->
		<modal-post-component id="modalPost" tamanho="lg">
			<template v-slot:conteudo>
				<button type="button" class="btn btn-close-modal float-right pr-2" data-dismiss="modal">
					<i class="far fa-times-circle icon-btn-close-modal"></i>
				</button>
				<!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
				<h3
					class="text-primary-color font-weight-bold text-center title-modal-post px-3 px-md-0 mt-4"
				>{{$store.state.item.publicacao}}</h3>
				<p
					class="font-weight-bold text-justify subtitle-modal-post mt-5 px-4"
				>{{$store.state.item.descricao}}</p>
				<div class="row justify-content-center">
					<div class="mt-3 mx-auto px-5" v-html="$store.state.item.texto"></div>
				</div>
				<!-- LIstagem de imagens da tabela relacionada publicacoes_imagens -->
				<div
					v-if="$store.state.item.publicacoes_imagens"
					id="carouselPost"
					class="carousel slide carousel-fade mt-5 px-5"
					data-ride="carousel"
				>
					<div class="carousel-inner">
						<div class="carousel-item active">
							<img
								:src="'/storage/'+$store.state.item.publicacoes_imagens[$store.state.item.publicacoes_imagens.length-1].url"
								class="d-block w-100"
							/>
						</div>
						<div v-for="img in $store.state.item.publicacoes_imagens" :key="img.id" class="carousel-item">
							<img :src="'/storage/'+img.url" class="d-block w-100" />
						</div>
					</div>
					<a class="carousel-control-prev" href="#carouselPost" role="button" data-slide="prev">
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="sr-only">Previous</span>
					</a>
					<a class="carousel-control-next" href="#carouselPost" role="button" data-slide="next">
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="sr-only">Next</span>
					</a>
				</div>
				<div class="row align-items-center mt-4">
					<div class="col-12 text-left">
						<p
							v-if="$store.state.item.user"
							class="mb-1 pl-3 info-modal-post"
						>Autor: {{$store.state.item.user.name}}</p>
						<p
							v-if="$store.state.item.created_at"
							class="mb-1 pl-3 info-modal-post"
						>Data de Postagem: {{$store.state.item.created_at | formataDataHora}}</p>
						<p
							v-if="$store.state.item.treinamento == 's'"
							class="mb-2 pl-3 info-modal-post"
						>Data do Treinamento: {{$store.state.item.data_evento | formataDH}}</p>
					</div>
				</div>
			</template>
		</modal-post-component>
		<!-- Fim Modal -->

		<!-- Modal Atividade -->
		<div
			class="modal fade"
			id="atividadeModal"
			tabindex="-1"
			aria-labelledby="atividadeModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header bg-primary-color border-0">
						<h5 class="modal-title text-white" id="atividadeModalLabel">{{$store.state.item.atividade}}</h5>
						<button type="button" class="close btn-close-modal" data-dismiss="modal" aria-label="Close">
							<!-- <span aria-hidden="true">&times;</span> -->
							<i class="far fa-times-circle text-white"></i>
						</button>
					</div>
					<div class="modal-body">
						<!-- Chama o component de loader no envio da requisição -->
						<loader
							v-if="$store.state.loader"
							:object="$store.state.colorObject"
							:size="$store.state.size"
							:speed="$store.state.speed"
							:bg="$store.state.colorBg"
							:opacity="$store.state.opacity"
							:disableScrolling="$store.state.disableScrolling"
							:name="$store.state.name"
						></loader>

						<p class="description-activity mt-3 px-3">{{$store.state.item.descricao}}</p>

						<!-- <hr /> -->

						<!-- <div v-if="$store.state.transacao.status != 'sucesso'">
                            <div v-for="p in $store.state.item.perguntas" :key="p.id">
                                Visualiza atividades se a mesma estiver pendente
                                <div class="mt-5 px-3">
                                    <p class="text-activity font-weight-bold">{{p.pergunta}}</p>
                                    <div class="px-3">
                                        <div v-for="a in $store.state.item.alternativas" :key="a.id">
                                            <div class="form-check" v-if="a.pergunta_id == p.id">
                                                <input class="form-check-input" type="radio" :name="'alt_'+p.id" :id="'alt_'+p.id" :value="a.id" required>
                                                <label class="form-check-label alternative-activity" :for="'alt_'+p.id">
                                                    {{a.alternativa}}
                                                </label>
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
						</div>-->

						<!-- Lista alertas de retorno de envio de requisição -->
						<div
							class="mt-4"
							v-if="$store.state.transacao.status == 'sucesso' || $store.state.transacao.status == 'erro'"
						>
							<alert-component
								tipo="success"
								:detalhes="$store.state.transacao"
								titulo="Atividade respondida com sucesso"
								v-if="$store.state.transacao.status == 'sucesso'"
							></alert-component>
							<alert-component
								tipo="danger"
								:detalhes="$store.state.transacao"
								titulo="Erro ao responder atividade"
								v-if="$store.state.transacao.status == 'erro'"
							></alert-component>
						</div>
					</div>
					<!-- Botões de Enviar e Excluir atividades respondidas -->
					<div class="modal-footer">
						<button type="button" class="btn btn-cancel" data-dismiss="modal">Fechar</button>
						<!-- <button
							type="button"
							class="btn btn-confirm"
							v-if="$store.state.transacao.status != 'sucesso'"
							@click="salvarAtividadeRespondida()"
						>Enviar</button>-->
					</div>
				</div>
			</div>
		</div>
		<!-- Fim Modal Atividade -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			agenda: [],
			avisos: [],
			treinamentos: [],
			atividades: [],
			atividadesUsuarios: [],
			aniversariantes: [],
			novosColaboradores: [],
			publicacoes: [],
			busca: { publicacao: "" },
			categoria: "",
			urlBase: "api/publicacao",
			urlPaginacao: "",
			urlFiltro: "&filtro=status:=:a",
			orderByDesc: "&order_desc=created_at",
			pagina: 1,
			limit: 5,
			offset: 0,
			pesquisa: true,
		};
	},
	methods: {
		carregarCardAvisos() {
			// Requisição de consulta de registros na tabela publicações no banco
			return axios
				.get(
					this.$store.state.apiHost +
						"api/publicacao?order_desc=created_at&atributos_cargos_user=cargo&atributos_setores_user=setor&atributos_filiais_user=filial&limite=5&filtro=importante:=:s;status:=:a"
				)
				.then((response) => {
					this.avisos = response.data;
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		carregarCardTreinamentos() {
			// Requisição de consulta de registros na tabela publicações no banco
			return axios
				.get(
					this.$store.state.apiHost +
						"api/publicacao?order_desc=data_evento&atributos_cargos_user=cargo&atributos_setores_user=setor&atributos_filiais_user=filial&limite=5&filtro=treinamento:=:s;status:=:a&not_null=data_evento"
				)
				.then((response) => {
					this.treinamentos = response.data;
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		carregarCardAtividades() {
			// Montando url de requisição com possiveis parâmetros de filtro
			let url =
				this.$store.state.apiHost +
				"api/atividade?&atributos_cargos_user=cargo&atributos_setores_user=setor&atributos_filiais_user=filial&order_desc=created_at&limite=5&filtro=status:=:a";
			// Requisição de consulta de registros na tabela atividades no banco
			return axios
				.get(url)
				.then((response) => {
					this.atividades = response.data;
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		carregarAtividadesUsuarios() {
			// Requisição de consulta de registros na tabela atividades_usuarios no banco
			return axios
				.get(
					this.$store.state.apiHost +
						"api/atividade-usuario?order_desc=created_at"
				)
				.then((response) => {
					this.atividadesUsuarios = response.data;
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		carregarCardAniversariantes() {
			let hoje = new Date();

			let mes = hoje.getMonth() + 1;
			if (mes < 10) {
				mes = "0" + mes;
			}

			let dia = hoje.getDate();
			if (dia < 10) {
				dia = "0" + dia;
			}

			// Requisição de consulta de registros na tabela users no banco com filtro por data de nascimento
			return axios
				.get(
					this.$store.state.apiHost +
						"api/user?order=filial_id&filtro=status:=:a;data_nascimento:like:%-" +
						mes +
						"-" +
						dia
				)
				.then((response) => {
					this.aniversariantes = response.data;
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		carregarCardNovosColaboradores() {
			let hoje = new Date();
			let dia = "";
			let mes = "";
			let ano = "";
			if (hoje.getDate() > 7) {
				ano = hoje.getFullYear();
				mes = hoje.getMonth() + 1;
				dia = hoje.getDate() - 7;
			} else {
				if (hoje.getMonth() > 0) {
					ano = hoje.getFullYear();
					mes = hoje.getMonth();
					dia = hoje.getDate() + 21;
				} else {
					ano = hoje.getFullYear() - 1;
					mes = hoje.getMonth() + 12;
					dia = hoje.getDate() + 21;
				}
			}

			if (mes < 10) {
				mes = "0" + mes;
			}
			if (dia < 10) {
				dia = "0" + dia;
			}

			// Requisição de consulta de registros na tabela users no banco com filtro por data de admissão
			return axios
				.get(
					this.$store.state.apiHost +
						"api/user?order=filial_id&filtro=status:=:a;data_admissao:>:" +
						ano +
						"-" +
						mes +
						"-" +
						dia
				)
				.then((response) => {
					this.novosColaboradores = response.data;
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		carregarPublicacoes() {
			// Montando url de requisição com possiveis parâmetros de filtro e paginação
			let url =
				this.$store.state.apiHost +
				this.urlBase +
				"?" +
				this.orderByDesc +
				"&atributos_cargos_user=cargo&atributos_setores_user=setor&atributos_filiais_user=filial" +
				this.urlFiltro +
				"&limite=" +
				this.limit +
				"&offset=" +
				this.offset;
			// Requisição de consulta de registros na tabela publicações no banco
			return axios
				.get(url)
				.then((response) => {
					response.data.forEach((publicacao) => {
						this.publicacoes.push(publicacao);
					});
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		carregarAgenda() {
			return axios
				.get(
					this.$store.state.apiHost +
						this.urlBase +
						"?not_null=data_evento"
				)
				.then((response) => {
					response.data.forEach((a) => {
						let dados = {};
						dados["id"] = a.id;
						dados["customData"] = {
							title: a.publicacao,
							description: a.descricao,
							date: a.data_evento,
						};
						let data = a.data_evento.split(" ")[0].split("-");
						dados["dates"] = new Date(
							data[0],
							data[1] - 1,
							data[2]
						);
						this.agenda.push(dados);
					});
				})
				.catch((errors) => {
					console.log(errors.response);
				});
		},
		// Metodo para buscar o registro do usuário logado
		carregarUser() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarUser");
		},
		// Metodo para buscar os registros da tabela categorias
		carregarCategorias() {
			// Executa o metodo da store do vuex (app.js)
			this.$store.commit("carregarCategorias");
		},
		// Metodo para listas mais regitros da tabela de publicações
		verMais() {
			this.pagina++;
			this.offset = this.pagina * this.limit - this.limit;
			this.carregarPublicacoes();
		},
		// Metodo de pesquisa de registros na tabela publicações por nome do registro
		pesquisar() {
			this.publicacoes = [];
			this.offset = 0;
			this.pagina = 1;
			let filtro = "";

			if (this.categoria != "") {
				filtro = "status:=:a;categoria_id:=:" + this.categoria;
			} else {
				filtro = "status:=:a";
			}

			// Percorre o objeto 'busca' concatenando seus atributos em uma string
			for (let chave in this.busca) {
				// Verifica se o campo foi preenchido para atribuir o valor
				if (this.busca[chave]) {
					// Separa cada atributo com ';'
					if (filtro != "") {
						filtro += ";";
					}
					// Separa valor e chave com ':like:'
					filtro +=
						chave + ":like:%" + this.busca[chave].substr(1) + "%";
				}
			}

			// Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
			if (filtro != "") {
				this.urlFiltro = "&filtro=" + filtro;
			} else {
				this.urlFiltro = "";
			}

			this.carregarPublicacoes();
		},
		// Metodo para inserção de registros na tabela atividades_usuarios no banco
		salvarAtividadeRespondida() {
			// Define a variaval global Spinner como true
			this.$store.state.loader = true;

			let radio = [];
			let preenchido = false;
			let cont = 0;
			// Valida se todas as perguntas da atividade foram respondidas
			this.$store.state.item.perguntas.forEach((pergunta) => {
				radio = document.getElementsByName("alt_" + pergunta.id);
				for (var j = 0, rd = radio.length; j < rd; j++) {
					if (radio[j].checked) {
						cont++;
						if (cont == this.$store.state.item.perguntas.length) {
							preenchido = true;
						}
					}
				}
			});
			// Insere no banco os registro se todas a atividades foram respondidas
			this.$store.state.item.perguntas.forEach((pergunta) => {
				if (preenchido) {
					radio = document.getElementsByName("alt_" + pergunta.id);
					for (var i = 0, r = radio.length; i < r; i++) {
						if (radio[i].checked) {
							// Instanciando formulario e definindo seus atributos
							let formData = new FormData();
							formData.append(
								"atividade_id",
								this.$store.state.item.id
							);
							formData.append("pergunta_id", pergunta.id);
							formData.append("alternativa_id", radio[i].value);
							formData.append(
								"user_id",
								this.$store.state.user.id
							);

							// Configurando headers da requisição
							let config = {
								headers: {
									"Content-Type": "multipart/form-data",
								},
							};

							// Requisição de inserção de registros atraves da biblioteca axios
							axios
								.post(
									this.$store.state.apiHost +
										"api/atividade-usuario",
									formData,
									config
								)
								.then((response) => {
									// Definindo status da requisição como sucesso
									this.$store.state.transacao.status =
										"sucesso";
									// Recuperando registro cadastrado no banco
									this.$store.state.transacao.mensagem =
										"Todas as perguntas foram respondidas com sucesso";
									this.$store.state.transacao.dados = "";
									this.carregarCardAtividades();
									this.carregarAtividadesUsuarios();

									// Define a variaval global Spinner como false
									this.$store.state.loader = false;

									this.$alert(
										"Registro inserido com sucesso",
										"Sucesso",
										"success"
									);
								})
								.catch((errors) => {
									// Definindo status da requisição como erro
									this.$store.state.transacao.status = "erro";
									// Recuperando erros da requisição
									this.$store.state.transacao.mensagem =
										errors.response.data.message;
									this.$store.state.transacao.dados =
										errors.response.data.errors;

									// Define a variaval global Spinner como false
									this.$store.state.loader = false;

									this.$alert(
										"Falha ao tentar inserir o registro",
										"Erro",
										"error"
									);
								});
						}
					}
				} else {
					// Definindo status da requisição como erro
					this.$store.state.transacao.status = "erro";
					// Retorna a mensagem de erro se alguma atividade não foi respondida
					this.$store.state.transacao.mensagem =
						"Uma ou mais perguntas não foram respondidas";
					this.$store.state.transacao.dados = "";

					// Define a variaval global Spinner como false
					this.$store.state.loader = false;

					this.$alert(
						"Falha ao tentar inserir o registro",
						"Erro",
						"error"
					);
				}
			});
		},
	},
	async mounted() {
		await this.carregarCardAvisos();
		await this.carregarCardAniversariantes();
		await this.carregarCardTreinamentos();
		await this.carregarCardNovosColaboradores();
		await this.carregarCardAtividades();
		await this.carregarAtividadesUsuarios();
		await this.carregarPublicacoes();
		await this.carregarAgenda();
		await this.carregarUser();
		await this.carregarCategorias();
	},
};
</script>
