<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 mb-5">
                <!-- Inicio do card de pesquisa -->
                <card-component titulo="Pesquisa de Arquivos">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <input-container-component id="inputArquivo" label="Arquivo" id-help="arquivoHelp" texto-help="Informe o nome do arquivo que deseja pesquisar!">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input type="text" class="form-control" id="inputArquivo" aria-describedby="arquivoHelp" placeholder="Digite aqui!" autocomplete="off" v-model="busca.arquivo" @keydown.enter="pesquisar()">
                        </input-container-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <button type="submit" class="btn btn-primary-generic btn-sm float-right" @click="pesquisar()">Pesquisar</button>
                    </template>
                </card-component>
                <!-- Fim do card de pesquisa -->
            </div>

            <div class="col-md-12">
                <!-- Inicio do card de listagem de arquivos -->
                <card-component titulo="Lista de Arquivos">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <!-- filiais: {campo: 'filial', titulo: 'Filiais', tipo: 'modal', dataTarget: '#modalFiliais', classe: 'secondary'}, -->
                        <table-component 
                            :dados="arquivos.data" 
                            :titulos="{  // Relação de titulos a serem listados e seus respectivos titulos
                                id: {titulo: 'ID', tipo: 'inteiro'},
                                arquivo: {titulo: 'Arquivo', tipo: 'texto'},
                                url: {titulo: 'Tipo Arquivo', tipo: 'icone'},
                                created_at: {titulo: 'Data de criação', tipo: 'data_hora'},
                                user: {campo: 'name', titulo: 'Último usuário', tipo: 'chave'},
                                cargos: {titulo: '', tipo: 'nao_exibir'},
                                setores: {titulo: '', tipo: 'nao_exibir'},
                                filiais: {titulo: '', tipo: 'nao_exibir'},
                            }"
                            
                            :botoes="{   // Relação de botoes a serem listados e suas respectivas propriedades
                                visualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalVisualizarArquivo', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                atualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalAtualizarArquivo', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                excluir: {visivel: true, dataToggle: 'modal', dataTarget: '#modalRemoverArquivo', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso}
                            }"
                        >
                        </table-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <div class="row align-items-center">
                            <div class="col">
                                <paginate-component>
                                    <li @click="paginacao(arquivos.links[0])" class="page-item">
                                        <a class="page-link" v-if="arquivos.links" v-html="arquivos.links[0].label"></a>
                                    </li>
                                    <li v-if="arquivos.current_page != 1" @click="paginacao(arquivos.links[arquivos.current_page-1])" class="page-item">
                                        <a class="page-link" v-if="arquivos.links" v-html="arquivos.links[arquivos.current_page-1].label"></a>
                                    </li>
                                    <li @click="paginacao(arquivos.links[arquivos.current_page])" class="page-item active">
                                        <a class="page-link" v-if="arquivos.links" v-html="arquivos.links[arquivos.current_page].label"></a>
                                    </li>
                                    <li v-if="arquivos.last_page > 1 && arquivos.last_page != arquivos.current_page" @click="paginacao(arquivos.links[arquivos.current_page+1])" class="page-item">
                                        <a class="page-link" v-if="arquivos.links" v-html="arquivos.links[arquivos.current_page+1].label"></a>
                                    </li>
                                    <li v-if="arquivos.last_page > 1 && arquivos.last_page != arquivos.current_page" @click="paginacao(arquivos.links[arquivos.current_page+1])" class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                    <li v-else class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                </paginate-component>
                            </div>
                            <div class="col mt-2 mt-md-0">
                                <button type="button" class="btn btn-primary-generic btn-sm float-right" data-toggle="modal" data-target="#modalArquivo" @click="limparTransacao()">Adicionar</button>
                            </div>
                        </div>
                    </template>
                </card-component>
                <!-- Fim do card de listagem de arquivos -->
            </div>
        </div>

        <!-- Inicio Modal de adição de novos arquivos-->
        <modal-component id="modalArquivo" titulo="Adicionar Arquivo" tamanho="lg">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo>    
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                            
                <input-container-component id="novoNomeArquivo" label="Nome da Arquivo" id-help="novoNomeArquivoHelp" texto-help="Informe o nome da arquivo.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="text" class="form-control" id="novoArquivo" aria-describedby="novoNomeArquivoHelp" placeholder="Nome da Arquivo" autocomplete="off" v-model="arquivo">
                </input-container-component>
                <input-container-component id="novoArquivo" label="Arquivo" id-help="novoArquivoHelp" texto-help="Selecione o arquivo desejado.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="file" class="form-control-file" id="novoArquivo" aria-describedby="novoArquivoHelp" placeholder="Selecione o arquivo." @change="carregarArquivo($event)">
                </input-container-component>
                <div class="form-row">
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="novoCargos" label="Cargos da Arquivo" id-help="novoCargosHelp" texto-help="Informe os cargos da arquivo.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoCargos" size="3" multiple v-model="cargosSelecionados">
                                <option v-for="c in $store.state.cargos" :key="c.id" :value="c.id">{{c.cargo}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="novoSetores" label="Setores da Arquivo" id-help="novoSetoresHelp" texto-help="Informe os setores da arquivo.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoSetores" size="3" multiple v-model="setoresSelecionados">
                                <option v-for="s in $store.state.setores" :key="s.id" :value="s.id">{{s.setor}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="novoFiliais" label="Filiais da Arquivo" id-help="novoFiliaisHelp" texto-help="Informe os filiais da arquivo.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="novoFiliais" size="3" multiple v-model="filiaisSelecionadas">
                                <option v-for="f in $store.state.filiais" :key="f.id" :value="f.id">{{f.filial}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                </div>   
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro realizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao realizar cadastro do arquivo" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="salvar()">Salvar</button>
            </template>            
        </modal-component>
        <!-- Fim Modal de adição de novos arquivos -->

        <!-- Inicio Modal de visualização de arquivos -->
        <modal-component id="modalVisualizarArquivo" titulo="Visualizar Arquivo" tamanho="lg">
            <template v-slot:conteudo  v-if="$store.state.modal == '#modalVisualizarArquivo'">
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Nome da Arquivo">
                    <input type="text" class="form-control" :value="$store.state.item.arquivo" disabled>
                </input-container-component>
                <input-container-component label="Tipo de Arquivo">
                    <img v-if="$store.state.item.url.includes('.pdf')" :src="'/storage/icons/pdf.png'" class="img-fluid ml-3 py-3" width="40px">
                    <img v-if="$store.state.item.url.includes('.txt')" :src="'/storage/icons/txt.png'" class="img-fluid ml-3 py-3" width="40px">
                    <img v-if="$store.state.item.url.includes('.xls') || $store.state.item.url.includes('.xlsx')" :src="'/storage/icons/excel.png'" class="img-fluid ml-3 py-3" width="40px">
                    <img v-if="$store.state.item.url.includes('.doc') || $store.state.item.url.includes('.docx')" :src="'/storage/icons/word.png'" class="img-fluid ml-3 py-3" width="40px">
                    <img v-if="$store.state.item.url.includes('.ppt') || $store.state.item.url.includes('.pptx')" :src="'/storage/icons/ppt.png'" class="img-fluid ml-3 py-3" width="40px">
                </input-container-component>
                <input-container-component label="Último usuário">
                    <input type="text" class="form-control" :value="$store.state.item.user.name" disabled>
                </input-container-component>
                <input-container-component label="Data de criação">
                    <input type="text" class="form-control" :value="$store.state.item.created_at | formataDataHora" disabled>
                </input-container-component>
                
                <div class="accordion" id="accordionArquivos">
                    <div class="py-2">
                        <button class="btn btn-primary-generic btn-sm" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Cargos
                        </button>
                        <button class="btn btn-primary-generic  btn-sm collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Setores
                        </button>
                        <button class="btn btn-primary-generic  btn-sm collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Filiais
                        </button>
                    </div>
                    <div class="card">
                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionArquivos">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Cargo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="c in $store.state.item.cargos" :key="c.id">
                                            <td>{{c.id}}</td>
                                            <td>{{c.cargo}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionArquivos">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Setor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="s in $store.state.item.setores" :key="s.id">
                                            <td>{{s.id}}</td>
                                            <td>{{s.setor}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionArquivos">
                            <div class="card-body">
                                <table class="table table-hover table-sm-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Filial</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="f in $store.state.item.filiais" :key="f.id">
                                            <td>{{f.id}}</td>
                                            <td>{{f.filial}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
            </template>
        </modal-component>
        <!-- Fim Modal de visualização de arquivos-->

        <!-- Inicio Modal de atualização de arquivos-->
        <modal-component id="modalAtualizarArquivo" titulo="Atualizar Arquivo" tamanho="lg">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo v-if="$store.state.modal == '#modalAtualizarArquivo'">  
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                              
                <input-container-component id="atualizarNomeArquivo" label="Nome da Arquivo" id-help="atualizarNomeArquivoHelp" texto-help="Informe o nome da arquivo.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="text" class="form-control" id="atualizarNomeArquivo" aria-describedby="atualizarNomeArquivoHelp" placeholder="Nome da Arquivo" v-model="$store.state.item.arquivo">
                </input-container-component>
                <input-container-component id="atualizarArquivo" label="Arquivo" id-help="atualizarArquivoHelp" texto-help="Selecione o arquivo desejado.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="file" class="form-control-file" id="atualizarArquivo" aria-describedby="atualizarArquivoHelp" placeholder="Selecione o arquivo." @change="carregarArquivo($event)">
                </input-container-component>
                <div class="row">
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="atualizarCargos" label="Cargos da Arquivo" id-help="atualizarCargosHelp" texto-help="Informe os cargos da arquivo.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarCargos" size="3" multiple v-model="$store.state.cargosSelecionados">
                                <option v-for="c in $store.state.cargos" :key="c.id" :value="c.id">{{c.cargo}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="atualizarSetores" label="Setores da Arquivo" id-help="atualizarSetoresHelp" texto-help="Informe os setores da arquivo.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarSetores" size="3" multiple v-model="$store.state.setoresSelecionados">
                                <option v-for="s in $store.state.setores" :key="s.id" :value="s.id">{{s.setor}}</option>
                            </select>                
                        </input-container-component>
                    </div>
                    <div class="col-md-4">
                        <input-container-component v-if="$store.state.user.nivel_acesso == 1" id="atualizarFiliais" label="Filiais da Arquivo" id-help="atualizarFiliaisHelp" texto-help="Informe os filiais da arquivo.">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <select class="form-control" id="atualizarFiliais" size="3" multiple v-model="$store.state.filiaisSelecionadas">
                                <option v-for="f in $store.state.filiais" :key="f.id" :value="f.id">{{f.filial}}</option>
                            </select>                
                        </input-container-component> 
                    </div>
                </div> 
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro atualizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao atualizar cadastro do arquivo" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="atualizar()">Atualizar</button>
            </template>                      
        </modal-component>
        <!-- Fim Modal de atualização de arquivos-->

        <!-- Inicio Modal de remoção de arquivos-->
        <modal-component id="modalRemoverArquivo" titulo="Remover Arquivo" tamanho="md">
            <template v-slot:conteudo v-if="$store.state.transacao.status != 'sucesso'">
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Nome da Arquivo">
                    <input type="text" class="form-control" :value="$store.state.item.arquivo" disabled>
                </input-container-component>
            </template>
            <template v-slot:alertas>
                <alert-component tipo="success" titulo="Registro removido com sucesso" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" titulo="Falha ao remover registro" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-danger-generic" @click="excluir()" v-if="$store.state.transacao.status != 'sucesso'">Excluir</button>
            </template>
        </modal-component>
        <!-- Fim Modal de remoção de arquivos-->
    </div>
</template>

<script>
    export default {
        // Define os atributos que poderão ser usados nesta instancia do Vue
        data() {
            return{
                urlBase: 'api/arquivo',
                urlPaginacao: '',
                urlFiltro: '',
                numPaginas: '&paginas=30',
                arquivo: '',
                urlArquivo: [],
                cargosSelecionados: [],
                setoresSelecionados: [],
                filiaisSelecionadas: [],
                arquivos: { data: [] },
                busca: { arquivo: '' }
            }            
        },
        // Define os metodos usados nesta instancia do Vue
        methods: {
            // Metodo para buscar os registros da tabela arquivos
            carregarLista() {
                // Montando url de requisição com possiveis parâmetros de filtro e paginação
                let url = this.$store.state.apiHost + this.urlBase + '?' + this.urlPaginacao + '&order=arquivo' + this.urlFiltro + this.numPaginas
                // Requisição de consulta de registros na tabela arquivos no banco
                return axios.get(url)
                    .then(response => {
                        this.arquivos = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            // Metodo para buscar os registros da tabela cargos
            carregarCargos() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarCargos')
            },
            // Metodo para buscar os registros da tabela setores
            carregarSetores() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarSetores')
            },
            // Metodo para buscar os registros da tabela filiais
            carregarFiliais() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarFiliais')
            },
            // Metodo para buscar os registros da tabela filiais
            carregarUser() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarUser')
            },
            // Metodo para criar links de requisição de buscas paginadas
            paginacao(l) {
                if (l.url) {
                    // Capturando apenas parâmetro de pagina da request de paginação
                    this.urlPaginacao = l.url.split('?')[1] 
                    this.carregarLista()
                }                
            },
            // Metodo de pesquisa de registros na tabela arquivos por nome do registro
            pesquisar() {
                let filtro = ''
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.busca) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.busca[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':like:%' + this.busca[chave].substr(1) + '%'
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                // Define a primeira pagina para o resultado da busca
                this.urlPaginacao = 'page=1'
                this.carregarLista()
            },
            limparTransacao() {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.arquivo = ''
                this.urlArquivo = []
                this.cargosSelecionados = []
                this.setoresSelecionados = []
                this.filiaisSelecionadas = []
                this.carregarCargos()
                this.carregarSetores()
                this.carregarFiliais()
                this.carregarUser()
            },
            // Metodo para selecionar arquivo e atribuir a variavel urlArquivo 
            carregarArquivo(e) {
                this.urlArquivo = e.target.files
            },
            // Metodo para inserção de registros na tabela arquivos no banco
            salvar() {       
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('arquivo', this.arquivo)
                formData.append('url', this.urlArquivo[0])
                formData.append('user_id', this.$store.state.user.id)

                if (this.cargosSelecionados.length > 0 || this.$store.state.user.nivel_acesso == 1) {
                    this.cargosSelecionados.forEach(cargo => {
                        formData.append('cargos[]', cargo)
                    });
                } else {
                    formData.append('cargos[]', this.$store.state.user.cargo_id)
                }

                if (this.setoresSelecionados.length > 0 || this.$store.state.user.nivel_acesso == 1) {
                    this.setoresSelecionados.forEach(setor => {
                        formData.append('setores[]', setor)
                    });
                } else {
                    formData.append('setores[]', this.$store.state.user.setor_id)
                }
                
                if (this.filiaisSelecionadas.length > 0 || this.$store.state.user.nivel_acesso == 1) {
                    this.filiaisSelecionadas.forEach(filial => {
                        formData.append('filiais[]', filial)
                    });
                } else {
                    formData.append('filiais[]', this.$store.state.user.filial_id)
                }

                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }                
                // Requisição de inserção de registros atraves da biblioteca axios
                axios.post(this.$store.state.apiHost + this.urlBase, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando registro cadastrado no banco
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id 
                        this.$store.state.transacao.dados = ''
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false 

                        this.$alert('Registro inserido com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false 

                        this.$alert('Falha ao tentar inserir o registro', 'Erro', 'error');
                    })
            },
            atualizar() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Recupera a urlBase e concatena com o id do registro a se atualizado
                let url = this.$store.state.apiHost + this.urlBase+'/'+this.$store.state.item.id
                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('_method', 'patch')
                formData.append('arquivo', this.$store.state.item.arquivo)
                formData.append('user_id', this.$store.state.user.id)

                if (this.urlArquivo[0]) {
                    formData.append('url', this.urlArquivo[0])
                } 

                this.$store.state.cargosSelecionados.forEach(cargo => {
                    formData.append('cargos[]', cargo)
                });
                this.$store.state.setoresSelecionados.forEach(setor => {
                    formData.append('setores[]', setor)
                });
                this.$store.state.filiaisSelecionadas.forEach(filial => {
                    formData.append('filiais[]', filial)
                });

                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                } 
                // Requisição de atualização de registros atraves da biblioteca axios
                axios.post(url, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando a mensagem da requisição
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id
                        this.$store.state.transacao.dados = ''
                        atualizarArquivo.value = ''
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false 

                        this.$alert('Registro alterado com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false 

                        this.$alert('Falha ao tentar alterar o registro', 'Erro', 'error');
                    })
            },
            excluir() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Abre uma caixa de dialogo para confirmar a exclusão do registro
                this.$confirm(
                    'Tem certeza que deseja remover o registro?',
                    'Exclusão de Registro',
                    'question'
                ).then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url = this.$store.state.apiHost + this.urlBase+'/'+ this.$store.state.item.id
                    // Instanciando formulario e definindo o _method delete
                    let formData = new FormData()
                    formData.append('_method', 'delete')
                    // Requisição de remoção de registro por id
                    axios.post(url, formData)
                        .then(response => {
                            // Definindo status da requisição como sucesso
                            this.$store.state.transacao.status = 'sucesso'
                            // Recuperando a mensagem da requisição
                            this.$store.state.transacao.mensagem = response.data.msg
                            this.$store.state.transacao.dados = ''
                            this.carregarLista()

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false 
                        })
                        .catch(errors => {
                            // Definindo status da requisição como erro
                            this.$store.state.transacao.status = 'erro'
                            // Recuperando erros da requisição
                            this.$store.state.transacao.mensagem = errors.response.data.erro

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false 
                        })
                }).catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false
                })
            }
        },
        // Executa uma ação quando o componente estiver montado
        async mounted() {
            await this.carregarLista()
            await this.carregarUser()
        }
    }
</script>