<template>
    <div class="row mt-3">
        <!-- Seção de Filtros - Lateral Esquerda -->
        <div class="col-12 col-lg-3 border-filter-right d-none d-lg-block">

            <h3 class="title-wikis text-primary-color font-weight-bold mt-4 ml-3">WIKI</h3>

            <div class="accordion" id="accordionSetores">
                <!-- Relação de todos os setores do banco -->
                <div v-for="s in $store.state.setores" :key="s.id" class="card card-filter-wiki">
                    <div class="card-header card-filter-wiki-header" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn btn-accordion-filter" type="button" data-toggle="collapse"
                                :data-target="'#setor'+s.id" aria-expanded="true">
                                {{s.setor}} <i class="fas fa-chevron-down"></i>
                            </button>
                        </h2>
                    </div>

                    <div :id="'setor'+s.id" class="collapse" aria-labelledby="headingOne"
                        data-parent="#accordionSetores">
                        <!-- Card de wikis por setor -->
                        <card-wiki-component 
                            :dados="wikisSetor.data"
                            :setor="s.id" 
                        >                
                        </card-wiki-component>
                    </div>
                </div>
            </div>
        </div>

        <!-- Listagem de wikis - apresentação de wikis -->
        <div class="col-12 col-lg-9">

            <!-- Botão de Adicionar Nova Wiki -->
            <div class="row">
                <div class="col-12 text-right">
                    <a v-if="$store.state.user.nivel_acesso == 1" :href="$store.state.apiHost + 'wikis'" class="btn btn-new-wiki">NOVO</a>
                    <a v-if="$store.state.user.nivel_acesso == 2" :href="$store.state.apiHost + 'wikis'" class="btn btn-new-wiki">NOVO</a>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <img src="/storage/icons/livro_icon.png" alt="Ícone de um livro" class="img-fluid">
                </div>
            </div>
            <!-- Barra de Pesquisa de Wikis por Título de Wiki -->
            <div class="row mt-3 mx-3">
                <div class="col-12">
                    <div class="form-group">
                        <input type="text" class="form-control input-search-post placeholder px-4"
                            id="search_post" placeholder="Posso Ajudar?" autocomplete="off" v-model="busca.wiki" @keydown.enter="pesquisar()">
                    </div>
                    <div class="col-12 text-center">
                        <button type="submit" class="btn btn-search px-4" @click="pesquisar()">PESQUISAR</button>
                    </div>
                </div>
            </div>
            <!-- Relação de Wikis listadas -->
            <div v-if="wikis.data.length != 0" class="mt-5 mx-3">
                <div v-for="w in wikis.data" :key="w.id" class="row mt-3">
                    <div class="col-12 border-publication-bottom">
                        <h3 class="title-wiki font-weight-bold">{{w.wiki}}</h3>
                        <p class="text-wiki">{{w.descricao}}</p>
                        <p v-if="w.user" class="text-right date-wiki">Autor: {{w.user.name}} <span class="ml-4">Data:
                                {{w.created_at | formataDataSimples}}</span></p>
                        <p class="text-center"><a class="btn btn-view-more" style="cursor: pointer;" data-toggle="modal" data-target="#modalWiki" @click="setStore(w)">Ver Mais <i class="fas fa-angle-right"></i></a></p>
                    </div>
                </div>
            </div>
            <div v-if="wikis.data.length == 0" class="mt-5 mx-3">
                <div class="row mt-3">
                    <div class="col-12 border-publication-bottom">
                        <p class="text-wiki text-center">Nenhum registro encontrado!</p>
                    </div>
                </div>
            </div>
            <!-- Links de paginação -->
            <div class="row justify-content-end mr-3 mt-5">
                <nav aria-label="Page navigation">
                    <ul class="pagination" style="cursor: pointer;">
                        <li @click="paginacao(wikis.links[0])" class="page-item">
                            <a class="page-link" v-if="wikis.links" v-html="wikis.links[0].label"></a>
                        </li>
                        <li v-if="wikis.current_page != 1" @click="paginacao(wikis.links[wikis.current_page-1])" class="page-item">
                            <a class="page-link" v-if="wikis.links" v-html="wikis.links[wikis.current_page-1].label"></a>
                        </li>
                        <li @click="paginacao(wikis.links[wikis.current_page])" class="page-item active">
                            <a class="page-link" v-if="wikis.links" v-html="wikis.links[wikis.current_page].label"></a>
                        </li>
                        <li v-if="wikis.last_page > 1 && wikis.last_page != wikis.current_page" @click="paginacao(wikis.links[wikis.current_page+1])" class="page-item">
                            <a class="page-link" v-if="wikis.links" v-html="wikis.links[wikis.current_page+1].label"></a>
                        </li>
                        <li v-if="wikis.last_page > 1 && wikis.last_page != wikis.current_page" @click="paginacao(wikis.links[wikis.current_page+1])" class="page-item">
                            <a class="page-link" v-html="'Próximo &raquo;'"></a>
                        </li>
                        <li v-else class="page-item">
                            <a class="page-link" v-html="'Próximo &raquo;'"></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <!-- Inicio Modal -->
        <modal-post-component id="modalWiki" tamanho="lg">
            <template v-slot:conteudo>
                <button type="button" class="btn btn-close-modal float-right pr-2" data-dismiss="modal"><i class="far fa-times-circle icon-btn-close-modal "></i></button>
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <h3 class="text-primary-color font-weight-bold text-center title-modal-post mt-4">{{$store.state.item.wiki}}</h3>
                <p class="font-weight-bold text-justify subtitle-modal-post mt-5 px-1 px-md-4">{{$store.state.item.descricao}}</p>
                <div class="row justify-content-center">
                    <div class="mt-3 mx-auto px-2 px-md-5" v-html="$store.state.item.texto"></div>
                </div>                
                <div class="row align-items-center mt-4">
                    <div class="col-12 text-left">
                        <p v-if="$store.state.item.user" class="mb-0 pl-2 pl-md-3 info-modal-post">Autor: {{$store.state.item.user.name}}</p>
                        <p v-if="$store.state.item.created_at" class="pl-2 pl-md-3 info-modal-post">Data de Postagem: {{$store.state.item.created_at | formataDataHora}}</p>
                    </div>
                </div>  
            </template>
        </modal-post-component>
        <!-- Fim Modal -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                urlBase: 'api/wiki',
                urlPaginacao: '',
                urlFiltro: '',
                numPaginas: '&paginas=30',
                busca: { wiki: '' },
                wikis: { data: [] },
                wikisSetor: { data: [] },
            }
        },
        methods: {
            carregarWikis() {  
                // Montando url de requisição com possiveis parâmetros de filtro e paginação
                let url = this.$store.state.apiHost + this.urlBase + '?' + this.urlPaginacao + '&order=wiki' + this.urlFiltro + this.numPaginas
                // Requisição de consulta de registros na tabela wikis no banco
                return axios.get(url)
                    .then(response => {
                        this.wikis = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            carregarWikisSetor() {  
                // Requisição de consulta de registros na tabela wikis no banco
                return axios.get(this.$store.state.apiHost + 'api/wiki?order=wiki')
                    .then(response => {
                        this.wikisSetor = response
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            // Metodo para criar links de requisição de buscas paginadas
            paginacao(l) {
                if (l.url) {
                    // Capturando apenas parâmetro de pagina da request de paginação
                    this.urlPaginacao = l.url.split('?')[1] 
                    this.carregarWikis()
                }                
            },
            // Metodo de pesquisa de registros na tabela wikis por nome do registro
            pesquisar() {
                let filtro = ''
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.busca) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.busca[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':like:%' + this.busca[chave].substr(1) + '%'
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                // Define a primeira pagina para o resultado da busca
                this.urlPaginacao = 'page=1'
                this.carregarWikis()
            },
            // Metodo para buscar os registros da tabela setores
            carregarSetores() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarSetores')
            },
            // Metodo para buscar os registros da tabela user
            carregarUser() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarUser')
            },
            setStore(obj) {
                // Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
                this.$store.state.item = obj
            }
        },
        async mounted() {
            await this.carregarWikis()
            await this.carregarWikisSetor()
            await this.carregarSetores()
            await this.carregarUser()
        }
    }
</script>
