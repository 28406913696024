<template>
    <div class="row mt-3">
        <div class="col-12 col-lg-9">
            <!-- Botão de Adicionar Novo Arquivo -->
            <div class="row">
                <div class="col-12 text-right">
                    <a v-if="$store.state.user.nivel_acesso == 1" :href="$store.state.apiHost + 'arquivos'" class="btn btn-new-file">NOVO</a>
                    <a v-if="$store.state.user.nivel_acesso == 2" :href="$store.state.apiHost + 'arquivos'" class="btn btn-new-file">NOVO</a>
                </div>
            </div>
            <!-- Barra de Pesquisa de Arquivos por Título de Arquivo -->
            <div class="row mt-3 mx-1 mx-lg-3">
                <div class="col-12">
                    <h3 class="title-files text-primary-color font-weight-bold text-center mb-4">Lista de Arquivos</h3>
                    <div class="form-group">
                        <input type="text" class="form-control input-search-post placeholder px-4"
                            id="search_post" placeholder="Digite o nome do arquivo" v-model="busca.arquivo" @keydown.enter="pesquisar()">
                    </div>
                    <div class="col-12 text-center">
                        <button type="submit" class="btn btn-search px-3 px-lg-5" @click="pesquisar()">BUSCAR</button>
                    </div>
                </div>
            </div>
            <!-- Card de Arquivos -->
            <card-file-component 
                :dados="arquivos.data" 
                :user="$store.state.user"
            >                
            </card-file-component>
            <!-- Links de paginação -->
            <div class="row justify-content-end mr-3 mt-5">
                <nav aria-label="Page navigation">
                    <ul class="pagination" style="cursor: pointer;">
                        <li @click="paginacao(arquivos.links[0])" class="page-item">
                            <a class="page-link" v-if="arquivos.links" v-html="arquivos.links[0].label"></a>
                        </li>
                        <li v-if="arquivos.current_page != 1" @click="paginacao(arquivos.links[arquivos.current_page-1])" class="page-item">
                            <a class="page-link" v-if="arquivos.links" v-html="arquivos.links[arquivos.current_page-1].label"></a>
                        </li>
                        <li @click="paginacao(arquivos.links[arquivos.current_page])" class="page-item active">
                            <a class="page-link" v-if="arquivos.links" v-html="arquivos.links[arquivos.current_page].label"></a>
                        </li>
                        <li v-if="arquivos.last_page > 1 && arquivos.last_page != arquivos.current_page" @click="paginacao(arquivos.links[arquivos.current_page+1])" class="page-item">
                            <a class="page-link" v-if="arquivos.links" v-html="arquivos.links[arquivos.current_page+1].label"></a>
                        </li>
                        <li v-if="arquivos.last_page > 1 && arquivos.last_page != arquivos.current_page" @click="paginacao(arquivos.links[arquivos.current_page+1])" class="page-item">
                            <a class="page-link" v-html="'Próximo &raquo;'"></a>
                        </li>
                        <li v-else class="page-item">
                            <a class="page-link" v-html="'Próximo &raquo;'"></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="col-12 col-lg-3 align-self-center text-center px-3 d-none d-lg-block">
            <img src="/storage/icons/livros.png" alt="Icone de Livros">
        </div>            
    </div>
</template>

<script>
    export default {
        data() {
            return {
                urlBase: 'api/arquivo',
                urlPaginacao: '',
                urlFiltro: '',
                numPaginas: '&paginas=5',
                busca: { arquivo: '' },
                arquivos: { data: [] },
            }
        },
        methods: {
            carregarArquivos() {  
                // Montando url de requisição com possiveis parâmetros de filtro e paginação
                let url = this.$store.state.apiHost + this.urlBase + '?' + this.urlPaginacao + '&atributos_cargos_user=cargo&atributos_setores_user=setor&atributos_filiais_user=filial&order=arquivo' + this.urlFiltro + this.numPaginas
                // Requisição de consulta de registros na tabela arquivos no banco
                return axios.get(url)
                    .then(response => {
                        this.arquivos = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            // Metodo para criar links de requisição de buscas paginadas
            paginacao(l) {
                if (l.url) {
                    // Capturando apenas parâmetro de pagina da request de paginação
                    this.urlPaginacao = l.url.split('?')[1] 
                    this.carregarArquivos()
                }                
            },
            // Metodo de pesquisa de registros na tabela arquivos por nome do registro
            pesquisar() {
                let filtro = ''
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.busca) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.busca[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':like:%' + this.busca[chave].substr(1) + '%'
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                // Define a primeira pagina para o resultado da busca
                this.urlPaginacao = 'page=1'
                this.carregarArquivos()
            },
            // Metodo para buscar os registros da tabela user
            carregarUser() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarUser')
            }
        },
        async mounted() {
            await this.carregarArquivos()
            await this.carregarUser()
        }
    }
</script>
