<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 mb-5">
                <!-- Inicio do card de pesquisa -->
                <card-component titulo="Pesquisa de Filiais">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <input-container-component id="inputFilial" label="Filial" id-help="filialHelp" texto-help="Informe o nome da filial que deseja pesquisar!">
                            <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                            <input type="text" class="form-control" id="inputFilial" aria-describedby="filialHelp" placeholder="Digite aqui!" autocomplete="off" v-model="busca.filial" @keydown.enter="pesquisar()">
                        </input-container-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <button type="submit" class="btn btn-primary-generic btn-sm float-right" @click="pesquisar()">Pesquisar</button>
                    </template>
                </card-component>
                <!-- Fim do card de pesquisa -->
            </div>

            <div class="col-md-10">
                <!-- Inicio do card de listagem de filiais -->
                <card-component titulo="Lista de Filiais">
                    <!-- Conteudo encaminhado para o slot 'conteudo' do componente Card -->
                    <template v-slot:conteudo>
                        <!-- Chama o component de loader no envio da requisição -->  
                        <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                  
                        <table-component 
                            :dados="filiais.data" 
                            :titulos="{  // Relação de titulos a serem listados e seus respectivos titulos
                                id: {titulo: 'ID', tipo: 'inteiro'},
                                filial: {titulo: 'Filial', tipo: 'texto'},
                                created_at: {titulo: 'Data de criação', tipo: 'data_hora'}
                            }"
                            :botoes="{   // Relação de botoes a serem listados e suas respectivas propriedades
                                visualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalVisualizarFilial', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                atualizar: {visivel: true, dataToggle: 'modal', dataTarget: '#modalAtualizarFilial', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso},
                                excluir: {visivel: true, dataToggle: 'modal', dataTarget: '#modalRemoverFilial', id: $store.state.user.id, nivel: $store.state.user.nivel_acesso}
                            }"
                            
                        >
                        </table-component>
                    </template>
                    <!-- Conteudo encaminhado para o slot 'rodape' do componente Card -->
                    <template v-slot:rodape>
                        <div class="row align-items-center">
                            <div class="col">
                                <paginate-component>
                                    <li @click="paginacao(filiais.links[0])" class="page-item">
                                        <a class="page-link" v-if="filiais.links" v-html="filiais.links[0].label"></a>
                                    </li>
                                    <li v-if="filiais.current_page != 1" @click="paginacao(filiais.links[filiais.current_page-1])" class="page-item">
                                        <a class="page-link" v-if="filiais.links" v-html="filiais.links[filiais.current_page-1].label"></a>
                                    </li>
                                    <li @click="paginacao(filiais.links[filiais.current_page])" class="page-item active">
                                        <a class="page-link" v-if="filiais.links" v-html="filiais.links[filiais.current_page].label"></a>
                                    </li>
                                    <li v-if="filiais.last_page > 1 && filiais.last_page != filiais.current_page" @click="paginacao(filiais.links[filiais.current_page+1])" class="page-item">
                                        <a class="page-link" v-if="filiais.links" v-html="filiais.links[filiais.current_page+1].label"></a>
                                    </li>
                                    <li v-if="filiais.last_page > 1 && filiais.last_page != filiais.current_page" @click="paginacao(filiais.links[filiais.current_page+1])" class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                    <li v-else class="page-item">
                                        <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                    </li>
                                </paginate-component>
                            </div>
                            <div class="col mt-2 mt-md-0">
                                <button type="button" class="btn btn-primary-generic btn-sm float-right" data-toggle="modal" data-target="#modalFilial" @click="limparTransacao()">Adicionar</button>
                                <button v-if="$store.state.user.id == 1" type="button" class="btn btn-danger-generic btn-sm float-right mr-2" @click="sincronizarFiliais()">Sincronizar com a Sênior</button>
                            </div>
                        </div>
                    </template>
                </card-component>
                <!-- Fim do card de listagem de filiais -->
            </div>
        </div>

        <!-- Inicio Modal de adição de novos filiais-->
        <modal-component id="modalFilial" titulo="Adicionar Filial" tamanho="md">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo> 
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                              
                <input-container-component id="novoFilial" label="Nome da Filial" id-help="novoFilialHelp" texto-help="Informe o nome da filial.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="text" class="form-control" id="novoFilial" aria-describedby="novoFilialHelp" placeholder="Nome da Filial" autocomplete="off" v-model="filial">
                </input-container-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro realizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao realizar cadastro da filial" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="salvar()">Salvar</button>
            </template>            
        </modal-component>
        <!-- Fim Modal de adição de novos filiais-->

        <!-- Inicio Modal de visualização de filiais-->
        <modal-component id="modalVisualizarFilial" titulo="Visualizar Filial" tamanho="md">
            <template v-slot:conteudo>
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Nome da Filial">
                    <input type="text" class="form-control" :value="$store.state.item.filial" disabled>
                </input-container-component>
                <input-container-component label="Data de criação">
                    <input type="text" class="form-control" :value="$store.state.item.created_at | formataDataHora" disabled>
                </input-container-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
            </template>
        </modal-component>
        <!-- Fim Modal de visualização de filiais-->

        <!-- Inicio Modal de atualização de filiais-->
        <modal-component id="modalAtualizarFilial" titulo="Atualizar Filial" tamanho="md">
            <!-- Conteudo encaminhado para o slot 'conteudo' do componente Modal -->
            <template v-slot:conteudo>              
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                  
                <input-container-component id="atualizarFilial" label="Nome da Filial" id-help="atualizarFilialHelp" texto-help="Informe o nome da filial.">
                    <!-- Conteudo encaminhado para o slot do componente InputContainer -->
                    <input type="text" class="form-control" id="atualizarFilial" aria-describedby="atualizarFilialHelp" placeholder="Nome da Filial" v-model="$store.state.item.filial" >
                </input-container-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'alertas' do componente Modal -->
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao" titulo="Cadastro atualizado com sucesso" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao" titulo="Erro ao atualizar cadastro da filial" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <!-- Conteudo encaminhado para o slot 'rodape' do componente Modal -->
            <template v-slot:rodape>                
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary-generic" @click="atualizar()">Atualizar</button>
            </template>                      
        </modal-component>
        <!-- Fim Modal de atualização de filiais-->

        <!-- Inicio Modal de remoção de filiais-->
        <modal-component id="modalRemoverFilial" titulo="Remover Filial" tamanho="md">
            <template v-slot:conteudo v-if="$store.state.transacao.status != 'sucesso'">
                <!-- Chama o component de loader no envio da requisição -->  
                <loader v-if="$store.state.loader" :object="$store.state.colorObject" :size="$store.state.size" :speed="$store.state.speed" :bg="$store.state.colorBg" :opacity="$store.state.opacity" :disableScrolling="$store.state.disableScrolling" :name="$store.state.name"></loader>
                
                <!-- Recupera o objeto criado na instancia global do vuex ($store.state.item) -->
                <input-container-component label="ID">
                    <input type="text" class="form-control" :value="$store.state.item.id" disabled>
                </input-container-component>
                <input-container-component label="Nome da Filial">
                    <input type="text" class="form-control" :value="$store.state.item.filial" disabled>
                </input-container-component>
            </template>
            <template v-slot:alertas>
                <alert-component tipo="success" titulo="Registro removido com sucesso" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" titulo="Falha ao remover registro" :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-danger-generic" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-danger-generic" @click="excluir()" v-if="$store.state.transacao.status != 'sucesso'">Excluir</button>
            </template>
        </modal-component>
        <!-- Fim Modal de remoção de filiais-->
    </div>
</template>

<script>
    export default {
        // Define os atributos que poderão ser usados nesta instancia do Vue
        data() {
            return{
                urlBase: 'api/filial',
                urlPaginacao: '',
                urlFiltro: '',
                numPaginas: '&paginas=30',
                filial: '',
                filiais: { data: [] },
                busca: { filial: '' }
            }            
        },
        // Define os metodos usados nesta instancia do Vue
        methods: {
            // Metodo para buscar os registros da tabela filiais
            carregarLista() {
                // Montando url de requisição com possiveis parâmetros de filtro e paginação
                let url = this.$store.state.apiHost + this.urlBase + '?' + this.urlPaginacao + '&order=filial' + this.urlFiltro + this.numPaginas
                // Requisição de consulta de registros na tabela filiais no banco
                return axios.get(url)
                    .then(response => {
                        this.filiais = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            // Metodo para buscar os registros da tabela filiais
            carregarUser() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarUser')
            },
            // Metodo para criar links de requisição de buscas paginadas
            paginacao(l) {
                if (l.url) {
                    // Capturando apenas parâmetro de pagina da request de paginação
                    this.urlPaginacao = l.url.split('?')[1] 
                    this.carregarLista()
                }                
            },
            // Metodo de pesquisa de registros na tabela filiais por nome do registro
            pesquisar() {
                let filtro = ''
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.busca) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.busca[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':like:%' + this.busca[chave].substr(1) + '%'
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                // Define a primeira pagina para o resultado da busca
                this.urlPaginacao = 'page=1'
                this.carregarLista()

            },
            limparTransacao() {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.filial = ''
            },
            // Metodo para inserção de registros na tabela filiais no banco
            salvar() {     
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('filial', this.filial)
                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }                
                // Requisição de inserção de registros atraves da biblioteca axios
                axios.post(this.$store.state.apiHost + this.urlBase, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando registro cadastrado no banco
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id 
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Registro inserido com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Falha ao tentar inserir o registro', 'Erro', 'error');
                    })
            },
            atualizar() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Recupera a urlBase e concatena com o id do registro a se atualizado
                let url = this.$store.state.apiHost + this.urlBase+'/'+this.$store.state.item.id
                // Instanciando formulario e definindo seus atributos
                let formData = new FormData()
                formData.append('_method', 'patch')
                formData.append('filial', this.$store.state.item.filial)
                // Configurando headers da requisição
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                } 
                // Requisição de atualização de registros atraves da biblioteca axios
                axios.post(url, formData, config)
                    .then(response => {
                        // Definindo status da requisição como sucesso
                        this.$store.state.transacao.status = 'sucesso'
                        // Recuperando a mensagem da requisição
                        this.$store.state.transacao.mensagem = 'Código do registro: ' + response.data.id
                        this.carregarLista()

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Registro alterado com sucesso', 'Sucesso', 'success');
                    })
                    .catch(errors => {
                        // Definindo status da requisição como erro
                        this.$store.state.transacao.status = 'erro'
                        // Recuperando erros da requisição
                        this.$store.state.transacao.mensagem = errors.response.data.message
                        this.$store.state.transacao.dados = errors.response.data.errors

                        // Define a variaval global Spinner como false
                        this.$store.state.loader = false

                        this.$alert('Falha ao tentar alterar o registro', 'Erro', 'error');
                    })
            },
            excluir() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Abre uma caixa de dialogo para confirmar a exclusão do registro
                this.$confirm(
                    'Tem certeza que deseja remover o registro?',
                    'Exclusão de Registro',
                    'question'
                ).then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url = this.$store.state.apiHost + this.urlBase+'/'+ this.$store.state.item.id
                    // Instanciando formulario e definindo o _method delete
                    let formData = new FormData()
                    formData.append('_method', 'delete')
                    // Requisição de remoção de registro por id
                    axios.post(url, formData)
                        .then(response => {
                            // Definindo status da requisição como sucesso
                            this.$store.state.transacao.status = 'sucesso'
                            // Recuperando a mensagem da requisição
                            this.$store.state.transacao.mensagem = response.data.msg
                            this.$store.state.transacao.dados = ''
                            this.carregarLista()

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false
                        })
                        .catch(errors => {
                            // Definindo status da requisição como erro
                            this.$store.state.transacao.status = 'erro'
                            // Recuperando erros da requisição
                            this.$store.state.transacao.mensagem = errors.response.data.erro
                            console.log(errors.response.data)

                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false
                        })                    
                }).catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false
                })                                  
            },
            sincronizarFiliais() {
                // Define a variaval global Spinner como true
                this.$store.state.loader = true

                // Abre uma caixa de dialogo para confirmar a exclusão do registro
                this.$confirm(
                    'Tem certeza que deseja sincronizar a tabela de filiais com a Sênior?',
                    'Sincronização de Registros',
                    'question'
                ).then(() => {
                    // Recupera a urlBase e concatena com o id do registro a se excluido
                    let url = this.$store.state.apiHost + 'api/sincronizar-filiais'
                    // Requisição de remoção de registro por id
                    axios.get(url)
                        .then(response => {
                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false
                            this.carregarLista()
                            
                            this.$alert(response.data.msg, 'Sucesso', 'success');
                        })
                        .catch(errors => {
                            // Define a variaval global Spinner como false
                            this.$store.state.loader = false

                            this.$alert(errors.response.data.erro, 'Erro', 'error');
                        })
                }).catch(() => {
                    // Define a variaval global Spinner como false
                    this.$store.state.loader = false
                })
            }
        },
        // Executa uma ação quando o componente estiver montado
        async mounted() {
            await this.carregarLista()
            await this.carregarUser()
        }
    }
</script>