<template>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
                <tr>
                    <!-- Percorre o objeto titulos e retorna o titulo de cada objeto -->
                    <th class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" scope="col" v-for="t, key in titulos" :key="key">{{t.titulo}}</th>
                    <th v-if="botoes.visualizar.visivel || botoes.atualizar.visivel || botoes.excluir.visivel"></th>
                </tr>
            </thead>
            <tbody v-if="dadosFiltrados.length != 0">
                <!-- Percorre o array de objetos e para cada objeto percorre seus devidos campos -->
                <tr v-for="obj, key in dadosFiltrados" :key="key">
                    <td class="text-center pl-1 pl-md-0 pr-1 pr-md-0 text-uppercase" v-for="valor, objKey in obj" :key="objKey">
                        <!-- Verifica o tipo de cada campo do objeto e trata a exibição de acordo com o tipo -->
                        <span v-if="titulos[objKey].tipo == 'texto'" data-toggle="tooltip" data-placement="top" :title="valor">{{valor | textoCurto}}</span>
                        <span v-if="titulos[objKey].tipo == 'inteiro'">{{valor}}</span>
                        <span v-if="titulos[objKey].tipo == 'icone'">
                            <img v-if="valor.includes('.pdf')" :src="'/storage/icons/pdf.png'" class="img-fluid" width="30px">
                            <img v-if="valor.includes('.txt')" :src="'/storage/icons/txt.png'" class="img-fluid" width="30px">
                            <img v-if="valor.includes('.xls') || valor.includes('.xlsx')" :src="'/storage/icons/excel.png'" class="img-fluid" width="30px">
                            <img v-if="valor.includes('.doc') || valor.includes('.docx')" :src="'/storage/icons/word.png'" class="img-fluid" width="30px">
                            <img v-if="valor.includes('.ppt') || valor.includes('.pptx')" :src="'/storage/icons/ppt.png'" class="img-fluid" width="30px">
                        </span>
                        <span v-if="titulos[objKey].tipo == 'nao_exibir'"></span>
                        <span v-if="titulos[objKey].tipo == 'chave'" data-toggle="tooltip" data-placement="top" :title="valor[titulos[objKey].campo]">{{valor[titulos[objKey].campo] | textoCurto}}</span>
                        <span v-if="titulos[objKey].tipo == 'modal'"><button :class="'btn btn-sm btn-outline-'+titulos[objKey].classe" @click="setStore(valor, titulos[objKey].dataTarget)" :data-toggle="titulos[objKey].tipo" :data-target="titulos[objKey].dataTarget">{{titulos[objKey].titulo}}</button></span>
                        <span v-if="titulos[objKey].tipo == 'data_hora'">{{valor | formataDataSimples}}</span>
                        <span v-if="titulos[objKey].tipo == 'data'">{{valor | formataData}}</span>
                        <span v-if="titulos[objKey].tipo == 'imagem'">
                            <img :src="'/storage/'+valor" v-if="valor != 'sem-imagem'" class="img-fluid" width="30px" height="30px">
                            <img :src="'/storage/icons/func_m.png'" v-if="valor == 'sem-imagem'" class="img-fluid" width="30px" height="30px">
                        </span>
                        <span v-if="titulos[objKey].tipo == 'status'">
                            <span v-if="valor == 'a'">Ativo</span>
                            <span v-if="valor == 'i'">Inativo</span>
                        </span>
                    </td>
                    
                    <td class="text-center text-md-right" v-if="botoes.visualizar.visivel || botoes.atualizar.visivel || botoes.excluir.visivel">
                        <span v-if="botoes.excluir.nivel == 1">
                            <button class="btn btn-danger-generic btn-sm mb-1 mb-md-0" @click="setStore(obj, botoes.excluir.dataTarget)" v-if="botoes.excluir.visivel" :data-toggle="botoes.excluir.dataToggle" :data-target="botoes.excluir.dataTarget">Excluir</button>
                        </span>
                        <span v-if="botoes.excluir.nivel == 2 && obj.user">
                            <button class="btn btn-danger-generic btn-sm mb-1 mb-md-0" @click="setStore(obj, botoes.excluir.dataTarget)" v-if="botoes.excluir.visivel && botoes.excluir.id == obj.user.id" :data-toggle="botoes.excluir.dataToggle" :data-target="botoes.excluir.dataTarget">Excluir</button>
                        </span>
                        <span v-if="botoes.excluir.nivel == 1">
                            <button class="btn btn-black-generic btn-sm mb-1 mb-md-0" @click="setStore(obj, botoes.atualizar.dataTarget)" v-if="botoes.atualizar.visivel" :data-toggle="botoes.atualizar.dataToggle" :data-target="botoes.atualizar.dataTarget">Atualizar</button>
                        </span>      
                        <span v-if="botoes.excluir.nivel == 2 && obj.user">
                            <button class="btn btn-black-generic btn-sm mb-1 mb-md-0" @click="setStore(obj, botoes.atualizar.dataTarget)" v-if="botoes.atualizar.visivel && botoes.atualizar.id == obj.user.id" :data-toggle="botoes.atualizar.dataToggle" :data-target="botoes.atualizar.dataTarget">Atualizar</button>
                        </span>                
                        <span>
                            <button class="btn btn-primary-generic btn-sm mb-1 mb-md-0" @click="setStore(obj, botoes.visualizar.dataTarget)" v-if="botoes.visualizar.visivel" :data-toggle="botoes.visualizar.dataToggle" :data-target="botoes.visualizar.dataTarget">Visualizar</button>
                        </span>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="12" class="text-center pl-1 pl-md-0 pr-1 pr-md-0">
                        Nenhum registro encontrado!
                    </td>
                </tr>
            </tbody>
        </table>
    </div>    
</template>

<script>
    export default {
        props: ['dados', 'titulos', 'botoes'],
        computed: {
            dadosFiltrados() {
                // Recupera todas as chaves do objeto 'titulos'
                let campos = Object.keys(this.titulos)
                let dadosFiltrados = []
                // Recupera o valor e chave de cada item do array de objetos 'dados'
                if (this.dados != '') {
                    this.dados.map((valor, key) => {
                        let itemFiltrado = {}
                        // Percorre o array 'campos' e executa uma função para cada chave do array
                        campos.forEach(campo => {
                            // Adicionando um atributo dentro do objeto 'itemFiltrado', atraves da sintaxe de array
                            itemFiltrado[campo] = valor[campo]
                        })                    
                        // Adicionando o objeto 'itemFiltrado' dentro do array 'dadosFiltrados'
                        dadosFiltrados.push(itemFiltrado)
                    })
                }                
                
                return dadosFiltrados
            }
        },
        methods: {
            // Metodo para buscar os registros da tabela cargos
            carregarCargos() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarCargos')
            },
            // Metodo para buscar os registros da tabela setores
            carregarSetores() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarSetores')
            },
            // Metodo para buscar os registros da tabela filiais
            carregarFiliais() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarFiliais')
            },
            // Metodo para buscar os registros da tabela categorias
            carregarCategorias() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarCategorias')
            },
            // Metodo para buscar os registros da tabela users
            carregarUser() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarUser')
            },
            // Metodo para buscar os registros da tabela atividades
            carregarAtividades() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarAtividades')
            },
            // Metodo para buscar os registros da tabela perguntas
            carregarPerguntas() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarPerguntas')
            },
            // Metodo recebe um objeto contendo as informações do cargo e apresenta no modal
            setStore(obj, idModal) {
                // Atribuindo o valor vazio aos atributos globais do $store.state.transacao sempre que um novo modal for aberto
                this.$store.state.transacao.mensagem = ''
                this.$store.state.transacao.status = ''
                this.$store.state.transacao.dados = ''
                this.$store.state.cargosSelecionados = []
                this.$store.state.setoresSelecionados = []
                this.$store.state.filiaisSelecionadas = []
                this.$store.state.item = {}
                this.$store.state.cropImgUser = ''
                // Atribuindo o objeto recuperado ao atributo de configuração na instancia do vue, pode ser usado em qualquer componente
                this.$store.state.item = obj
                // Recupera o id do modal a ser instanciado
                this.$store.state.modal = idModal

                if (obj.cargos) {
                    // Recupera a relação de cargos selecionados
                    let cargosSelecionado = []
                    obj.cargos.forEach(cargo => {
                        cargosSelecionado = cargo.id
                        this.$store.state.cargosSelecionados.push(cargosSelecionado)
                    });
                    // Recupera relação de todos os cargos do banco
                    this.carregarCargos()
                }

                if (obj.cargo) {
                    // Recupera relação de todos os cargos do banco
                    this.carregarCargos()
                }
                
                if (obj.setores) {
                    // Recupera a relação de setores selecionados
                    let setorSelecionado = []
                    obj.setores.forEach(setor => {
                        setorSelecionado = setor.id
                        this.$store.state.setoresSelecionados.push(setorSelecionado)
                    });
                    // Recupera relação de todos os setores do banco
                    this.carregarSetores()
                }

                if (obj.setor) {
                    // Recupera relação de todos os setores do banco
                    this.carregarSetores()
                }

                if (obj.filiais) {
                    // Recupera a relação de filiais selecionadas
                    let filialSelecionada = []
                    obj.filiais.forEach(filial => {
                        filialSelecionada = filial.id
                        this.$store.state.filiaisSelecionadas.push(filialSelecionada)
                    });
                    // Recupera relação de todas as filiais do banco
                    this.carregarFiliais()
                }

                if (obj.filial) {
                    // Recupera relação de todas as filiais do banco
                    this.carregarFiliais()
                }

                if (obj.categoria) {
                    // Recupera relação de todas as categorias do banco
                    this.carregarCategorias()
                }
                
                if (obj.user) {
                    // Metodo para carregar informações do usuário logado
                    this.carregarUser()
                }
                
                if (obj.atividade) {
                    // Recupera relação de todas as atividades do banco
                    this.carregarAtividades()
                }

                if (obj.pergunta) {
                    // Recupera relação de todas as perguntas do banco
                    this.carregarPerguntas()
                }              
            }
        }
    }
</script>