<template>
    <div class="row mt-3 pt-3">
        <!-- Filtros de Pesquisa de Contatos -->
        <div class="col-12 col-lg-3 border-filter-right d-none d-lg-block">
            <h3 class="title-contact-book font-weight-bold text-primary-color">Filtro de Pesquisa</h3>
            <div class="row">
                <div class="col-12 text-right mt-3">
                    <button class="btn btn-filter mr-3" @click="filtrar()">FILTRAR</button>
                </div>
            </div>
            <!-- Collapse de filiais cadastradas no banco -->
            <div class="mt-3">
                <div>
                    <button class="btn btn-filter-contact-book font-weight-bold" type="button"
                        data-toggle="collapse" data-target="#filialCollapse" aria-expanded="false"
                        aria-controls="filialCollapse">
                        Filial <i class="fas fa-plus icon-filter pl-2"></i>
                    </button>
                </div>
                <div class="collapse show" id="filialCollapse">
                    <div class="form-check" v-for="f in $store.state.filiais" :key="f.id">
                        <input class="form-check-input" type="radio" :value="f.id" v-model="filtra.filial_id">
                        <label class="form-check-label">{{f.filial}}</label>
                    </div>
                </div>
            </div>
            <!-- Collapse de setores cadastrados no banco -->
            <div class="mt-3">
                <div>
                    <button class="btn btn-filter-contact-book font-weight-bold" type="button"
                        data-toggle="collapse" data-target="#setorCollapse" aria-expanded="false"
                        aria-controls="setorCollapse">
                        Setor <i class="fas fa-plus icon-filter pl-2"></i>
                    </button>
                </div>
                <div class="collapse" id="setorCollapse">
                    <div class="form-check" v-for="s in $store.state.setores" :key="s.id">
                        <input class="form-check-input" type="radio" :value="s.id" v-model="filtra.setor_id">
                        <label class="form-check-label">{{s.setor}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-9 px-4">
            <!-- Barra de Pesquisa de Contatos por nome do Colaborador -->
            <div class="row">
                <div class="col-12 col-lg-9 px-1 px-lg-4">
                    <h3 class="title-contact-book font-weight-bold text-primary-color text-center pb-4">
                        Busca de Contatos
                    </h3>
                    <div class="form-group">
                        <input type="text" class="form-control input-search-post placeholder px-4"
                            id="search_post" placeholder="Digite o nome do colaborador" autocomplete="off" v-model="busca.name" @keydown.enter="pesquisar()">
                    </div>
                    <div class="col-12 text-center">
                        <button type="submit" class="btn btn-search px-5" @click="pesquisar()">BUSCAR</button>
                    </div>
                </div>
                <div class="col-12 col-lg-3 align-self-center d-none d-lg-block">
                    <img src="/storage/icons/lista_telefonica.png" class="img-fluid" alt="Icone de uma Lista Telefonica">
                </div>
            </div>
            <!-- Tabela com listagem de contato por Colaborador -->
            <div class="row mt-5">
                <div class="col-12 px-1 px-lg-4 table-responsive">
                    <table class="table table-hover table-bordered table-contact-book">
                        <thead>
                            <tr>
                                <th scope="col" class="text-uppercase">Nome</th>
                                <th scope="col" class="text-uppercase">Filial</th>
                                <th scope="col" class="text-uppercase">Setor</th>
                                <th scope="col" class="text-uppercase">Celular</th>
                                <th scope="col" class="text-uppercase pr-2">Ramal</th>
                                <th scope="col" class="text-uppercase">E-mail</th>
                            </tr>
                        </thead>
                        <tbody v-if="colaboradores.data.length != 0">
                            <tr v-for="c in colaboradores.data" :key="c.id">
                                <td class="text-uppercase" data-toggle="tooltip" data-placement="top" :title="c.name">{{c.name | textoCurtoContatos}}</td>
                                <td class="text-uppercase" v-if="c.filial" data-toggle="tooltip" data-placement="top" :title="c.filial.filial">{{c.filial.filial | textoCurtoContatos}}</td>
                                <td class="text-uppercase" v-if="c.setor" data-toggle="tooltip" data-placement="top" :title="c.setor.setor">{{c.setor.setor | textoCurtoContatos}}</td>
                                <td class="text-uppercase" data-toggle="tooltip" data-placement="top" :title="c.celular">{{c.celular}}</td>
                                <td class="text-uppercase" data-toggle="tooltip" data-placement="top" :title="c.ramal">{{c.ramal | valorNull}}</td>
                                <td class="text-uppercase" data-toggle="tooltip" data-placement="top" :title="c.email">{{c.email | valorNull | textoCurtoContatos}}</td>
                            </tr>
                        </tbody>
                        <tbody v-if="colaboradores.data.length == 0">
                            <tr>
                                <td colspan="6" class="text-center">Nenhum Colaborador Encontrado!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 px-1 px-lg-4">
                    <div class="row justify-content-center justify-content-md-start px-3">
                        <!-- Links de paginação -->
                        <nav aria-label="Page navigation">
                            <ul class="pagination" style="cursor: pointer;">
                                <li @click="paginacao(colaboradores.links[0])" class="page-item">
                                    <a class="page-link" v-if="colaboradores.links" v-html="colaboradores.links[0].label"></a>
                                </li>
                                <li v-if="colaboradores.current_page != 1" @click="paginacao(colaboradores.links[colaboradores.current_page-1])" class="page-item">
                                    <a class="page-link" v-if="colaboradores.links" v-html="colaboradores.links[colaboradores.current_page-1].label"></a>
                                </li>
                                <li @click="paginacao(colaboradores.links[colaboradores.current_page])" class="page-item active">
                                    <a class="page-link" v-if="colaboradores.links" v-html="colaboradores.links[colaboradores.current_page].label"></a>
                                </li>
                                <li v-if="colaboradores.last_page > 1 && colaboradores.last_page != colaboradores.current_page" @click="paginacao(colaboradores.links[colaboradores.current_page+1])" class="page-item">
                                    <a class="page-link" v-if="colaboradores.links" v-html="colaboradores.links[colaboradores.current_page+1].label"></a>
                                </li>
                                <li v-if="colaboradores.last_page > 1 && colaboradores.last_page != colaboradores.current_page" @click="paginacao(colaboradores.links[colaboradores.current_page+1])" class="page-item">
                                    <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                </li>
                                <li v-else class="page-item">
                                    <a class="page-link" v-html="'Próximo &raquo;'"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                urlBase: 'api/user',
                urlPaginacao: '',
                urlFiltro: '&filtro=status:=:a;id:<>:1',
                numPaginas: '&paginas=30',
                busca: { name: '' },
                filtra: {filial_id: '', setor_id: ''},                
                colaboradores: { data: [] },
            }
        },
        methods: {
            carregarColaboradores() {  
                // Montando url de requisição com possiveis parâmetros de filtro e paginação
                let url = this.$store.state.apiHost + this.urlBase + '?' + this.urlPaginacao + '&order=name' + this.urlFiltro + this.numPaginas
                // Requisição de consulta de registros na tabela users no banco
                return axios.get(url)
                    .then(response => {
                        this.colaboradores = response.data
                    })
                    .catch(errors => {
                        console.log(errors.response)
                    })
            },
            // Metodo para criar links de requisição de buscas paginadas
            paginacao(l) {
                if (l.url) {
                    // Capturando apenas parâmetro de pagina da request de paginação
                    this.urlPaginacao = l.url.split('?')[1] 
                    this.carregarColaboradores()
                }                
            },
            // Metodo de pesquisa de registros na tabela users por nome do registro
            pesquisar() {
                let filtro = 'status:=:a;id:<>:1'
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.busca) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.busca[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':like:%' + this.busca[chave].substr(1) + '%'
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                // Define a primeira pagina para o resultado da busca
                this.urlPaginacao = 'page=1'
                this.carregarColaboradores()
            },
            // Metodo de filtro de registros na tabela users por filial e setor
            filtrar() {
                let filtro = 'status:=:a;id:<>:1'
                // Percorre o objeto 'busca' concatenando seus atributos em uma string
                for(let chave in this.filtra) {
                    // Verifica se o campo foi preenchido para atribuir o valor
                    if (this.filtra[chave]) {
                        // Separa cada atributo com ';'
                        if (filtro != '') {
                            filtro += ';' 
                        }
                        // Separa valor e chave com ':like:'
                        filtro += chave + ':=:' + this.filtra[chave]
                    }                    
                } 
                // Verifica se o filtro é diferente de vazio e atribui seu valor a 'urlFiltro', senão limpa o valor contido dentro de 'urlFiltro'
                if (filtro != '') {
                    this.urlFiltro = '&filtro='+filtro
                } else {
                    this.urlFiltro = ''
                }                
                // Define a primeira pagina para o resultado da busca
                this.urlPaginacao = 'page=1'
                this.carregarColaboradores()
            },
            // Metodo para buscar os registros da tabela setores
            carregarSetores() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarSetores')
            },
            // Metodo para buscar os registros da tabela filiais
            carregarFiliais() {
                // Executa o metodo da store do vuex (app.js)
                this.$store.commit('carregarFiliais')
            },
        },
        async mounted() {
            await this.carregarColaboradores()
            await this.carregarSetores()
            await this.carregarFiliais()
        }
    }
</script>
